import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { DICTIONARY, getTodayWord } from "../constants/dictionary";
import {
  NOT_MIN_LENGTH_MESSAGES,
  NO_WORD_MESSAGES,
  SUCCESS_MESSAGES,
  TEXT_CHALLENGE_FRIENDS,
} from "../constants/strings";
import {
  DAY_MS,
  GAME_VERSION,
  GREEK_LETTERS,
  MAX_TRIES,
  PRUNE_STATS,
  WORD_LENGTH,
} from "../constants/variables";
import Alert from "./Alert";
import ChangesModal from "./ChangesModal";
import Grid from "./Grid";
import InfoModal from "./InfoModal";
import Keyboard from "./Keyboard";

import StatisticsModal from "./StatisticsModal";
import TitleBar from "./TitleBar";

type Props = {};

export const Game = (props: Props) => {
  const today = new Date(new Date().toDateString()).valueOf();
  const [currentGuess, setCurrentGuess] = useState("");
  const [guesses, setGuesses] = useState<string[]>([]);
  const [solution, setSolution] = useState(getTodayWord());
  const [gameStatus, setGameStatus] = useState("IN_PROGRESS");
  const [lastPlayed, setLastPlayed] = useState(today);
  const [statistics, setStatistics] = useState(PRUNE_STATS);
  const [isStatsModalShown, setIsStatsModalShown] = useState(false);
  const [wordNotFound, setWordNotFound] = useState(false);
  const [notMinLength, setNotMinLength] = useState(false);
  const [infoModalShown, setInfoModalShown] = useState(false);
  const [showSolution, setShowSolution] = useState(false);
  const [isGameWon, setIsGameWon] = useState(false);
  const [isChangesModalShown, setIsChangesModalShown] = useState(false);

  const gameContainer = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (gameContainer.current) {
      gameContainer.current.focus();
    }
  });

  useEffect(() => {
    if (!localStorage.watchedTutorial) {
      toggleInfoModal();
    }

    if (localStorage.gameState) {
      let save = JSON.parse(localStorage.gameState);

      if (save.lastPlayed === today) {
        setGuesses(save.guesses);
        setSolution(save.solution);
        setGameStatus(save.gameStatus);
      } else {
        let skippedDay = today - save.lastPlayed > DAY_MS;

        setLastPlayed(save.lastPlayed);
        localStorage.gameState = JSON.stringify({
          solution,
          guesses,
          gameStatus,
          lastPlayed: save.lastPlayed,
        });

        if (skippedDay && localStorage.statistics) {
          let prevStatistics = JSON.parse(localStorage.statistics);
          prevStatistics.currentStreak = 0;
          localStorage.statistics = JSON.stringify(prevStatistics);
        }
      }
    } else {
      localStorage.gameState = JSON.stringify({
        solution,
        guesses,
        gameStatus,
        lastPlayed,
      });
    }

    if (localStorage.statistics) {
      setStatistics(JSON.parse(localStorage.statistics));
    }

    if (localStorage.version) {
      if (localStorage.version < GAME_VERSION) {
        toggleChangesModal();
        localStorage.version = GAME_VERSION;
      }
    } else {
      localStorage.version = GAME_VERSION;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const keyPressHandler = (e: React.KeyboardEvent) => {
    let specialKeys = ["Enter", "Backspace"];
    if (
      GREEK_LETTERS.includes(e.key.toUpperCase()) ||
      specialKeys.includes(e.key)
    ) {
      let key = e.key === "Backspace" ? "DELETE" : e.key.toUpperCase();
      onKeyPress(key);
    }
  };

  useEffect(() => {
    if (gameStatus === "WON" || gameStatus === "LOST") {
      if (gameStatus === "WON") {
        setIsGameWon(true);
        setTimeout(() => {
          setIsGameWon(false);
        }, 1000);
      } else {
        setShowSolution(true);
        setTimeout(() => {
          setShowSolution(false);
        }, 1000);
      }
      setTimeout(() => {
        setIsStatsModalShown(true);
      }, 1050);
    }
  }, [gameStatus]);

  const onEnter = () => {
    if (gameStatus === "WON" || guesses.length === MAX_TRIES) {
      return;
    }

    if (currentGuess.length < WORD_LENGTH) {
      setNotMinLength(true);
      setTimeout(() => {
        setNotMinLength(false);
      }, 1000);
      return;
    }

    if (!DICTIONARY.includes(currentGuess)) {
      setWordNotFound(true);
      setTimeout(() => {
        setWordNotFound(false);
      }, 1000);
      return;
    }

    updateGameState();
  };

  const onDelete = () => {
    setCurrentGuess((prev) => prev.slice(0, -1));
  };

  const onKeyPress = (key: string) => {
    if (gameStatus === "WON") return;

    if (key === "ENTER") {
      onEnter();
      return;
    }
    if (key === "DELETE") {
      onDelete();
      return;
    }

    if (currentGuess.length < WORD_LENGTH && guesses.length < MAX_TRIES) {
      setCurrentGuess((prev) => `${prev}${key}`);
    }
  };

  const updateGameState = () => {
    setGuesses((prev) => {
      let current = currentGuess;
      let nextGuesses = [...prev, current];

      setCurrentGuess((prevCurrentGuess) => {
        let gs =
          prevCurrentGuess === solution
            ? "WON"
            : nextGuesses.length === MAX_TRIES
            ? "LOST"
            : gameStatus;

        if (gs === "WON" || gs === "LOST") {
          setGameStatus(gs);
          updateGameStatistics(gs, nextGuesses.length);
          if (gs === "LOST") {
            setShowSolution(true);
            setTimeout(() => {
              setShowSolution(false);
            }, 1000);
          }
        }

        localStorage.gameState = JSON.stringify({
          solution,
          guesses: [...guesses, current],
          gameStatus: gs,
          lastPlayed: gs === "IN_PROGRESS" ? lastPlayed : today,
        });

        return "";
      });

      return nextGuesses;
    });
  };

  const updateGameStatistics = (status: string, guesses: number) => {
    let skippedDay = today - lastPlayed > DAY_MS;
    let stats = { ...statistics };
    let currentStreak =
      status === "WON" && skippedDay
        ? 1
        : status === "LOST"
        ? 0
        : stats.currentStreak + 1;
    let bestStreak =
      currentStreak > stats.bestStreak ? currentStreak : stats.bestStreak;
    let gamesFailed =
      status === "WON"
        ? stats.gamesFailed
        : status === "LOST"
        ? stats.gamesFailed + 1
        : stats.gamesFailed;
    stats = {
      ...stats,
      bestStreak,
      currentStreak,
      gamesFailed,
      totalGames: stats.totalGames + 1,
      winDistribution:
        status === "WON"
          ? stats.winDistribution.map((win, i) =>
              i + 1 === guesses ? win + 1 : win
            )
          : stats.winDistribution,
    };

    let gamesWon = stats.totalGames - stats.gamesFailed;
    stats.successPercent = Math.trunc((gamesWon / stats.totalGames) * 100);
    localStorage.statistics = JSON.stringify(stats);

    let history = JSON.parse(localStorage?.history ?? '{"found":[],"lost":[]}');
    if (status === "WON") {
      history.found = [...history.found, solution];
    } else if (status === "LOST") {
      history.lost = [...history.lost, solution];
    }

    localStorage.history = JSON.stringify(history);
  };

  const toggleInfoModal = () => {
    setInfoModalShown((prev) => {
      if (!localStorage.watchedTutorial && !!prev) {
        localStorage.watchedTutorial = true;
      }
      return !prev;
    });
  };
  const toggleStatisticsModal = () => setIsStatsModalShown(!isStatsModalShown);
  const toggleChangesModal = () => setIsChangesModalShown(!isChangesModalShown);

  return (
    <div
      tabIndex={0}
      onKeyDown={(e) => keyPressHandler(e)}
      ref={gameContainer}
      className="mx-auto flex h-full max-w-xl flex-col justify-between p-2 outline-0"
    >
      <TitleBar
        toggleInfoModal={toggleInfoModal}
        toggleStatsModal={toggleStatisticsModal}
      />
      <div className="text-sm text-slate-800 dark:text-slate-300">
        {TEXT_CHALLENGE_FRIENDS}
      </div>
      <Grid guesses={guesses} currentGuess={currentGuess} />
      <Keyboard onKey={onKeyPress} guesses={guesses} solution={solution} />

      {/* Alerts */}
      <Alert status="success" isShown={isGameWon}>
        {SUCCESS_MESSAGES[Math.floor(Math.random() * SUCCESS_MESSAGES.length)]}
      </Alert>
      <Alert status="danger" isShown={wordNotFound}>
        {NO_WORD_MESSAGES[Math.floor(Math.random() * NO_WORD_MESSAGES.length)]}
      </Alert>
      <Alert status="info" isShown={notMinLength}>
        {
          NOT_MIN_LENGTH_MESSAGES[
            Math.floor(Math.random() * NOT_MIN_LENGTH_MESSAGES.length)
          ]
        }
      </Alert>
      <Alert status="info" isShown={showSolution}>
        <span className="text-xl font-bold">{solution}</span>
      </Alert>

      {/* Modals */}
      <StatisticsModal
        toggle={toggleStatisticsModal}
        isShown={isStatsModalShown}
      />
      <InfoModal toggle={toggleInfoModal} isShown={infoModalShown} />
      <ChangesModal toggle={toggleChangesModal} isShown={isChangesModalShown} />
    </div>
  );
};
