export const WORD_LENGTH = 5;
export const GAME_DOMAIN = "https://leksokefalia.bakedbird.com";
export const GAME_HASHTTAG = "#leksokefalia #leksokefalia";
export const GAME_VERSION = "0.6";
export const GREEK_LETTERS = "ΕΡΤΥΘΙΟΠΑΣΔΦΓΗΞΚΛΖΧΨΩΒΝΜ";
export const MAX_TRIES = 6;
export const DAY_MS = 86400000;
export const GAME_EPOCH = new Date("February 12, 2022 00:00:00").valueOf();
export const PRUNE_STATS = {
  bestStreak: 0,
  currentStreak: 0,
  successPercent: 0,
  gamesFailed: 0,
  totalGames: 0,
  winDistribution: [0, 0, 0, 0, 0, 0],
};
