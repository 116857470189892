type Props = { letter?: string; status?: string };

const Tile = ({ letter, status }: Props) => {
  let classNames =
    "m-0.5 flex h-14 w-14 items-center justify-center rounded-md border-2 ";

  switch (status) {
    case "absent":
      classNames += "border-slate-700 bg-slate-700 text-gray-300";
      break;
    case "correct":
      classNames += "border-emerald-700 bg-emerald-700 text-gray-300";
      break;
    case "present":
      classNames += "border-yellow-600 bg-yellow-600 text-gray-300";
      break;
    default:
      classNames += "border-slate-800 dark:border-slate-300 dark:text-gray-300";
      break;
  }

  return (
    <div className={classNames}>
      <span className="text-4xl font-extrabold">{letter ?? null}</span>
    </div>
  );
};

export default Tile;
