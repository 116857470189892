import { useEffect, useState } from "react";
import {
  GAME_NAME,
  TEXT_COPIED,
  TITLE_BEST_STREAK,
  TITLE_CURRENT_STREAK,
  TITLE_LOST_GAMES,
  TITLE_NEW_WORD_IN,
  TITLE_PERCENTAGE,
  TITLE_STATISTICS,
  TITLE_TOTAL_GAMES,
  TITLE_WIN_DISTRIBUTION,
  TITLE_WON_GAMES,
} from "../constants/strings";
import { DAY_MS, GAME_EPOCH, GAME_HASHTTAG } from "../constants/variables";
import Alert from "./Alert";
import CloseIcon from "./CloseIcon";
import { getLetterStatuses } from "./GuessRow";
import Modal from "./Modal";
import StatBox from "./StatBox";

type Props = {
  isShown: boolean;
  toggle: () => void;
};

const today = new Date(new Date().toDateString()).valueOf();
const tomorrow = today + DAY_MS;

const StatisticsModal = ({ isShown, toggle }: Props) => {
  const [newWordIn, setNewWordIn] = useState<number>(tomorrow - Date.now());
  const [isCopiedShown, setIsCopiedShown] = useState<boolean>(false);

  useEffect(() => {
    setInterval(() => {
      let remaining = tomorrow - Date.now();
      if (remaining > 0) {
        setNewWordIn(tomorrow - Date.now());
      } else {
        window.location.reload();
      }
    }, 1000);
  }, []);

  let stats = JSON.parse(localStorage?.statistics ?? "{}");
  let save = JSON.parse(localStorage?.gameState ?? "{}");
  let gamesWon = +(stats?.totalGames - stats?.gamesFailed) || 0;

  const formatMsToTime = () => {
    let seconds = Math.floor((newWordIn / 1000) % 60);
    let minutes = Math.floor((newWordIn / (1000 * 60)) % 60);
    let hours = Math.floor((newWordIn / (1000 * 60 * 60)) % 24);
    return `${hours < 10 ? `0${hours}` : hours}:${
      minutes < 10 ? `0${minutes}` : minutes
    }:${seconds < 10 ? `0${seconds}` : seconds}`;
  };

  const shareResults = () => {
    let gameNum = (today - GAME_EPOCH + DAY_MS) / DAY_MS;
    let guesses = save.gameStatus === "WON" ? save?.guesses.length : "X";
    let text = `${GAME_NAME} ${gameNum} ${guesses}/6`;
    let hashtags = GAME_HASHTTAG + gameNum;

    let grid = save?.guesses
      .map((guess: string) => {
        let statuses = getLetterStatuses(guess);
        let boxes = statuses.map((status) => {
          switch (status) {
            case "correct":
              return "🟩";

            case "absent":
              return "⬜";
            case "present":
              return "🟨";

            default:
              return "";
          }
        });

        return boxes.join("");
      })
      .join("\n");

    if (
      !!navigator.share &&
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      navigator.share({
        text: [text, grid].join("\n\n"),
      });
    } else {
      navigator.clipboard.writeText([text, grid, hashtags].join("\n\n"));
      setIsCopiedShown(true);
      setTimeout(() => {
        setIsCopiedShown(false);
      }, 1000);
    }
  };

  return (
    <div
      className={`opacity-0 ${isShown && "opacity-100"} transition ease-in-out`}
    >
      {isShown && (
        <Modal>
          <div className="relative">
            <h2 className="text-center text-xl">{TITLE_STATISTICS}</h2>
            <button onClick={toggle} className="absolute right-0 top-0">
              <CloseIcon />
            </button>
            <hr className="my-2" />
          </div>
          <div className="mt-2 grid grid-cols-3 gap-2">
            <StatBox title={TITLE_WON_GAMES} value={gamesWon} />
            <StatBox title={TITLE_LOST_GAMES} value={stats?.gamesFailed ?? 0} />
            <StatBox
              title={TITLE_PERCENTAGE}
              value={`${stats?.successPercent ?? 0}%`}
            />
            <StatBox title={TITLE_TOTAL_GAMES} value={stats?.totalGames ?? 0} />
            <StatBox title={TITLE_BEST_STREAK} value={stats?.bestStreak ?? 0} />
            <StatBox
              title={TITLE_CURRENT_STREAK}
              value={stats?.currentStreak ?? 0}
            />
          </div>
          {stats?.winDistribution && (
            <div>
              <hr className="my-3" />
              <h3 className="text-center text-lg">{TITLE_WIN_DISTRIBUTION}</h3>

              {stats?.winDistribution?.map((win: number, i: number) => (
                <div key={i} className="mb-2 flex">
                  <span className="w-5 pr-2">{i + 1}:</span>
                  <div
                    className={`min-w-min rounded-full px-2 text-slate-300 ${
                      save?.guesses && save?.gameStatus === "WON"
                        ? save?.guesses?.length === i + 1
                          ? "bg-blue-800"
                          : "bg-slate-500"
                        : "bg-blue-800"
                    }`}
                    style={{
                      width: `${(win / gamesWon) * 100 ?? 0}%`,
                    }}
                  >
                    {win}
                  </div>
                </div>
              ))}
            </div>
          )}
          {save.gameStatus !== "IN_PROGRESS" && (
            <>
              <hr className="my-3" />
              <div className="flex">
                <div className="flex flex-col">
                  <span className="">{TITLE_NEW_WORD_IN}</span>
                  <span className="text-xl font-bold">{formatMsToTime()}</span>
                </div>

                <button
                  onClick={shareResults}
                  className="my-auto ml-auto rounded bg-blue-800 px-3 py-2 text-slate-300"
                >
                  Μοιράσου το
                </button>
              </div>
            </>
          )}
          <div className="flex justify-center">
            <Alert status={"success"} isShown={isCopiedShown}>
              <span className="font-bold">{TEXT_COPIED}</span>
            </Alert>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default StatisticsModal;
