type Props = { title: string; value: string | number };

const StatBox = ({ title, value }: Props) => {
  return (
    <div className="flex flex-col items-center">
      <span className="text-2xl font-bold">{value}</span>
      <span className="whitespace-nowrap">{title}</span>
    </div>
  );
};

export default StatBox;
