export const GAME_NAME = "ΛΕΞΟΚΕΦΑΛΙΑ";
export const TITLE_STATISTICS = "Στατιστικά";
export const TITLE_INFO = "Πληροφορίες";
export const TITLE_TOTAL_GAMES = "Παιχνίδια";
export const TITLE_WON_GAMES = "Νίκες";
export const TITLE_LOST_GAMES = "Ήττες";
export const TITLE_PERCENTAGE = "Ποσοστό νίκης";
export const TITLE_BEST_STREAK = "Καλύτερο σερί";
export const TITLE_CURRENT_STREAK = "Τρέχων σερί";
export const TITLE_WIN_DISTRIBUTION = "Νίκες ανά προσπάθεια";
export const TITLE_NEW_WORD_IN = "Επόμενη λέξη";
export const TITLE_CHANGES = "Αλλαγές έκδοσης";

export const TEXT_COPIED = "Έγινε αντιγραφή!";
export const TEXT_NO_CONSENT = "Δεν θέλω";
export const TEXT_OK = "Εντάξει!";
export const TEXT_COOKIES =
  "Η σελίδα χρησιμοποιεί cookies μόνο για να υπολογιστούν πόσα άτομα χρησιμοποιούν το παιχνίδι και από που. Κανένα προσωπικό δεδομένο δε συλλέγεται ούτε χρησιμοποιείται για άλλους σκοπούς πέρα από αυτή τη μέτρηση. Με το να πατήσεις 'Εντάξει', απλά βοηθάς στο να καταλάβω πόσος κόσμος χρησιμοποιεί το παιχνίδι, κι αν αξίζει να το συνεχίσω. Η επιλογή σου δεν επιρεάζει σε τίποτα το παιχνίδι, και μπορείς να συνεχίσεις να παίζεις κανονικά! Μην ξεχάσεις να μοιραστείς το σκορ σου με τους φίλους σου!";
export const TEXT_CHALLENGE_FRIENDS =
  "Μάντεψε τη λέξη και προκάλεσε τους φίλους σου.";
export const TEXT_INFO =
  "Βρες τη λέξη σε 6 προσπάθειες. Μετά από κάθε προσπάθεια, το χρώμα των κουτιών θ' αλλάξει για να σου δείξει πόσο κοντά είσαι στη σωστή λέξη.";
export const TEXT_CORRECT_LETTER =
  "Πράσσινο, το γράμμα υπάρχει και είναι στη σωστή θέση";
export const TEXT_PRESENT_LETTER =
  "Κίτρινο, το γράμμα υπάρχει αλλά είναι στη λάθος θέση";
export const TEXT_ABSENT_LETTER =
  "Με γκρί είναι τα γράμματα που δεν υπάρχουν στη λέξη";

export const SUCCESS_MESSAGES = [
  "Καταπληκτικά",
  "Μπράβο σου",
  "Τα κατάφερες",
  "Απίστευτο",
];
export const NO_WORD_MESSAGES = [
  "Δεν την ξέρω αυτή τη λέξη",
  "Αυτή η λέξη μάλλον δεν υπάρχει",
  "Είναι λέξη αυτό;",
  "Σίγουρα υπάρχει αυτή η λέξη;",
];
export const NOT_MIN_LENGTH_MESSAGES = [
  "Κάθε λέξη είναι 5 γραμμάτων",
  "Πρέπει να βάλεεις 5 γράμματα",
  "Πρέπει να γεμίσεις όλα τα κουτάκια",
  "Δεν έβαλες 5 γράμματα",
];
