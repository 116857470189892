type Props = {
  letter?: string;
  width?: number;
  icon?: string;
  value: string;
  onKey: (key: string) => void;
  status?: string;
};

const Key = ({ letter, width = 40, icon, value, onKey, status }: Props) => {
  const statusClass = (() => {
    switch (status) {
      case "correct":
        return "bg-green-500";
      case "absent":
        return "bg-slate-500";
      case "present":
        return "bg-yellow-500";
      case "almost":
        return "bg-gradient-to-b to-yellow-500 from-green-500";
      case "correct-present":
        return "bg-gradient-to-t to-slate-300 via-slate-300 from-green-500";
      case "almost-present":
        return "bg-gradient-to-t to-slate-300 via-slate-300 from-yellow-500";

      default:
        return "bg-slate-300";
    }
  })();
  return (
    <button
      onClick={() => onKey(value)}
      className={`m-0.5  p-2 text-lg font-semibold ${statusClass}`}
      style={{ width, height: 55 }}
    >
      {letter}
      {icon && <img alt="key" src={icon} />}
    </button>
  );
};

export default Key;
