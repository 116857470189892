import { useEffect, useState } from "react";
import { ICON_MOON, ICON_SUN } from "../constants/icons";
import Icon from "./Icon";

const toggleHtmlClass = () => {
  const htmlEl = document.querySelector("html");

  if (+localStorage.darkTheme) {
    htmlEl?.classList.add("dark");
  } else {
    htmlEl?.classList.remove("dark");
  }
};

const DarkModeButton = () => {
  const [darkTheme, setDarkTheme] = useState(0);

  const initTheme = () => {
    if (!("darkTheme" in localStorage)) {
      localStorage.darkTheme = +window.matchMedia(
        "(prefers-color-scheme: dark)"
      ).matches;
    }

    setDarkTheme(+localStorage.darkTheme);
  };

  const toggleTheme = () => {
    setDarkTheme(+!darkTheme);
  };

  useEffect(() => {
    initTheme();
  }, []);

  useEffect(() => {
    localStorage.darkTheme = darkTheme;
    toggleHtmlClass();
  }, [darkTheme]);

  return (
    <button onClick={toggleTheme}>
      <Icon icon={darkTheme ? ICON_SUN : ICON_MOON} />
    </button>
  );
};

export default DarkModeButton;
