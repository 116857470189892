import { TITLE_CHANGES } from "../constants/strings";
import CloseIcon from "./CloseIcon";
import Modal from "./Modal";

type Props = {
  isShown: boolean;
  toggle: () => void;
};

const ChangesModal = ({ isShown, toggle }: Props) => {
  return (
    <div
      className={`opacity-0 ${isShown && "opacity-100"} transition ease-in-out`}
    >
      {isShown && (
        <Modal>
          <div className="relative">
            <h2 className="text-center text-xl">{TITLE_CHANGES}</h2>
            <button onClick={toggle} className="absolute right-0 top-0">
              <CloseIcon />
            </button>
            <hr className="my-2" />
          </div>
          <div className="overflow-y-auto">
            <span className="font-bold">v0.6</span>
            <ul className="list-disc pl-3">
              <li>Προσθήκη νέων λέξεων στο παιχνίδι</li>
              <li>Διορθώσεις στον τρόπο που υπολογίζονται τα σερί</li>
            </ul>

            <span className="font-bold">v0.5.1</span>
            <ul className="list-disc pl-3">
              <li>
                Διπλός χρωματισμός στο πληκτρολόγιο όταν το γράμμα που έχεις
                μαντέψει υπάρχει πάνω από μια φορές και δεν έχεις βρει όλες τις
                σωστές θέσεις.
              </li>
            </ul>

            <span className="font-bold">v0.5</span>
            <ul className="list-disc pl-3">
              <li>Διορθώσεις στη χρήση πληκτρολογίου από υπολογιστή</li>
              <li>
                Αν κάνεις ανανέωση τη σελίδα, οι λέξεις που έχεις μαντέψει,
                μένουν στη μνήμη
              </li>
              <li>
                Αλλαγή στα hashtags όταν μοιράζεσαι τ' αποτελέσματα από
                υπολογιστή
              </li>
            </ul>

            <span className="font-bold">v0.4</span>
            <ul className="list-disc pl-3">
              <li>
                Οταν τα γράμματα που έχεις μαντέψει, υπάρχουν στη λύση,
                χρωματίζονται ανάλογα.
              </li>
              <li>Χρήση του πληκτρολογίου από υπολογιστή</li>
            </ul>

            <span className="font-bold">v0.3</span>
            <ul className="list-disc pl-3">
              <li>
                Τώρα μπορείς να μοιράζεσαι τ' αποτελέσματά σου σε όλες τις
                εφαρμογές άμεσα, χωρίς αντιγραφή (από κινητό).
              </li>
              <li>Μικροαλλαγές στην εμφάνιση</li>
            </ul>

            <span className="font-bold">v0.2</span>
            <ul className="list-disc pl-3">
              <li>Διόρθωμα στο μέγεθος του πίνακα</li>
              <li>
                Την πρώτη φορά που κάποιος ανοίξει το παιχνίδι, ανοίγουν οι
                πληροφορίες
              </li>
            </ul>

            <span className="font-bold">v0.1</span>
            <p>Πρώτη έκδοση!</p>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default ChangesModal;
