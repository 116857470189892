import { GAME_EPOCH } from "./variables";

export const getTodayWord = () => {
  const now = Date.now();
  const msInDay = 86400000;
  const index = Math.floor((now - GAME_EPOCH) / msInDay);

  return GAME_WORDS[index % GAME_WORDS.length];
};

export const DICTIONARY: string[] = [
  "ΦΑΥΛΑ",
  "ΣΟΥΖΑ",
  "ΧΡΙΣΗ",
  "ΨΟΓΟΙ",
  "ΟΡΝΕΟ",
  "ΠΛΕΕΙ",
  "ΒΙΔΑΣ",
  "ΧΕΝΕΣ",
  "ΓΛΟΙΑ",
  "ΜΑΚΡΥ",
  "ΜΙΡΑΖ",
  "ΕΝΩΣΕ",
  "ΚΡΑΝΗ",
  "ΔΕΝΕΙ",
  "ΤΟΣΟΥ",
  "ΚΑΛΩΝ",
  "ΓΡΑΣΑ",
  "ΒΛΗΜΑ",
  "ΑΚΟΠΗ",
  "ΛΕΠΡΗ",
  "ΕΩΛΟΝ",
  "ΛΕΥΚΟ",
  "ΡΑΒΔΟ",
  "ΠΛΕΩΝ",
  "ΘΕΤΟΙ",
  "ΣΗΚΟΣ",
  "ΚΑΙΝΟ",
  "ΖΟΧΟΥ",
  "ΚΥΒΟΥ",
  "ΞΥΣΟΥ",
  "ΡΟΜΠΑ",
  "ΨΑΞΕΙ",
  "ΟΒΕΛΟ",
  "ΑΝΘΕΙ",
  "ΒΑΡΑΩ",
  "ΑΒΓΩΝ",
  "ΚΑΥΤΗ",
  "ΩΘΕΙΣ",
  "ΛΑΓΟΣ",
  "ΔΟΤΟΣ",
  "ΣΚΥΒΩ",
  "ΕΞΕΜΩ",
  "ΚΟΦΤΑ",
  "ΑΡΙΕΣ",
  "ΚΟΣΙΑ",
  "ΜΑΣΕΖ",
  "ΑΙΣΙΟ",
  "ΑΝΤΡΟ",
  "ΦΑΙΗΣ",
  "ΝΥΦΗΣ",
  "ΧΥΜΩΝ",
  "ΕΝΟΣΩ",
  "ΠΙΕΖΕ",
  "ΧΥΛΩΝ",
  "ΑΤΟΠΗ",
  "ΝΩΠΗΣ",
  "ΣΚΑΨΩ",
  "ΖΟΜΠΙ",
  "ΤΣΑΡΟ",
  "ΒΡΕΘΩ",
  "ΟΠΤΟΣ",
  "ΨΑΜΜΟ",
  "ΡΙΨΕΙ",
  "ΛΑΖΟΣ",
  "ΜΟΔΙΟ",
  "ΠΑΘΕΙ",
  "ΛΟΓΙΕ",
  "ΛΟΙΠΕ",
  "ΕΠΑΘΑ",
  "ΔΙΩΞΕ",
  "ΓΡΥΠΟ",
  "ΤΡΙΨΩ",
  "ΥΨΩΝΕ",
  "ΓΛΑΡΕ",
  "ΑΓΕΛΗ",
  "ΧΩΣΑΝ",
  "ΕΘΙΓΗ",
  "ΒΟΥΝΟ",
  "ΚΟΥΙΖ",
  "ΩΙΔΙΑ",
  "ΗΒΙΚΗ",
  "ΠΕΤΡΑ",
  "ΣΕΜΝΕ",
  "ΑΒΑΦΕ",
  "ΜΑΝΑΣ",
  "ΖΕΡΒΑ",
  "ΠΑΓΟΙ",
  "ΘΥΡΑΣ",
  "ΓΔΑΡΩ",
  "ΑΣΗΜΕ",
  "ΒΡΙΖΩ",
  "ΖΕΨΤΕ",
  "ΑΝΘΟΙ",
  "ΜΙΣΕΣ",
  "ΜΕΣΤΑ",
  "ΚΑΡΕΣ",
  "ΧΑΡΤΗ",
  "ΒΙΑΙΟ",
  "ΖΟΦΟΙ",
  "ΑΠΟΨΗ",
  "ΜΟΥΦΑ",
  "ΓΡΙΒΑ",
  "ΓΛΑΡΑ",
  "ΑΤΥΠΕ",
  "ΝΙΑΜΑ",
  "ΤΑΦΟΥ",
  "ΟΣΙΩΣ",
  "ΜΝΗΜΑ",
  "ΛΑΜΩΝ",
  "ΙΔΙΟΣ",
  "ΠΙΕΖΩ",
  "ΔΥΤΗΣ",
  "ΘΟΛΕΣ",
  "ΑΝΔΡΟ",
  "ΦΤΑΙΩ",
  "ΨΑΧΝΩ",
  "ΤΥΧΕΙ",
  "ΑΦΑΝΑ",
  "ΑΜΑΡΑ",
  "ΕΔΟΘΗ",
  "ΣΠΑΗΣ",
  "ΠΡΟΖΑ",
  "ΟΙΚΙΑ",
  "ΖΑΛΙΑ",
  "ΑΘΕΗΣ",
  "ΒΗΧΑΣ",
  "ΣΙΡΟΙ",
  "ΜΥΩΠΑ",
  "ΜΟΡΕΣ",
  "ΓΑΖΕΣ",
  "ΕΠΑΥΕ",
  "ΑΙΩΝΑ",
  "ΣΦΥΡΟ",
  "ΒΕΛΟΣ",
  "ΓΕΡΟΥ",
  "ΔΥΕΤΕ",
  "ΓΑΤΕΣ",
  "ΛΑΘΕΙ",
  "ΛΟΙΠΟ",
  "ΛΥΠΕΣ",
  "ΘΗΚΗΣ",
  "ΓΕΝΕΙ",
  "ΝΕΡΟΥ",
  "ΚΟΥΛΕ",
  "ΚΛΩΝΙ",
  "ΜΗΝΙΣ",
  "ΚΗΡΙΟ",
  "ΠΗΛΟΙ",
  "ΣΠΑΝΗ",
  "ΑΡΙΔΑ",
  "ΟΡΓΙΑ",
  "ΤΣΕΛΑ",
  "ΔΙΑΚΕ",
  "ΡΩΜΩΝ",
  "ΟΡΟΦΕ",
  "ΜΥΞΕΣ",
  "ΨΟΓΟΣ",
  "ΠΛΟΙΑ",
  "ΡΟΥΧΑ",
  "ΜΥΛΗΣ",
  "ΣΤΑΛΑ",
  "ΟΠΑΔΟ",
  "ΘΝΗΤΕ",
  "ΠΑΛΜΕ",
  "ΠΟΥΡΕ",
  "ΗΡΩΩΝ",
  "ΞΑΡΤΙ",
  "ΓΑΤΩΝ",
  "ΕΚΟΨΕ",
  "ΦΘΗΝΟ",
  "ΦΩΝΩΝ",
  "ΕΚΛΥΩ",
  "ΛΙΜΟΥ",
  "ΥΨΩΣΑ",
  "ΟΡΔΗΣ",
  "ΑΓΕΙΣ",
  "ΠΟΖΑΣ",
  "ΛΗΞΑΝ",
  "ΝΙΦΤΩ",
  "ΖΕΒΡΕ",
  "ΕΥΧΩΝ",
  "ΒΙΚΟΙ",
  "ΠΙΤΣΑ",
  "ΥΕΤΩΝ",
  "ΤΑΓΚΗ",
  "ΑΠΑΘΗ",
  "ΣΤΑΝΤ",
  "ΦΡΙΞΩ",
  "ΜΑΔΑΩ",
  "ΤΙΛΟΣ",
  "ΓΕΛΕΣ",
  "ΕΘΑΨΑ",
  "ΜΠΕΤΟ",
  "ΧΥΤΕΣ",
  "ΠΟΣΩΝ",
  "ΚΛΑΜΠ",
  "ΝΕΡΩΝ",
  "ΜΟΛΟΥ",
  "ΛΑΧΝΟ",
  "ΦΑΡΣΑ",
  "ΗΡΕΜΑ",
  "ΗΘΜΟΥ",
  "ΡΕΓΓΕ",
  "ΙΧΘΥΣ",
  "ΤΣΙΟΥ",
  "ΕΠΝΕΕ",
  "ΣΙΩΠΩ",
  "ΟΞΙΑΣ",
  "ΒΕΡΟΥ",
  "ΠΑΡΙΕ",
  "ΚΟΥΠΑ",
  "ΤΑΦΩΝ",
  "ΦΟΒΟΥ",
  "ΠΑΛΜΟ",
  "ΟΓΚΟΙ",
  "ΒΙΩΣΗ",
  "ΘΕΛΕΙ",
  "ΛΥΔΙΟ",
  "ΤΖΙΦΟ",
  "ΑΛΙΕΑ",
  "ΦΑΝΤΗ",
  "ΠΛΑΚΙ",
  "ΑΝΗΘΟ",
  "ΛΑΡΟΣ",
  "ΒΡΕΞΕ",
  "ΨΗΛΕΣ",
  "ΛΑΔΑΣ",
  "ΑΓΟΝΟ",
  "ΑΟΡΤΗ",
  "ΤΙΛΙΟ",
  "ΕΞΑΨΕ",
  "ΙΚΤΙΣ",
  "ΚΟΛΙΕ",
  "ΑΒΑΤΟ",
  "ΑΤΜΟΝ",
  "ΒΟΙΔΙ",
  "ΠΟΙΕΙ",
  "ΔΟΥΛΕ",
  "ΡΟΝΙΑ",
  "ΥΜΝΟΣ",
  "ΑΠΟΡΟ",
  "ΜΑΜΟΣ",
  "ΛΟΞΑΣ",
  "ΦΟΡΤΟ",
  "ΘΛΙΨΕ",
  "ΠΟΣΘΗ",
  "ΑΠΤΕΣ",
  "ΟΠΛΩΝ",
  "ΣΠΑΣΕ",
  "ΤΕΦΡΑ",
  "ΙΣΑΛΑ",
  "ΓΑΤΑΣ",
  "ΓΔΥΣΕ",
  "ΒΡΕΦΗ",
  "ΒΑΘΩΝ",
  "ΖΕΡΒΟ",
  "ΨΟΦΑΣ",
  "ΟΡΦΝΑ",
  "ΓΔΥΝΩ",
  "ΚΑΔΡΟ",
  "ΑΛΕΣΑ",
  "ΡΕΝΤΑ",
  "ΙΚΑΡΟ",
  "ΟΠΟΥΣ",
  "ΝΙΩΘΕ",
  "ΡΙΝΕΣ",
  "ΠΑΡΙΑ",
  "ΣΚΙΖΕ",
  "ΠΗΡΑΣ",
  "ΣΕΖΟΝ",
  "ΠΕΤΟΥ",
  "ΒΑΨΙΣ",
  "ΑΝΙΣΗ",
  "ΣΟΓΙΑ",
  "ΕΝΘΕΟ",
  "ΣΤΑΜΑ",
  "ΓΡΟΣΙ",
  "ΔΟΜΗΣ",
  "ΣΚΑΖΕ",
  "ΑΤΑΚΑ",
  "ΠΕΙΡΟ",
  "ΣΟΥΞΕ",
  "ΠΕΛΟΣ",
  "ΜΙΣΟΥ",
  "ΠΟΙΕΣ",
  "ΑΠΝΟΗ",
  "ΤΕΡΜΑ",
  "ΛΑΣΩΝ",
  "ΜΠΗΓΩ",
  "ΑΗΧΕΣ",
  "ΔΕΡΜΑ",
  "ΚΙΡΚΟ",
  "ΔΑΚΟΙ",
  "ΑΛΜΗΣ",
  "ΤΑΞΙΣ",
  "ΙΣΙΩΝ",
  "ΕΛΥΣΕ",
  "ΜΥΣΤΗ",
  "ΘΑΦΤΑ",
  "ΔΥΑΜΕ",
  "ΝΙΟΥΣ",
  "ΑΣΤΡΟ",
  "ΡΙΠΟΣ",
  "ΥΙΟΥΣ",
  "ΤΑΓΕΣ",
  "ΧΑΛΝΩ",
  "ΡΥΘΜΕ",
  "ΤΑΠΕΣ",
  "ΧΙΛΙΑ",
  "ΠΑΙΖΑ",
  "ΕΡΓΟΥ",
  "ΗΜΕΡΕ",
  "ΠΕΣΤΕ",
  "ΑΛΑΝΙ",
  "ΤΑΥΡΕ",
  "ΜΟΥΡΗ",
  "ΚΑΥΜΑ",
  "ΤΡΩΓΕ",
  "ΠΕΡΝΩ",
  "ΦΗΓΟΙ",
  "ΚΟΜΠΟ",
  "ΒΛΟΓΑ",
  "ΠΡΩΡΑ",
  "ΤΕΚΝΑ",
  "ΕΞΗΨΑ",
  "ΡΕΠΙΑ",
  "ΞΙΦΟΣ",
  "ΙΖΑΝΩ",
  "ΕΡΜΩΝ",
  "ΟΡΜΗΣ",
  "ΚΟΦΤΟ",
  "ΜΕΡΑΣ",
  "ΑΧΟΛΗ",
  "ΚΥΚΝΟ",
  "ΠΟΣΟΣ",
  "ΛΟΥΕΙ",
  "ΙΣΧΝΑ",
  "ΓΑΜΨΕ",
  "ΞΕΝΕΣ",
  "ΠΟΡΔΗ",
  "ΦΩΝΗΣ",
  "ΧΑΡΑΣ",
  "ΓΥΛΟΣ",
  "ΟΡΜΟΥ",
  "ΣΑΓΗΣ",
  "ΕΡΑΒΑ",
  "ΟΡΙΟΝ",
  "ΠΑΣΑΝ",
  "ΥΔΡΟΣ",
  "ΔΙΦΥΗ",
  "ΝΤΥΘΩ",
  "ΦΘΑΝΑ",
  "ΑΚΟΠΟ",
  "ΓΟΜΦΩ",
  "ΟΧΕΙΑ",
  "ΖΩΜΟΣ",
  "ΓΑΜΨΑ",
  "ΧΡΕΟΣ",
  "ΑΤΥΧΕ",
  "ΙΛΑΡΗ",
  "ΚΟΥΦΗ",
  "ΣΚΕΤΟ",
  "ΚΟΥΦΟ",
  "ΡΑΝΕΙ",
  "ΔΡΥΩΝ",
  "ΓΛΑΣΕ",
  "ΜΗΡΩΝ",
  "ΠΙΛΟΙ",
  "ΜΑΜΑΣ",
  "ΒΑΘΙΑ",
  "ΓΥΨΑΣ",
  "ΕΘΙΖΑ",
  "ΑΜΕΛΗ",
  "ΩΣΠΟΥ",
  "ΓΙΩΤΑ",
  "ΒΑΖΑΝ",
  "ΑΕΡΓΑ",
  "ΕΒΑΛΑ",
  "ΒΟΥΔΑ",
  "ΜΠΑΛΑ",
  "ΛΙΓΝΟ",
  "ΚΙΣΤΗ",
  "ΠΑΚΩΝ",
  "ΜΕΣΟΣ",
  "ΑΚΗΔΗ",
  "ΕΔΙΚΕ",
  "ΧΑΛΑΣ",
  "ΟΒΟΛΟ",
  "ΑΟΠΛΑ",
  "ΚΟΙΛΕ",
  "ΑΧΥΡΑ",
  "ΣΕΡΕΣ",
  "ΜΗΝΥΑ",
  "ΠΑΤΣΑ",
  "ΦΟΡΟΣ",
  "ΟΞΕΩΣ",
  "ΛΙΑΝΗ",
  "ΟΙΚΟΣ",
  "ΕΦΗΒΗ",
  "ΟΥΛΟΥ",
  "ΚΑΒΟΥ",
  "ΔΥΝΕΣ",
  "ΣΠΑΡΕ",
  "ΠΥΚΝΟ",
  "ΟΛΚΕΣ",
  "ΑΘΕΙΑ",
  "ΞΟΦΛΑ",
  "ΣΑΤΕΝ",
  "ΜΥΤΗΣ",
  "ΑΜΦΙΟ",
  "ΠΑΝΙΑ",
  "ΤΑΜΙΑ",
  "ΕΞΩΘΩ",
  "ΑΤΕΛΗ",
  "ΑΦΕΘΩ",
  "ΤΟΝΕΡ",
  "ΥΠΑΓΕ",
  "ΕΝΔΥΑ",
  "ΖΩΗΡΗ",
  "ΚΥΛΙΕ",
  "ΨΗΦΑΣ",
  "ΦΟΥΜΑ",
  "ΙΛΑΡΑ",
  "ΠΡΩΗΝ",
  "ΑΣΠΙΣ",
  "ΓΥΜΝΗ",
  "ΑΦΡΩΝ",
  "ΦΙΔΙΑ",
  "ΛΙΓΝΗ",
  "ΜΕΓΑΣ",
  "ΜΕΛΛΩ",
  "ΜΟΝΕΣ",
  "ΓΗΙΝΟ",
  "ΟΥΤΩΣ",
  "ΔΑΥΛΕ",
  "ΓΟΕΡΗ",
  "ΑΕΡΑΣ",
  "ΑΖΩΤΟ",
  "ΠΗΧΗΣ",
  "ΜΥΡΟΝ",
  "ΦΥΓΕΣ",
  "ΠΛΟΙΟ",
  "ΑΘΩΕΣ",
  "ΣΑΙΝΙ",
  "ΣΚΟΛΗ",
  "ΑΜΑΝΕ",
  "ΖΟΥΛΑ",
  "ΥΨΩΝΑ",
  "ΤΟΚΟΣ",
  "ΕΘΕΤΑ",
  "ΣΕΦΤΕ",
  "ΧΑΛΒΑ",
  "ΣΚΥΛΟ",
  "ΣΤΙΒΕ",
  "ΚΟΡΝΑ",
  "ΙΠΠΟΙ",
  "ΤΙΜΗΣ",
  "ΕΣΩΝΕ",
  "ΒΩΒΟΙ",
  "ΘΕΩΡΕ",
  "ΖΟΦΟΥ",
  "ΒΑΛΤΟ",
  "ΛΕΙΧΩ",
  "ΒΟΛΒΟ",
  "ΧΕΛΜΟ",
  "ΤΟΜΟΣ",
  "ΧΤΥΠΩ",
  "ΝΟΝΕΣ",
  "ΦΑΚΟΙ",
  "ΕΡΓΟΝ",
  "ΚΕΧΡΙ",
  "ΓΕΡΤΕ",
  "ΤΡΟΧΕ",
  "ΣΤΑΘΩ",
  "ΜΙΚΡΑ",
  "ΕΠΗΓΕ",
  "ΒΙΟΛΙ",
  "ΦΛΑΡΟ",
  "ΑΜΦΙΑ",
  "ΑΓΡΟΣ",
  "ΛΙΚΕΡ",
  "ΣΩΣΜΕ",
  "ΟΛΙΚΗ",
  "ΑΝΗΒΟ",
  "ΕΓΚΥΟ",
  "ΦΗΜΩΝ",
  "ΒΩΜΟΣ",
  "ΝΟΘΟΥ",
  "ΜΙΛΕΙ",
  "ΑΡΑΓΕ",
  "ΡΟΔΗΣ",
  "ΔΕΡΑΣ",
  "ΦΑΣΙΣ",
  "ΥΠΟΨΗ",
  "ΑΒΑΤΕ",
  "ΑΡΓΟΥ",
  "ΑΓΑΘΗ",
  "ΓΚΑΓΚ",
  "ΦΤΕΡΗ",
  "ΠΗΡΟΣ",
  "ΗΝΙΟΝ",
  "ΚΑΙΡΟ",
  "ΑΜΩΜΟ",
  "ΦΙΚΩΝ",
  "ΑΝΕΜΟ",
  "ΘΕΡΜΗ",
  "ΣΑΛΤΟ",
  "ΙΝΙΩΝ",
  "ΣΤΟΩΝ",
  "ΝΤΑΛΑ",
  "ΣΑΚΟΣ",
  "ΒΙΖΑΣ",
  "ΑΥΛΟΙ",
  "ΒΑΣΗΣ",
  "ΠΑΝΣΕ",
  "ΓΑΜΨΗ",
  "ΣΤΕΦΕ",
  "ΡΗΣΩΝ",
  "ΧΑΡΕΣ",
  "ΟΨΙΜΟ",
  "ΠΑΧΥΣ",
  "ΣΚΕΠΩ",
  "ΔΕΙΤΕ",
  "ΨΑΡΟΣ",
  "ΣΚΥΛΑ",
  "ΣΚΙΑΣ",
  "ΑΓΟΥΣ",
  "ΑΕΡΙΕ",
  "ΜΑΘΟΥ",
  "ΠΡΑΞΗ",
  "ΜΟΥΡΟ",
  "ΓΚΛΟΠ",
  "ΗΠΙΟΣ",
  "ΦΑΝΕΙ",
  "ΑΔΗΛΑ",
  "ΙΣΑΛΟ",
  "ΔΙΧΤΥ",
  "ΚΑΤΗΣ",
  "ΛΟΥΣΩ",
  "ΦΙΝΟΙ",
  "ΕΙΚΟΣ",
  "ΟΧΗΜΑ",
  "ΡΥΣΗΣ",
  "ΣΦΑΚΑ",
  "ΛΟΡΔΟ",
  "ΑΓΛΑΟ",
  "ΚΑΛΩΣ",
  "ΚΟΡΔΑ",
  "ΡΗΧΟΙ",
  "ΠΙΑΝΩ",
  "ΘΕΙΟΣ",
  "ΜΥΧΟΙ",
  "ΦΕΥΓΩ",
  "ΕΞΑΝΕ",
  "ΦΩΤΟΣ",
  "ΜΑΜΩΝ",
  "ΚΟΡΟΥ",
  "ΑΜΟΛΩ",
  "ΚΑΝΘΟ",
  "ΒΙΑΣΩ",
  "ΨΑΧΤΩ",
  "ΧΑΖΟΙ",
  "ΔΙΨΑΩ",
  "ΦΛΟΚΑ",
  "ΕΥΦΥΗ",
  "ΗΡΩΑΣ",
  "ΜΟΛΟΙ",
  "ΚΛΙΤΑ",
  "ΕΔΥΣΑ",
  "ΝΟΗΣΗ",
  "ΔΙΚΙΑ",
  "ΠΥΛΕΣ",
  "ΟΣΜΙΑ",
  "ΦΛΟΚΙ",
  "ΦΕΓΓΗ",
  "ΦΑΚΤΟ",
  "ΛΙΜΕΣ",
  "ΒΟΛΟΝ",
  "ΑΙΤΙΟ",
  "ΒΑΣΙΣ",
  "ΜΠΡΟΣ",
  "ΣΑΛΜΙ",
  "ΖΥΘΩΝ",
  "ΠΟΘΟΥ",
  "ΦΑΣΜΑ",
  "ΣΗΨΗΣ",
  "ΥΓΡΟΥ",
  "ΑΡΑΚΑ",
  "ΚΛΩΣΗ",
  "ΑΛΛΟΝ",
  "ΧΑΝΝΟ",
  "ΡΗΞΗΣ",
  "ΚΛΑΝΩ",
  "ΑΦΑΤΟ",
  "ΧΙΜΑΝ",
  "ΕΞΩΘΙ",
  "ΑΓΑΝΕ",
  "ΚΥΒΟΣ",
  "ΒΟΡΡΑ",
  "ΠΟΥΡΙ",
  "ΡΗΣΟΥ",
  "ΠΗΚΤΑ",
  "ΠΕΣΟΣ",
  "ΟΣΜΩΝ",
  "ΒΙΩΣΩ",
  "ΠΤΕΡΑ",
  "ΜΠΗΓΕ",
  "ΕΞΥΣΑ",
  "ΕΤΑΞΑ",
  "ΛΑΒΩΝ",
  "ΦΥΛΑΣ",
  "ΑΠΛΩΝ",
  "ΝΕΥΣΗ",
  "ΜΑΝΟΥ",
  "ΕΣΕΙΑ",
  "ΘΥΜΟΝ",
  "ΓΟΡΓΗ",
  "ΒΥΘΙΕ",
  "ΒΟΗΘΩ",
  "ΚΤΙΣΗ",
  "ΟΡΘΕΣ",
  "ΓΕΝΝΑ",
  "ΧΝΩΤΟ",
  "ΒΟΤΚΑ",
  "ΑΖΥΜΕ",
  "ΑΩΡΩΝ",
  "ΣΑΝΩΝ",
  "ΨΩΛΗΣ",
  "ΣΕΛΜΑ",
  "ΣΤΗΣΕ",
  "ΕΜΒΙΕ",
  "ΜΑΝΙΑ",
  "ΝΟΤΟΣ",
  "ΑΓΙΟΝ",
  "ΤΡΕΜΕ",
  "ΡΟΦΟΙ",
  "ΛΑΘΩΝ",
  "ΙΣΧΥΣ",
  "ΠΑΓΟΣ",
  "ΜΗΝΕΣ",
  "ΕΦΕΞΑ",
  "ΕΨΕΞΑ",
  "ΦΥΓΩΝ",
  "ΖΑΒΩΝ",
  "ΜΙΚΤΟ",
  "ΗΘΟΥΣ",
  "ΖΗΣΤΕ",
  "ΜΥΧΙΑ",
  "ΕΡΙΣΩ",
  "ΕΙΧΕΣ",
  "ΣΚΕΠΕ",
  "ΛΙΧΝΑ",
  "ΘΕΙΟΥ",
  "ΧΑΝΕΣ",
  "ΨΗΦΙΟ",
  "ΚΡΟΤΕ",
  "ΒΩΒΟΥ",
  "ΝΟΕΙΣ",
  "ΛΟΦΟΣ",
  "ΔΙΩΞΩ",
  "ΓΑΜΜΑ",
  "ΣΚΕΤΑ",
  "ΚΟΦΤΕ",
  "ΒΛΑΜΗ",
  "ΑΔΩΡΟ",
  "ΒΟΟΥΝ",
  "ΝΟΣΟΣ",
  "ΚΛΙΤΕ",
  "ΒΓΕΙΣ",
  "ΘΡΗΝΩ",
  "ΣΑΞΕΙ",
  "ΦΤΥΣΕ",
  "ΣΛΕΠΙ",
  "ΚΛΑΣΗ",
  "ΜΕΡΙΣ",
  "ΑΔΙΚΩ",
  "ΚΑΜΠΟ",
  "ΤΣΙΜΑ",
  "ΥΠΤΙΑ",
  "ΑΓΕΝΗ",
  "ΕΛΚΥΩ",
  "ΓΟΡΓΕ",
  "ΕΚΣΠΩ",
  "ΑΜΥΧΗ",
  "ΘΕΙΚΑ",
  "ΝΑΥΛΑ",
  "ΑΓΙΩΝ",
  "ΟΜΑΛΑ",
  "ΑΧΙΝΟ",
  "ΗΧΕΡΟ",
  "ΕΒΑΨΕ",
  "ΘΗΛΥΣ",
  "ΑΙΤΟΣ",
  "ΕΙΣΤΕ",
  "ΑΜΝΙΟ",
  "ΤΑΞΗΣ",
  "ΑΝΘΟΣ",
  "ΤΕΡΑΣ",
  "ΚΑΕΙΣ",
  "ΚΩΛΟΝ",
  "ΕΡΗΜΗ",
  "ΝΙΩΘΩ",
  "ΟΙΝΟΝ",
  "ΦΙΟΡΔ",
  "ΑΝΔΡΑ",
  "ΠΟΜΦΟ",
  "ΕΡΩΤΑ",
  "ΚΥΑΝΑ",
  "ΜΟΝΩΝ",
  "ΓΕΝΝΩ",
  "ΑΦΤΙΑ",
  "ΠΙΟΝΙ",
  "ΘΕΡΜΕ",
  "ΜΑΙΕΣ",
  "ΟΦΕΛΗ",
  "ΑΓΑΝΟ",
  "ΕΤΥΜΟ",
  "ΓΚΟΛΦ",
  "ΑΔΥΤΟ",
  "ΚΛΕΨΕ",
  "ΑΥΓΕΣ",
  "ΣΟΜΦΑ",
  "ΚΩΛΟΙ",
  "ΝΤΥΣΩ",
  "ΑΧΟΥΣ",
  "ΓΕΥΤΩ",
  "ΚΑΥΣΕ",
  "ΠΛΑΤΥ",
  "ΜΕΙΝΕ",
  "ΑΡΑΞΑ",
  "ΣΑΛΙΟ",
  "ΜΕΡΩΝ",
  "ΑΣΚΟΥ",
  "ΑΠΝΟΥ",
  "ΧΙΡΩΝ",
  "ΦΘΑΣΩ",
  "ΟΛΜΟΙ",
  "ΓΑΡΟΙ",
  "ΕΞΗΓΑ",
  "ΨΑΡΩΝ",
  "ΑΘΟΛΗ",
  "ΤΥΦΛΕ",
  "ΧΑΟΥΣ",
  "ΝΙΒΕΙ",
  "ΑΚΑΚΑ",
  "ΑΧΡΟΕ",
  "ΨΑΜΜΕ",
  "ΠΤΗΝΟ",
  "ΨΗΦΑΩ",
  "ΚΑΙΤΕ",
  "ΑΦΑΤΗ",
  "ΦΕΓΓΩ",
  "ΜΑΣΤΕ",
  "ΜΠΕΗΣ",
  "ΠΙΕΣΗ",
  "ΝΟΜΟΥ",
  "ΧΥΜΑΝ",
  "ΦΑΡΕΣ",
  "ΑΦΩΝΕ",
  "ΜΟΥΣΑ",
  "ΧΩΡΑΣ",
  "ΛΥΘΡΟ",
  "ΞΕΛΕΩ",
  "ΑΝΗΚΕ",
  "ΡΟΔΙΟ",
  "ΠΟΘΩΝ",
  "ΤΟΛΜΩ",
  "ΦΑΙΑΣ",
  "ΜΠΑΚΑ",
  "ΦΕΡΤΑ",
  "ΣΚΑΤΕ",
  "ΑΡΓΙΑ",
  "ΦΕΙΔΩ",
  "ΕΝΘΕΝ",
  "ΠΕΤΑΝ",
  "ΝΙΑΟΥ",
  "ΡΗΤΡΑ",
  "ΟΝΟΥΣ",
  "ΦΙΝΩΝ",
  "ΣΙΜΩΝ",
  "ΑΠΝΟΟ",
  "ΧΑΖΙΑ",
  "ΤΡΙΤΕ",
  "ΛΙΤΟΙ",
  "ΑΡΙΟΙ",
  "ΗΘΜΩΝ",
  "ΑΚΜΕΣ",
  "ΚΛΑΝΕ",
  "ΥΨΩΝΩ",
  "ΜΕΝΕΑ",
  "ΣΦΑΓΗ",
  "ΜΥΧΙΕ",
  "ΠΤΥΩΝ",
  "ΟΔΙΚΕ",
  "ΤΟΚΟΥ",
  "ΡΙΓΟΣ",
  "ΧΕΙΡΩ",
  "ΑΠΙΟΝ",
  "ΓΡΕΖΙ",
  "ΑΛΥΤΗ",
  "ΑΞΙΩΝ",
  "ΞΥΣΙΑ",
  "ΧΑΡΜΑ",
  "ΠΙΚΑΣ",
  "ΣΥΧΝΑ",
  "ΟΡΚΟΙ",
  "ΝΟΗΤΑ",
  "ΜΗΤΙΣ",
  "ΕΧΘΡΑ",
  "ΠΕΤΩΝ",
  "ΒΑΡΙΟ",
  "ΣΟΦΗΣ",
  "ΛΙΜΟΙ",
  "ΑΠΑΛΗ",
  "ΤΙΓΚΑ",
  "ΓΡΕΓΟ",
  "ΥΠΕΡΕ",
  "ΠΛΕΚΕ",
  "ΤΥΧΑΝ",
  "ΛΕΙΩΝ",
  "ΠΛΑΝΕ",
  "ΦΟΡΒΗ",
  "ΠΡΑΣΟ",
  "ΣΠΑΛΑ",
  "ΦΛΩΡΕ",
  "ΚΟΨΙΑ",
  "ΙΚΕΤΗ",
  "ΘΑΡΡΩ",
  "ΓΡΑΨΕ",
  "ΤΜΗΤΕ",
  "ΟΡΧΩΝ",
  "ΚΟΥΝΑ",
  "ΣΑΦΩΣ",
  "ΔΙΟΠΟ",
  "ΑΡΧΕΣ",
  "ΛΥΡΩΝ",
  "ΑΜΝΟΣ",
  "ΖΑΛΕΣ",
  "ΔΕΣΟΥ",
  "ΣΠΑΝΑ",
  "ΕΘΕΣΕ",
  "ΚΟΨΙΣ",
  "ΤΟΜΕΣ",
  "ΜΑΙΑΣ",
  "ΤΑΡΑΣ",
  "ΚΛΕΨΩ",
  "ΩΣΕΩΣ",
  "ΒΑΔΗΝ",
  "ΣΥΚΟΥ",
  "ΕΞΑΝΑ",
  "ΕΜΒΙΟ",
  "ΣΤΙΞΕ",
  "ΕΣΑΞΕ",
  "ΒΡΙΖΕ",
  "ΠΟΙΟΝ",
  "ΝΟΗΘΩ",
  "ΓΟΗΤΑ",
  "ΦΤΑΙΣ",
  "ΚΟΙΛΟ",
  "ΚΥΛΙΑ",
  "ΜΙΣΗΣ",
  "ΕΠΑΨΕ",
  "ΦΥΓΕΙ",
  "ΔΥΙΚΟ",
  "ΠΙΚΡΟ",
  "ΑΗΘΩΣ",
  "ΙΝΙΟΥ",
  "ΩΜΙΚΗ",
  "ΒΡΕΧΩ",
  "ΧΗΡΟΙ",
  "ΝΑΝΟΥ",
  "ΨΑΘΩΝ",
  "ΣΧΙΖΕ",
  "ΕΙΔΗΣ",
  "ΑΦΩΤΕ",
  "ΣΑΓΡΕ",
  "ΡΑΦΙΑ",
  "ΕΝΕΟΥ",
  "ΗΛΙΟΝ",
  "ΟΣΜΗΣ",
  "ΣΑΧΛΕ",
  "ΓΙΟΜΑ",
  "ΡΟΛΟΙ",
  "ΒΕΡΟΣ",
  "ΑΕΡΓΕ",
  "ΑΛΚΗΣ",
  "ΑΓΑΜΕ",
  "ΑΓΑΘΑ",
  "ΜΙΜΩΝ",
  "ΦΑΤΣΑ",
  "ΠΕΣΟΝ",
  "ΧΡΟΝΩ",
  "ΕΚΤΟΝ",
  "ΠΟΡΝΕ",
  "ΓΡΑΔΟ",
  "ΕΔΡΩΝ",
  "ΙΣΚΕΣ",
  "ΣΠΙΘΑ",
  "ΧΗΝΑΣ",
  "ΑΙΝΤΕ",
  "ΤΙΜΩΝ",
  "ΑΙΓΑΣ",
  "ΣΤΑΡΑ",
  "ΣΤΕΑΡ",
  "ΧΑΜΩΙ",
  "ΚΟΠΗΣ",
  "ΛΙΓΝΑ",
  "ΠΙΕΣΕ",
  "ΧΑΖΕΣ",
  "ΤΑΙΖΩ",
  "ΑΝΘΟΥ",
  "ΘΑΛΛΩ",
  "ΑΔΕΙΟ",
  "ΚΑΡΥΟ",
  "ΒΟΕΡΑ",
  "ΖΟΧΩΝ",
  "ΤΡΙΤΗ",
  "ΨΑΧΝΟ",
  "ΓΟΦΙΑ",
  "ΟΝΤΟΣ",
  "ΛΑΜΙΑ",
  "ΤΑΠΩΝ",
  "ΕΥΗΧΗ",
  "ΓΟΥΝΑ",
  "ΚΑΜΨΩ",
  "ΑΞΟΝΑ",
  "ΚΟΜΩΝ",
  "ΑΧΘΩΝ",
  "ΑΚΡΗΣ",
  "ΔΟΛΟΙ",
  "ΒΥΘΟΝ",
  "ΠΥΞΟΥ",
  "ΣΥΖΕΙ",
  "ΓΚΕΜΙ",
  "ΦΑΚΗΣ",
  "ΜΙΚΤΑ",
  "ΨΥΧΡΗ",
  "ΚΟΥΚΟ",
  "ΦΩΛΟΣ",
  "ΧΑΝΑΝ",
  "ΧΑΛΚΗ",
  "ΑΣΙΤΕ",
  "ΝΟΣΟΥ",
  "ΠΡΕΦΑ",
  "ΚΟΙΝΟ",
  "ΒΩΛΟΣ",
  "ΚΑΜΨΗ",
  "ΘΡΟΝΟ",
  "ΚΟΤΕΣ",
  "ΧΡΙΣΕ",
  "ΦΛΕΓΩ",
  "ΑΛΛΩΣ",
  "ΥΠΑΤΑ",
  "ΞΥΠΝΟ",
  "ΣΤΗΤΗ",
  "ΕΛΑΤΙ",
  "ΟΒΙΔΑ",
  "ΣΑΧΛΑ",
  "ΤΡΕΛΗ",
  "ΗΠΙΟΙ",
  "ΨΕΙΡΗ",
  "ΔΡΕΠΩ",
  "ΦΛΕΜΑ",
  "ΓΡΙΩΝ",
  "ΖΕΝΙΘ",
  "ΜΑΜΟΙ",
  "ΠΗΛΟΥ",
  "ΕΙΔΟΣ",
  "ΙΡΙΔΑ",
  "ΦΛΟΓΑ",
  "ΔΕΧΤΩ",
  "ΧΑΔΙΑ",
  "ΕΛΑΘΑ",
  "ΟΧΘΩΝ",
  "ΝΩΜΟΙ",
  "ΡΑΜΜΑ",
  "ΟΛΜΟΥ",
  "ΝΤΟΥΣ",
  "ΜΕΙΝΩ",
  "ΚΡΑΝΑ",
  "ΜΟΧΘΟ",
  "ΑΣΙΤΟ",
  "ΞΑΝΤΕ",
  "ΑΦΥΗΣ",
  "ΑΚΡΑΣ",
  "ΙΣΙΕΣ",
  "ΑΘΕΩΝ",
  "ΟΣΧΕΟ",
  "ΑΨΗΛΑ",
  "ΔΑΥΤΑ",
  "ΕΣΜΟΥ",
  "ΤΑΣΣΕ",
  "ΠΛΕΚΩ",
  "ΑΕΡΓΟ",
  "ΡΕΣΤΕ",
  "ΖΩΑΚΙ",
  "ΠΑΠΕΣ",
  "ΣΤΙΣΕ",
  "ΑΛΩΣΕ",
  "ΡΗΤΗΣ",
  "ΥΜΝΕΙ",
  "ΒΙΑΙΗ",
  "ΑΔΑΩΣ",
  "ΧΑΙΤΗ",
  "ΝΟΗΜΑ",
  "ΟΧΘΕΣ",
  "ΠΑΛΙΑ",
  "ΔΑΡΜΕ",
  "ΦΥΤΡΑ",
  "ΒΟΛΑΝ",
  "ΙΛΑΡΟ",
  "ΣΑΤΤΕ",
  "ΛΙΤΩΝ",
  "ΦΕΡΝΗ",
  "ΟΡΧΟΣ",
  "ΘΕΡΟΥ",
  "ΣΑΚΕΣ",
  "ΗΞΕΡΕ",
  "ΙΔΕΑΣ",
  "ΧΗΡΩΝ",
  "ΙΜΕΡΟ",
  "ΣΙΑΞΕ",
  "ΠΟΡΠΗ",
  "ΚΑΙΚΙ",
  "ΝΟΣΟΙ",
  "ΝΤΕΜΙ",
  "ΓΑΛΕΟ",
  "ΕΠΑΦΗ",
  "ΠΛΕΝΩ",
  "ΟΡΘΡΟ",
  "ΥΔΑΤΑ",
  "ΦΑΒΑΣ",
  "ΚΟΤΛΕ",
  "ΕΨΗΝΑ",
  "ΠΤΩΣΗ",
  "ΟΔΕΥΩ",
  "ΑΠΤΟΣ",
  "ΔΙΟΔΟ",
  "ΣΚΟΤΗ",
  "ΘΝΗΤΗ",
  "ΒΟΣΚΑ",
  "ΛΗΓΕΙ",
  "ΛΑΥΡΑ",
  "ΤΥΦΛΟ",
  "ΕΝΕΣΕ",
  "ΑΧΝΟΣ",
  "ΜΠΟΥΑ",
  "ΛΟΥΡΙ",
  "ΑΥΠΝΑ",
  "ΖΥΓΗΣ",
  "ΝΟΤΙΕ",
  "ΧΩΡΙΣ",
  "ΑΦΑΓΗ",
  "ΕΣΩΝΑ",
  "ΣΙΚΥΑ",
  "ΚΟΠΩΝ",
  "ΕΝΑΤΕ",
  "ΚΑΝΩΝ",
  "ΑΥΠΝΗ",
  "ΑΓΩΓΟ",
  "ΓΥΡΟΣ",
  "ΠΑΛΙΝ",
  "ΚΛΩΣΩ",
  "ΑΨΙΛΕ",
  "ΟΥΡΙΕ",
  "ΒΟΜΒΕ",
  "ΝΑΝΟΙ",
  "ΤΣΟΛΙ",
  "ΜΑΙΤΡ",
  "ΑΛΚΩΝ",
  "ΠΤΥΣΩ",
  "ΚΕΛΙΑ",
  "ΡΑΙΣΑ",
  "ΖΟΥΜΙ",
  "ΜΙΣΟΝ",
  "ΨΗΝΑΝ",
  "ΩΜΙΚΑ",
  "ΜΗΠΩΣ",
  "ΖΥΓΟΣ",
  "ΦΟΛΕΣ",
  "ΣΚΥΡΟ",
  "ΑΞΕΝΟ",
  "ΦΡΥΞΕ",
  "ΑΞΕΝΑ",
  "ΣΟΥΒΑ",
  "ΤΥΠΟΣ",
  "ΛΑΜΨΩ",
  "ΦΥΣΕΙ",
  "ΝΗΠΙΟ",
  "ΣΧΑΣΗ",
  "ΟΣΙΑΣ",
  "ΡΙΣΚΑ",
  "ΝΥΜΦΗ",
  "ΚΥΤΙΑ",
  "ΖΩΗΡΑ",
  "ΤΑΞΤΕ",
  "ΟΠΟΤΕ",
  "ΚΡΗΝΗ",
  "ΧΤΙΣΩ",
  "ΜΥΤΕΣ",
  "ΑΨΙΟΥ",
  "ΛΕΒΙΕ",
  "ΣΟΡΟΣ",
  "ΠΟΛΤΟ",
  "ΠΡΗΞΩ",
  "ΡΟΜΒΟ",
  "ΑΕΡΙΟ",
  "ΧΟΡΩΝ",
  "ΟΜΑΛΗ",
  "ΠΛΩΡΗ",
  "ΧΥΣΙΑ",
  "ΤΣΑΡΕ",
  "ΚΑΜΕΑ",
  "ΑΓΡΙΕ",
  "ΕΥΧΕΣ",
  "ΧΟΛΗΣ",
  "ΜΑΔΑΣ",
  "ΕΠΤΥΕ",
  "ΓΑΜΠΑ",
  "ΘΟΛΟΥ",
  "ΑΡΟΣΗ",
  "ΣΟΜΦΗ",
  "ΒΙΑΖΑ",
  "ΕΘΙΖΕ",
  "ΔΟΥΝΕ",
  "ΠΗΡΕΣ",
  "ΓΑΜΑΩ",
  "ΧΟΥΜΕ",
  "ΝΟΤΩΝ",
  "ΕΚΤΗΣ",
  "ΘΑΜΒΑ",
  "ΚΑΔΗΣ",
  "ΦΩΤΩΝ",
  "ΡΙΞΕΙ",
  "ΙΣΑΔΑ",
  "ΚΑΥΤΟ",
  "ΦΑΣΑΣ",
  "ΑΒΙΩΝ",
  "ΚΟΝΞΑ",
  "ΕΤΕΡΕ",
  "ΚΡΥΦΕ",
  "ΝΙΩΣΩ",
  "ΤΟΜΗΣ",
  "ΧΑΛΙΑ",
  "ΘΡΑΣΗ",
  "ΓΝΩΡΑ",
  "ΜΑΠΑΣ",
  "ΑΣΥΛΟ",
  "ΣΦΥΡΙ",
  "ΨΙΛΟΙ",
  "ΓΡΙΑΣ",
  "ΕΝΩΝΕ",
  "ΧΑΣΜΑ",
  "ΕΚΟΒΑ",
  "ΦΥΣΑΩ",
  "ΠΗΖΕΙ",
  "ΝΥΞΗΣ",
  "ΦΑΣΚΩ",
  "ΦΟΝΩΝ",
  "ΓΙΔΙΑ",
  "ΑΖΥΓΟ",
  "ΖΑΛΗΣ",
  "ΝΕΤΟΥ",
  "ΒΓΟΥΝ",
  "ΤΕΜΠΟ",
  "ΚΑΜΙΑ",
  "ΧΟΥΜΟ",
  "ΒΟΘΡΕ",
  "ΑΨΥΧΑ",
  "ΠΟΝΑΣ",
  "ΧΟΡΤΕ",
  "ΦΕΣΤΑ",
  "ΛΥΚΟΣ",
  "ΜΟΧΘΕ",
  "ΚΛΑΔΕ",
  "ΤΡΕΞΩ",
  "ΚΟΛΠΟ",
  "ΕΦΟΡΟ",
  "ΟΧΙΑΣ",
  "ΓΑΙΩΝ",
  "ΟΨΙΜΗ",
  "ΝΟΝΩΝ",
  "ΦΑΡΟΣ",
  "ΧΑΡΟΥ",
  "ΠΛΑΝΩ",
  "ΦΡΑΚΑ",
  "ΛΥΠΗΣ",
  "ΚΑΠΩΣ",
  "ΕΔΕΣΕ",
  "ΥΓΙΕΙ",
  "ΓΛΙΝΑ",
  "ΕΤΑΖΑ",
  "ΤΕΡΨΗ",
  "ΣΠΙΝΟ",
  "ΔΕΨΗΣ",
  "ΣΥΡΜΕ",
  "ΣΤΕΚΩ",
  "ΝΩΠΩΝ",
  "ΧΛΟΜΟ",
  "ΚΥΦΩΝ",
  "ΞΕΦΤΩ",
  "ΤΟΥΔΕ",
  "ΘΩΡΙΑ",
  "ΛΑΔΙΑ",
  "ΔΟΥΛΗ",
  "ΒΟΥΗΣ",
  "ΑΛΛΕΣ",
  "ΣΑΠΩΝ",
  "ΠΕΛΩΝ",
  "ΝΟΥΝΟ",
  "ΝΤΥΜΑ",
  "ΓΕΙΡΩ",
  "ΙΕΡΟΝ",
  "ΟΛΙΚΕ",
  "ΣΤΥΦΩ",
  "ΚΟΤΩΝ",
  "ΠΤΗΣΗ",
  "ΡΩΤΑΩ",
  "ΧΑΙΡΩ",
  "ΕΨΕΝΑ",
  "ΔΗΜΟΣ",
  "ΧΟΤΖΑ",
  "ΧΩΡΙΑ",
  "ΚΑΠΡΟ",
  "ΜΙΤΟΣ",
  "ΖΩΣΟΥ",
  "ΣΤΙΧΕ",
  "ΤΕΡΨΕ",
  "ΑΧΡΟΗ",
  "ΟΜΟΡΗ",
  "ΣΑΛΕΣ",
  "ΠΑΥΕΙ",
  "ΠΝΟΕΣ",
  "ΣΑΛΟΙ",
  "ΤΟΜΕΑ",
  "ΣΑΡΚΑ",
  "ΛΥΣΣΑ",
  "ΦΟΡΟΙ",
  "ΑΖΗΛΕ",
  "ΚΑΖΩΝ",
  "ΛΕΠΤΟ",
  "ΣΟΙΛΗ",
  "ΠΙΝΑΣ",
  "ΞΗΡΑΣ",
  "ΨΑΛΕΙ",
  "ΣΥΡΘΩ",
  "ΒΙΛΑΣ",
  "ΛΑΘΡΑ",
  "ΕΠΕΒΗ",
  "ΛΗΞΙΣ",
  "ΕΙΔΕΣ",
  "ΘΕΜΙΣ",
  "ΠΟΛΤΕ",
  "ΤΗΞΗΣ",
  "ΜΠΗΞΕ",
  "ΣΥΚΗΣ",
  "ΝΕΤΗΣ",
  "ΖΩΙΚΟ",
  "ΥΠΕΧΩ",
  "ΗΛΙΟΥ",
  "ΑΣΒΟΥ",
  "ΑΛΥΤΑ",
  "ΜΑΜΕΣ",
  "ΒΛΑΧΟ",
  "ΑΛΑΦΙ",
  "ΒΙΑΖΩ",
  "ΒΑΨΑΝ",
  "ΦΑΓΟΥ",
  "ΧΑΛΕΣ",
  "ΛΥΣΗΣ",
  "ΡΑΨΤΕ",
  "ΜΟΥΛΑ",
  "ΑΦΟΒΕ",
  "ΚΕΦΙΑ",
  "ΧΟΛΕΣ",
  "ΜΑΚΙΑ",
  "ΟΥΛΗΣ",
  "ΣΑΘΡΟ",
  "ΜΕΝΟΝ",
  "ΓΚΡΙΛ",
  "ΧΥΤΟΙ",
  "ΚΑΚΤΕ",
  "ΚΛΕΟΣ",
  "ΟΧΙΩΝ",
  "ΩΜΕΓΑ",
  "ΣΕΡΑΙ",
  "ΨΗΣΟΥ",
  "ΕΡΘΕΙ",
  "ΑΟΙΔΕ",
  "ΠΑΡΑΣ",
  "ΙΣΤΙΑ",
  "ΦΟΡΕΣ",
  "ΟΡΑΤΑ",
  "ΕΠΗΓΑ",
  "ΒΑΛΕΣ",
  "ΑΚΜΗΣ",
  "ΑΦΑΓΕ",
  "ΣΤΙΜΑ",
  "ΣΤΟΕΣ",
  "ΧΥΤΩΝ",
  "ΑΚΟΠΕ",
  "ΠΥΘΙΕ",
  "ΚΑΙΜΕ",
  "ΒΑΦΤΩ",
  "ΒΑΨΟΥ",
  "ΛΟΓΟΣ",
  "ΛΕΛΕΣ",
  "ΚΛΑΙΩ",
  "ΣΚΑΤΟ",
  "ΑΥΛΟΣ",
  "ΚΟΥΤΑ",
  "ΕΞΟΧΕ",
  "ΛΥΚΟΙ",
  "ΡΟΖΩΝ",
  "ΜΟΤΕΛ",
  "ΙΣΩΝΑ",
  "ΤΡΕΜΩ",
  "ΙΔΙΟΥ",
  "ΕΔΡΕΣ",
  "ΔΕΞΟΥ",
  "ΜΑΧΕΣ",
  "ΛΥΓΟΣ",
  "ΕΑΥΤΕ",
  "ΦΤΥΜΑ",
  "ΑΤΟΚΑ",
  "ΧΩΣΤΟ",
  "ΝΕΓΡΟ",
  "ΑΠΤΩΝ",
  "ΒΙΑΙΑ",
  "ΕΤΕΡΑ",
  "ΤΙΜΑΩ",
  "ΤΥΡΟΙ",
  "ΠΑΣΩΝ",
  "ΡΩΤΑΣ",
  "ΛΕΙΨΕ",
  "ΔΡΑΣΕ",
  "ΠΥΡΓΟ",
  "ΕΖΗΣΕ",
  "ΕΧΑΣΑ",
  "ΟΒΟΛΕ",
  "ΕΞΤΡΑ",
  "ΠΡΩΤΕ",
  "ΠΕΤΣΑ",
  "ΡΥΣΙΣ",
  "ΗΡΑΙΟ",
  "ΚΟΥΛΟ",
  "ΞΑΝΘΑ",
  "ΧΗΝΕΣ",
  "ΘΕΣΙΝ",
  "ΓΚΕΣΤ",
  "ΓΕΝΙΑ",
  "ΦΑΝΟΣ",
  "ΑΡΑΞΩ",
  "ΚΑΡΑΣ",
  "ΧΡΥΣΟ",
  "ΔΡΑΝΑ",
  "ΗΛΘΕΣ",
  "ΕΛΚΕΙ",
  "ΛΑΓΙΑ",
  "ΑΡΑΤΕ",
  "ΤΟΣΗΝ",
  "ΔΕΙΞΕ",
  "ΡΙΚΝΗ",
  "ΡΑΓΟΥ",
  "ΕΩΛΗΣ",
  "ΚΙΟΝΑ",
  "ΠΝΕΕΙ",
  "ΕΡΜΗΣ",
  "ΜΟΔΕΣ",
  "ΧΕΖΑΝ",
  "ΙΣΑΜΕ",
  "ΠΥΡΟΣ",
  "ΑΓΑΝΑ",
  "ΘΩΚΟΙ",
  "ΚΟΛΕΣ",
  "ΓΕΥΣΗ",
  "ΓΡΑΦΕ",
  "ΦΤΩΧΗ",
  "ΒΑΝΑΣ",
  "ΚΑΝΕΙ",
  "ΜΙΚΤΕ",
  "ΟΥΛΩΝ",
  "ΒΥΘΙΑ",
  "ΤΑΦΗΣ",
  "ΑΤΜΟΥ",
  "ΤΙΜΑΣ",
  "ΕΞΥΣΕ",
  "ΑΗΘΗΣ",
  "ΣΤΗΤΑ",
  "ΔΕΙΡΕ",
  "ΑΔΟΛΑ",
  "ΣΤΕΝΗ",
  "ΘΕΩΡΟ",
  "ΒΕΡΓΑ",
  "ΠΥΩΔΗ",
  "ΑΛΟΓΟ",
  "ΨΥΞΟΥ",
  "ΡΟΣΤΑ",
  "ΑΒΡΟΣ",
  "ΠΤΟΕΙ",
  "ΔΕΙΞΩ",
  "ΓΑΛΟΣ",
  "ΖΩΝΩΝ",
  "ΥΒΟΥΣ",
  "ΡΑΙΒΟ",
  "ΘΑΨΟΥ",
  "ΣΙΓΑΩ",
  "ΠΩΛΟΣ",
  "ΔΟΝΕΣ",
  "ΠΕΝΘΩ",
  "ΦΡΑΠΕ",
  "ΑΤΙΜΑ",
  "ΟΜΟΡΑ",
  "ΒΓΗΚΑ",
  "ΑΓΑΠΩ",
  "ΛΑΛΩΝ",
  "ΜΥΡΟΥ",
  "ΕΒΕΝΕ",
  "ΒΑΦΗΣ",
  "ΔΟΜΩΝ",
  "ΠΑΤΑΩ",
  "ΦΕΣΙΑ",
  "ΕΛΚΩΝ",
  "ΛΕΥΚΗ",
  "ΘΗΛΕΣ",
  "ΒΟΥΒΑ",
  "ΤΖΑΜΑ",
  "ΧΑΡΩΝ",
  "ΚΝΙΣΑ",
  "ΑΘΟΛΟ",
  "ΦΑΝΟΥ",
  "ΑΛΙΚΑ",
  "ΠΗΧΤΑ",
  "ΑΒΟΛΗ",
  "ΚΕΦΙΡ",
  "ΧΑΖΗΣ",
  "ΚΑΙΡΩ",
  "ΧΡΕΩΝ",
  "ΚΑΡΓΑ",
  "ΦΥΣΑΣ",
  "ΒΙΑΙΕ",
  "ΠΥΘΙΟ",
  "ΑΩΤΟΝ",
  "ΚΥΡΩΝ",
  "ΑΡΘΡΟ",
  "ΚΟΦΑΣ",
  "ΖΩΗΡΟ",
  "ΕΚΡΕΩ",
  "ΚΛΑΨΩ",
  "ΔΑΡΤΑ",
  "ΦΟΡΕΙ",
  "ΓΚΕΤΑ",
  "ΦΑΣΟΝ",
  "ΟΡΘΩΝ",
  "ΑΠΑΤΩ",
  "ΨΑΛΜΟ",
  "ΚΩΜΟΣ",
  "ΠΟΤΟΥ",
  "ΕΘΙΣΕ",
  "ΚΡΑΤΗ",
  "ΨΙΛΕΣ",
  "ΦΑΡΑΣ",
  "ΔΗΓΜΑ",
  "ΑΠΟΡΗ",
  "ΦΙΔΕΣ",
  "ΖΕΛΕΣ",
  "ΚΑΙΛΑ",
  "ΚΩΛΥΕ",
  "ΣΠΑΝΕ",
  "ΔΑΣΩΝ",
  "ΣΑΓΙΑ",
  "ΣΟΛΕΑ",
  "ΓΙΝΕΙ",
  "ΣΟΦΟΙ",
  "ΛΩΛΟΙ",
  "ΚΤΕΝΑ",
  "ΚΡΑΖΕ",
  "ΜΙΖΕΣ",
  "ΒΟΓΚΩ",
  "ΣΠΙΝΕ",
  "ΧΥΤΟΥ",
  "ΣΚΙΣΩ",
  "ΧΕΛΙΑ",
  "ΤΑΞΙΝ",
  "ΣΑΛΙΑ",
  "ΟΧΛΩΝ",
  "ΚΡΙΟΥ",
  "ΙΑΣΗΣ",
  "ΛΑΙΜΕ",
  "ΑΧΥΜΑ",
  "ΦΕΛΑΝ",
  "ΑΚΟΜΗ",
  "ΥΓΙΗΣ",
  "ΜΕΣΩΝ",
  "ΗΓΗΘΩ",
  "ΤΙΚΤΩ",
  "ΔΙΕΒΗ",
  "ΑΣΤΕΣ",
  "ΓΔΥΝΕ",
  "ΤΑΒΛΑ",
  "ΑΦΡΟΙ",
  "ΜΕΤΡΑ",
  "ΝΥΧΙΑ",
  "ΔΕΙΝΟ",
  "ΛΟΥΠΑ",
  "ΚΙΑΛΙ",
  "ΠΛΙΘΑ",
  "ΠΙΣΤΟ",
  "ΜΙΞΗΣ",
  "ΕΔΙΝΑ",
  "ΑΗΘΕΣ",
  "ΧΟΧΛΟ",
  "ΛΟΓΗΣ",
  "ΠΡΙΖΑ",
  "ΠΥΡΗΝ",
  "ΞΙΝΟΙ",
  "ΚΩΦΗΣ",
  "ΣΚΑΛΙ",
  "ΣΤΟΡΙ",
  "ΠΡΗΞΕ",
  "ΧΡΩΣΗ",
  "ΜΥΛΟΣ",
  "ΦΥΡΑΣ",
  "ΡΟΙΔΑ",
  "ΝΟΙΚΙ",
  "ΚΑΠΡΕ",
  "ΕΛΚΥΕ",
  "ΒΛΙΤΟ",
  "ΑΩΡΟΣ",
  "ΨΑΥΣΗ",
  "ΕΒΑΦΕ",
  "ΡΟΓΧΟ",
  "ΠΑΥΤΩ",
  "ΖΩΝΕΣ",
  "ΟΡΔΙΑ",
  "ΑΣΚΟΙ",
  "ΓΑΙΑΣ",
  "ΠΟΛΛΩ",
  "ΦΙΚΟΥ",
  "ΔΕΞΙΑ",
  "ΑΚΡΟΣ",
  "ΑΛΙΚΟ",
  "ΣΟΦΕΡ",
  "ΜΑΘΕΙ",
  "ΦΥΡΟΙ",
  "ΒΟΡΕΣ",
  "ΑΠΛΟΙ",
  "ΚΑΥΚΙ",
  "ΠΟΛΙΝ",
  "ΡΙΓΕΙ",
  "ΜΥΧΟΥ",
  "ΛΕΡΟΣ",
  "ΣΤΑΣΗ",
  "ΛΩΒΑΣ",
  "ΑΜΜΩΝ",
  "ΓΟΝΟΣ",
  "ΔΕΙΛΕ",
  "ΤΑΝΚΣ",
  "ΑΚΥΡΗ",
  "ΓΙΔΑΣ",
  "ΑΣΣΟΙ",
  "ΓΕΥΜΑ",
  "ΑΦΩΤΑ",
  "ΞΥΠΝΩ",
  "ΣΚΑΙΟ",
  "ΒΙΔΩΝ",
  "ΞΑΣΜΑ",
  "ΕΝΕΧΩ",
  "ΚΑΣΚΑ",
  "ΙΑΧΩΝ",
  "ΕΒΑΦΑ",
  "ΔΑΡΤΟ",
  "ΕΣΑΖΑ",
  "ΞΙΝΕΣ",
  "ΑΥΤΟΙ",
  "ΕΛΗΞΕ",
  "ΣΕΠΙΑ",
  "ΜΑΖΑΣ",
  "ΚΟΡΟΙ",
  "ΕΦΗΒΟ",
  "ΚΤΥΠΕ",
  "ΜΠΑΖΟ",
  "ΠΙΘΟΙ",
  "ΤΗΡΕΙ",
  "ΓΡΑΣΟ",
  "ΛΑΟΥΣ",
  "ΦΥΓΑΝ",
  "ΩΡΙΜΗ",
  "ΔΑΡΘΩ",
  "ΜΑΒΙΑ",
  "ΨΕΥΔΗ",
  "ΣΚΕΨΗ",
  "ΑΙΣΙΕ",
  "ΕΠΩΔΗ",
  "ΜΠΟΥΜ",
  "ΥΙΙΚΑ",
  "ΤΟΠΟΝ",
  "ΚΡΟΤΩ",
  "ΦΩΤΑΩ",
  "ΜΠΑΣΩ",
  "ΓΡΙΦΟ",
  "ΣΟΥΠΑ",
  "ΑΝΗΒΑ",
  "ΤΡΩΤΟ",
  "ΠΥΡΕΣ",
  "ΔΟΛΟΣ",
  "ΠΟΙΩΝ",
  "ΔΕΞΙΕ",
  "ΔΟΛΙΑ",
  "ΦΥΛΑΞ",
  "ΛΑΡΔΙ",
  "ΠΕΙΣΩ",
  "ΑΡΕΣΑ",
  "ΚΟΡΤΕ",
  "ΘΗΡΑΣ",
  "ΣΤΕΠΑ",
  "ΚΡΥΟΙ",
  "ΛΕΥΚΕ",
  "ΗΝΙΩΝ",
  "ΚΩΔΩΝ",
  "ΟΨΕΩΣ",
  "ΚΝΗΜΗ",
  "ΠΕΠΛΕ",
  "ΑΕΤΟΙ",
  "ΛΥΤΗΣ",
  "ΕΜΕΙΣ",
  "ΜΗΝΑΝ",
  "ΩΘΟΥΝ",
  "ΜΠΗΚΑ",
  "ΠΛΑΘΕ",
  "ΕΠΙΚΟ",
  "ΠΟΔΕΣ",
  "ΚΑΠΝΟ",
  "ΣΤΑΡΙ",
  "ΚΤΗΜΑ",
  "ΧΙΑΣΩ",
  "ΕΝΕΜΑ",
  "ΔΩΡΕΑ",
  "ΝΙΚΑΝ",
  "ΕΞΕΙΣ",
  "ΟΝΤΩΝ",
  "ΑΠΑΤΕ",
  "ΚΥΚΝΕ",
  "ΑΤΟΚΗ",
  "ΚΕΡΜΑ",
  "ΝΟΘΑΣ",
  "ΡΙΝΓΚ",
  "ΣΟΡΤΣ",
  "ΟΠΤΟΙ",
  "ΕΥΡΟΣ",
  "ΣΑΧΛΟ",
  "ΓΕΛΩΝ",
  "ΑΔΡΟΣ",
  "ΕΓΧΕΩ",
  "ΘΥΡΕΣ",
  "ΥΠΤΙΟ",
  "ΜΕΡΟΣ",
  "ΚΟΙΤΑ",
  "ΡΙΖΑΣ",
  "ΘΡΥΛΩ",
  "ΓΑΜΩΝ",
  "ΝΟΗΣΩ",
  "ΕΧΤΕΣ",
  "ΥΙΙΚΗ",
  "ΡΟΥΜΙ",
  "ΛΥΓΑΝ",
  "ΝΟΗΤΗ",
  "ΕΨΕΞΕ",
  "ΦΟΔΡΑ",
  "ΔΟΡΕΣ",
  "ΧΙΟΝΙ",
  "ΧΛΟΕΣ",
  "ΑΜΟΝΕ",
  "ΑΡΧΟΣ",
  "ΜΠΗΖΩ",
  "ΑΡΤΙΕ",
  "ΔΡΟΣΑ",
  "ΚΑΚΟΥ",
  "ΡΗΤΟΙ",
  "ΑΤΟΠΟ",
  "ΠΟΤΟΣ",
  "ΜΑΜΗΣ",
  "ΑΦΙΞΗ",
  "ΔΟΓΗΣ",
  "ΔΑΔΑΣ",
  "ΑΒΑΘΟ",
  "ΣΤΥΛΟ",
  "ΣΤΙΖΕ",
  "ΝΕΚΡΗ",
  "ΧΑΝΙΑ",
  "ΦΑΤΝΗ",
  "ΤΑΦΟΙ",
  "ΨΟΦΟΥ",
  "ΙΔΙΩΝ",
  "ΗΛΙΟΙ",
  "ΚΟΜΜΑ",
  "ΠΡΑΝΗ",
  "ΒΡΟΜΗ",
  "ΙΩΣΗΣ",
  "ΤΑΓΗΣ",
  "ΝΟΤΕΣ",
  "ΚΕΡΔΗ",
  "ΧΥΣΙΣ",
  "ΡΑΠΤΗ",
  "ΕΞΩΛΗ",
  "ΑΙΓΩΝ",
  "ΑΧΝΑΣ",
  "ΔΕΙΛΗ",
  "ΘΕΙΕΣ",
  "ΠΛΑΚΕ",
  "ΓΕΡΕΣ",
  "ΦΩΛΕΕ",
  "ΚΡΙΣΗ",
  "ΘΟΛΟΙ",
  "ΜΑΓΟΙ",
  "ΟΠΤΕΣ",
  "ΛΙΝΟΣ",
  "ΚΟΠΡΟ",
  "ΨΗΦΑΝ",
  "ΥΠΑΤΟ",
  "ΠΤΩΧΕ",
  "ΤΡΕΛΟ",
  "ΑΔΕΝΑ",
  "ΗΓΑΓΕ",
  "ΠΟΚΕΣ",
  "ΚΛΗΤΑ",
  "ΓΛΥΚΑ",
  "ΜΙΛΤΟ",
  "ΠΑΝΕΛ",
  "ΔΕΤΩΝ",
  "ΕΡΜΕΣ",
  "ΑΤΥΧΑ",
  "ΘΕΙΚΟ",
  "ΤΙΜΗΝ",
  "ΛΑΜΔΑ",
  "ΤΡΕΦΕ",
  "ΚΕΙΝΟ",
  "ΓΟΜΟΥ",
  "ΡΟΚΑΣ",
  "ΣΚΑΝΕ",
  "ΑΡΑΣΕ",
  "ΟΝΙΚΑ",
  "ΔΡΑΚΕ",
  "ΙΔΡΥΩ",
  "ΑΒΟΛΟ",
  "ΤΡΙΒΗ",
  "ΒΑΘΡΑ",
  "ΚΟΝΙΣ",
  "ΕΠΤΥΑ",
  "ΑΛΕΣΩ",
  "ΕΠΑΝΩ",
  "ΟΡΓΙΟ",
  "ΛΟΞΟΥ",
  "ΣΚΥΨΕ",
  "ΩΧΡΩΝ",
  "ΧΑΡΙΝ",
  "ΑΓΑΜΗ",
  "ΣΜΙΛΗ",
  "ΑΤΑΦΑ",
  "ΘΟΛΟΣ",
  "ΦΥΣΑΝ",
  "ΥΠΝΟΙ",
  "ΑΣΕΒΗ",
  "ΟΧΘΗΣ",
  "ΖΟΧΟΙ",
  "ΙΑΤΡΟ",
  "ΕΚΑΨΑ",
  "ΝΩΜΩΝ",
  "ΦΛΑΡΕ",
  "ΑΒΑΡΗ",
  "ΜΗΛΟΝ",
  "ΤΥΧΕΣ",
  "ΛΑΜΨΕ",
  "ΡΟΙΔΙ",
  "ΣΤΗΤΕ",
  "ΦΑΡΟΥ",
  "ΑΧΟΛΕ",
  "ΝΤΑΡΑ",
  "ΑΓΛΑΗ",
  "ΜΙΤΟΙ",
  "ΕΝΩΜΑ",
  "ΦΙΛΙΕ",
  "ΙΣΧΥΕ",
  "ΚΑΨΕΙ",
  "ΕΡΜΟΥ",
  "ΑΜΜΟΣ",
  "ΣΑΜΠΟ",
  "ΠΕΤΣΙ",
  "ΑΡΙΟΝ",
  "ΤΥΛΟΙ",
  "ΟΜΟΡΕ",
  "ΠΑΤΗΡ",
  "ΛΗΞΕΙ",
  "ΓΙΑΛΕ",
  "ΜΟΝΙΑ",
  "ΛΙΑΣΕ",
  "ΡΑΓΕΣ",
  "ΘΥΤΗΣ",
  "ΡΑΦΕΣ",
  "ΡΟΤΑΣ",
  "ΚΗΡΟΣ",
  "ΕΚΑΜΕ",
  "ΑΙΤΕΙ",
  "ΕΡΕΨΕ",
  "ΤΡΙΨΕ",
  "ΧΙΑΣΕ",
  "ΤΑΓΚΟ",
  "ΜΙΑΡΑ",
  "ΟΡΥΧΗ",
  "ΒΑΝΕΙ",
  "ΛΟΙΠΑ",
  "ΧΛΟΗΣ",
  "ΔΙΩΚΕ",
  "ΠΗΔΩΝ",
  "ΚΟΙΤΗ",
  "ΧΗΡΟΥ",
  "ΛΙΓΚΑ",
  "ΨΕΞΕΙ",
  "ΦΡΕΝΟ",
  "ΧΛΩΡΕ",
  "ΑΥΛΟΥ",
  "ΟΣΤΟΥ",
  "ΟΞΕΙΣ",
  "ΧΕΡΤΣ",
  "ΚΥΛΑΝ",
  "ΜΗΝΥΩ",
  "ΠΑΓΙΕ",
  "ΕΞΑΛΑ",
  "ΛΕΞΙΣ",
  "ΤΑΓΚΑ",
  "ΒΥΡΣΑ",
  "ΜΑΤΙΑ",
  "ΔΡΟΥΝ",
  "ΚΟΓΧΗ",
  "ΒΟΛΕΙ",
  "ΒΟΡΩΝ",
  "ΛΥΠΗΝ",
  "ΛΥΓΜΕ",
  "ΠΕΣΣΟ",
  "ΚΥΚΛΕ",
  "ΑΚΕΦΟ",
  "ΝΑΖΙΑ",
  "ΩΦΕΛΩ",
  "ΓΥΡΩΝ",
  "ΩΔΕΙΟ",
  "ΠΛΑΝΟ",
  "ΞΕΡΗΣ",
  "ΟΝΤΩΣ",
  "ΛΗΝΟΣ",
  "ΤΑΓΚΕ",
  "ΨΗΦΙΑ",
  "ΓΡΑΟΣ",
  "ΖΟΧΟΣ",
  "ΘΡΕΨΕ",
  "ΑΜΠΕΡ",
  "ΧΟΙΚΑ",
  "ΛΙΤΡΑ",
  "ΑΔΟΛΕ",
  "ΚΥΛΛΟ",
  "ΛΑΦΙΑ",
  "ΟΡΜΑΩ",
  "ΑΠΛΗΣ",
  "ΠΛΟΥΣ",
  "ΣΠΑΝΟ",
  "ΕΚΡΟΗ",
  "ΣΤΟΚΟ",
  "ΠΑΨΕΙ",
  "ΨΥΧΟΣ",
  "ΔΟΣΙΣ",
  "ΕΖΕΥΑ",
  "ΒΥΖΙΑ",
  "ΤΑΒΛΙ",
  "ΘΕΡΜΟ",
  "ΘΩΚΟΣ",
  "ΛΗΝΟΙ",
  "ΠΗΔΑΣ",
  "ΣΠΟΡΑ",
  "ΣΧΙΖΑ",
  "ΑΞΙΖΑ",
  "ΛΟΥΛΑ",
  "ΝΩΜΟΣ",
  "ΠΟΣΩΣ",
  "ΠΗΞΕΙ",
  "ΠΑΣΑΣ",
  "ΤΑΧΕΙ",
  "ΟΥΛΟΝ",
  "ΛΕΓΕΣ",
  "ΗΧΕΙΣ",
  "ΣΦΑΖΩ",
  "ΔΑΦΝΗ",
  "ΟΣΜΙΟ",
  "ΠΕΔΙΑ",
  "ΣΤΙΞΩ",
  "ΒΑΦΕΣ",
  "ΒΑΦΕΙ",
  "ΑΤΟΝΩ",
  "ΔΙΦΡΕ",
  "ΦΕΡΤΕ",
  "ΜΗΡΟΥ",
  "ΡΗΧΩΝ",
  "ΒΑΨΕΙ",
  "ΑΡΕΤΕ",
  "ΖΑΝΤΑ",
  "ΛΙΝΟΙ",
  "ΙΘΥΝΩ",
  "ΔΙΠΛΑ",
  "ΜΑΒΗΣ",
  "ΤΡΟΠΗ",
  "ΠΑΡΛΑ",
  "ΔΩΣΟΥ",
  "ΤΟΠΩΝ",
  "ΝΩΔΕΣ",
  "ΦΟΝΙΑ",
  "ΛΕΓΑΝ",
  "ΔΙΚΑΙ",
  "ΑΔΟΤΟ",
  "ΥΓΕΙΑ",
  "ΕΤΥΜΕ",
  "ΟΝΙΚΟ",
  "ΧΕΙΣΕ",
  "ΔΙΠΛΕ",
  "ΧΩΡΟΥ",
  "ΕΧΑΒΕ",
  "ΜΟΥΛΕ",
  "ΡΕΟΥΝ",
  "ΒΟΛΙΑ",
  "ΑΣΤΟΙ",
  "ΔΙΟΤΙ",
  "ΣΙΝΙΑ",
  "ΟΓΡΟΣ",
  "ΧΛΕΥΗ",
  "ΕΛΑΣΗ",
  "ΜΙΞΕΡ",
  "ΖΩΣΕΣ",
  "ΔΕΣΜΕ",
  "ΦΕΛΛΟ",
  "ΒΟΛΟΥ",
  "ΚΥΒΩΝ",
  "ΕΛΙΚΑ",
  "ΤΟΞΩΝ",
  "ΜΑΣΙΦ",
  "ΑΞΙΟΥ",
  "ΕΥΗΧΑ",
  "ΦΟΡΟΥ",
  "ΓΟΦΟΣ",
  "ΦΕΡΟΝ",
  "ΑΜΗΤΕ",
  "ΘΡΗΝΟ",
  "ΙΜΑΤΖ",
  "ΚΡΙΟΙ",
  "ΜΗΚΩΝ",
  "ΑΤΟΜΟ",
  "ΔΟΥΛΟ",
  "ΥΨΩΜΑ",
  "ΤΡΙΧΑ",
  "ΚΑΗΚΑ",
  "ΓΛΥΦΗ",
  "ΕΚΑΝΕ",
  "ΖΗΤΩΝ",
  "ΙΤΑΜΟ",
  "ΑΣΕΒΕ",
  "ΜΕΣΕΣ",
  "ΤΕΘΕΙ",
  "ΤΟΞΟΥ",
  "ΟΔΗΓΟ",
  "ΕΥΚΩΝ",
  "ΠΑΡΤΙ",
  "ΑΝΑΨΑ",
  "ΠΑΙΣΕ",
  "ΓΟΝΙΟ",
  "ΜΙΖΑΣ",
  "ΔΙΝΑΝ",
  "ΠΑΓΚΟ",
  "ΡΩΜΕΣ",
  "ΜΗΡΟΙ",
  "ΘΛΙΒΩ",
  "ΠΑΛΗΝ",
  "ΧΟΡΤΟ",
  "ΣΟΡΩΝ",
  "ΣΕΠΤΑ",
  "ΣΟΛΑΣ",
  "ΠΑΓΙΟ",
  "ΜΗΡΙΑ",
  "ΤΡΙΣΩ",
  "ΛΕΥΓΑ",
  "ΖΕΣΤΑ",
  "ΔΙΣΚΕ",
  "ΤΖΙΒΑ",
  "ΛΩΡΟΣ",
  "ΨΑΛΛΩ",
  "ΠΛΗΘΗ",
  "ΓΙΑΚΑ",
  "ΕΡΑΒΕ",
  "ΕΙΜΑΙ",
  "ΛΟΓΟΥ",
  "ΠΟΔΩΝ",
  "ΚΕΝΗΣ",
  "ΑΛΗΘΗ",
  "ΘΑΦΤΟ",
  "ΤΑΚΩΝ",
  "ΧΕΖΑΣ",
  "ΥΜΕΙΣ",
  "ΤΡΟΜΟ",
  "ΤΡΕΣΑ",
  "ΧΟΛΜΣ",
  "ΠΑΡΘΩ",
  "ΓΥΡΟΙ",
  "ΠΟΘΕΙ",
  "ΜΑΝΗΣ",
  "ΥΝΙΟΥ",
  "ΠΙΚΛΑ",
  "ΣΕΙΡΑ",
  "ΕΜΕΝΑ",
  "ΤΟΣΩΝ",
  "ΤΟΜΙΑ",
  "ΣΦΑΛΕ",
  "ΕΛΘΕΙ",
  "ΠΟΤΕΣ",
  "ΣΕΡΝΩ",
  "ΜΠΟΥΝ",
  "ΛΑΛΟΙ",
  "ΚΟΥΤΟ",
  "ΧΩΣΤΗ",
  "ΣΤΟΛΗ",
  "ΑΘΥΜΑ",
  "ΑΣΣΟΥ",
  "ΑΦΑΤΑ",
  "ΜΟΝΟΣ",
  "ΕΠΕΧΩ",
  "ΡΙΖΕΣ",
  "ΩΩΔΕΣ",
  "ΟΞΙΚΑ",
  "ΧΩΣΙΣ",
  "ΒΑΜΜΑ",
  "ΨΩΜΙΑ",
  "ΗΘΜΟΙ",
  "ΠΛΩΤΟ",
  "ΙΕΡΑΞ",
  "ΜΥΙΚΗ",
  "ΕΤΥΜΗ",
  "ΕΧΑΝΑ",
  "ΣΑΘΡΗ",
  "ΣΕΡΑΣ",
  "ΑΠΟΡΕ",
  "ΑΚΡΩΝ",
  "ΣΥΛΦΗ",
  "ΔΕΙΝΗ",
  "ΡΟΦΑΣ",
  "ΖΑΛΟΥ",
  "ΓΥΨΩΝ",
  "ΕΣΑΝΣ",
  "ΕΥΝΟΩ",
  "ΛΕΓΩΝ",
  "ΣΩΡΟΙ",
  "ΖΟΥΠΩ",
  "ΩΡΑΙΑ",
  "ΦΤΗΝΑ",
  "ΛΙΓΟΝ",
  "ΝΟΡΜΑ",
  "ΣΙΓΑΣ",
  "ΖΕΥΤΩ",
  "ΑΝΟΜΕ",
  "ΜΕΝΕΙ",
  "ΓΥΜΝΟ",
  "ΠΡΗΖΩ",
  "ΚΟΠΟΥ",
  "ΚΑΗΜΟ",
  "ΓΟΥΤΟ",
  "ΑΦΙΛΕ",
  "ΓΟΥΔΙ",
  "ΑΣΤΩΝ",
  "ΧΛΩΜΗ",
  "ΣΥΧΝΕ",
  "ΑΔΗΛΟ",
  "ΤΜΗΣΗ",
  "ΙΓΝΥΣ",
  "ΑΝΙΩΝ",
  "ΤΟΠΟΥ",
  "ΛΑΚΕΣ",
  "ΟΞΟΥΣ",
  "ΚΛΟΙΟ",
  "ΠΟΡΩΝ",
  "ΕΣΕΙΕ",
  "ΛΥΓΜΟ",
  "ΧΟΡΔΗ",
  "ΠΤΙΛΑ",
  "ΣΠΙΤΙ",
  "ΑΥΛΗΣ",
  "ΑΡΧΗΝ",
  "ΟΡΤΣΑ",
  "ΒΑΦΕΑ",
  "ΧΑΛΑΝ",
  "ΨΑΧΝΕ",
  "ΩΑΡΙΟ",
  "ΙΣΤΟΣ",
  "ΣΑΡΜΑ",
  "ΘΡΟΝΙ",
  "ΠΟΛΩΝ",
  "ΜΠΙΡΑ",
  "ΓΙΑΛΟ",
  "ΣΚΑΦΗ",
  "ΛΑΝΤΟ",
  "ΑΛΛΗΣ",
  "ΧΩΡΙΟ",
  "ΛΟΞΕΣ",
  "ΣΤΟΧΕ",
  "ΠΛΥΘΩ",
  "ΚΩΛΥΩ",
  "ΑΚΟΗΣ",
  "ΚΟΙΛΑ",
  "ΑΠΑΤΗ",
  "ΠΥΡΓΕ",
  "ΞΕΡΩΝ",
  "ΚΟΥΛΗ",
  "ΠΡΑΕΣ",
  "ΧΩΘΕΙ",
  "ΞΕΡΕΣ",
  "ΟΨΙΜΕ",
  "ΙΣΑΖΩ",
  "ΓΟΒΑΣ",
  "ΠΟΥΛΑ",
  "ΚΑΡΟΥ",
  "ΙΣΘΜΟ",
  "ΧΑΡΤΙ",
  "ΣΑΦΩΝ",
  "ΑΦΑΛΕ",
  "ΔΕΙΝΑ",
  "ΩΔΕΙΑ",
  "ΝΥΓΜΟ",
  "ΦΘΑΝΩ",
  "ΧΙΛΩΝ",
  "ΧΥΣΟΥ",
  "ΕΠΑΓΩ",
  "ΖΙΛΙΑ",
  "ΧΑΨΕΙ",
  "ΡΟΦΟΥ",
  "ΒΕΡΟΙ",
  "ΤΡΩΝΕ",
  "ΕΨΑΥΕ",
  "ΑΜΜΟΙ",
  "ΟΚΝΩΝ",
  "ΑΨΗΤΟ",
  "ΦΘΙΣΗ",
  "ΠΛΕΞΗ",
  "ΒΕΝΘΗ",
  "ΤΑΧΘΩ",
  "ΧΗΡΑΣ",
  "ΤΥΦΛΗ",
  "ΟΡΑΤΕ",
  "ΕΑΡΟΣ",
  "ΑΟΣΜΕ",
  "ΒΙΒΛΟ",
  "ΚΕΝΤΩ",
  "ΚΩΔΙΞ",
  "ΑΚΥΡΑ",
  "ΦΟΝΟΣ",
  "ΚΑΚΟΝ",
  "ΑΒΑΚΑ",
  "ΤΡΟΦΗ",
  "ΤΥΛΩΝ",
  "ΘΥΡΕΟ",
  "ΜΙΝΙΟ",
  "ΑΘΩΑΣ",
  "ΕΔΩΘΕ",
  "ΚΕΙΝΕ",
  "ΟΓΡΟΙ",
  "ΜΟΧΛΟ",
  "ΚΑΛΟΝ",
  "ΕΝΕΩΝ",
  "ΠΥΚΝΑ",
  "ΑΥΛΩΝ",
  "ΑΚΟΥΩ",
  "ΡΑΙΖΩ",
  "ΙΩΔΙΑ",
  "ΟΡΙΣΑ",
  "ΣΑΦΗΣ",
  "ΗΧΗΣΑ",
  "ΚΛΑΨΑ",
  "ΛΕΤΣΟ",
  "ΠΑΛΕΣ",
  "ΚΙΟΛΑ",
  "ΔΡΑΜΙ",
  "ΒΡΟΧΕ",
  "ΛΥΣΙΣ",
  "ΚΡΙΘΗ",
  "ΕΧΑΝΕ",
  "ΞΥΣΜΑ",
  "ΟΧΥΡΑ",
  "ΓΟΦΟΙ",
  "ΓΥΨΟΙ",
  "ΞΕΣΜΑ",
  "ΛΥΜΦΗ",
  "ΤΟΣΕΣ",
  "ΘΕΛΑΝ",
  "ΣΤΕΝΕ",
  "ΡΑΒΕΙ",
  "ΑΝΕΜΕ",
  "ΒΟΕΙΟ",
  "ΑΗΔΩΝ",
  "ΑΨΟΓΕ",
  "ΨΗΝΕΙ",
  "ΣΠΑΘΙ",
  "ΚΛΑΙΝ",
  "ΤΑΡΕΣ",
  "ΠΤΥΟΝ",
  "ΕΝΑΤΟ",
  "ΠΙΘΩΝ",
  "ΠΑΡΤΗ",
  "ΘΩΡΕΙ",
  "ΥΦΑΝΩ",
  "ΝΩΘΡΑ",
  "ΤΣΙΚΟ",
  "ΡΩΓΕΣ",
  "ΛΕΙΑΣ",
  "ΧΟΡΙΟ",
  "ΜΙΚΡΕ",
  "ΧΑΡΕΙ",
  "ΕΔΑΦΗ",
  "ΕΣΤΙΑ",
  "ΣΚΑΒΩ",
  "ΥΨΗΛΑ",
  "ΚΑΥΓΑ",
  "ΠΗΓΑΝ",
  "ΟΣΙΟΣ",
  "ΧΡΕΙΑ",
  "ΧΑΥΝΕ",
  "ΠΛΑΣΗ",
  "ΣΙΑΞΩ",
  "ΜΑΡΟΝ",
  "ΚΑΤΩΙ",
  "ΒΥΘΙΟ",
  "ΚΟΛΠΑ",
  "ΖΟΥΛΩ",
  "ΖΑΡΕΣ",
  "ΝΑΝΩΝ",
  "ΤΟΛΜΑ",
  "ΠΟΥΛΩ",
  "ΑΓΙΟΙ",
  "ΠΩΛΟΥ",
  "ΛΟΦΩΝ",
  "ΜΙΑΡΗ",
  "ΓΥΨΟΣ",
  "ΨΕΛΝΕ",
  "ΧΥΜΟΥ",
  "ΚΟΡΟΝ",
  "ΓΕΡΤΑ",
  "ΧΛΩΡΟ",
  "ΖΟΦΟΣ",
  "ΕΜΠΥΟ",
  "ΖΕΥΓΑ",
  "ΝΟΣΩΝ",
  "ΤΣΙΡΟ",
  "ΛΥΚΩΝ",
  "ΔΩΡΟΝ",
  "ΡΗΘΕΝ",
  "ΣΥΡΤΗ",
  "ΕΛΗΞΑ",
  "ΠΕΙΡΑ",
  "ΣΟΒΑΣ",
  "ΠΟΛΙΣ",
  "ΕΝΘΕΕ",
  "ΦΑΣΗΣ",
  "ΕΝΙΨΑ",
  "ΛΟΓΚΟ",
  "ΣΚΙΕΡ",
  "ΝΥΚΤΙ",
  "ΚΟΨΕΙ",
  "ΠΙΠΑΣ",
  "ΑΣΗΜΙ",
  "ΙΣΟΥΣ",
  "ΔΑΚΡΥ",
  "ΑΡΑΙΗ",
  "ΡΟΖΟΥ",
  "ΑΡΜΟΣ",
  "ΦΑΝΤΕ",
  "ΝΕΦΩΝ",
  "ΓΑΙΕΣ",
  "ΔΙΑΝΟ",
  "ΣΗΤΑΣ",
  "ΝΩΔΟΥ",
  "ΚΟΑΖΩ",
  "ΝΟΜΑΣ",
  "ΑΛΤΕΣ",
  "ΛΑΣΚΟ",
  "ΨΑΡΗΣ",
  "ΕΠΙΝΕ",
  "ΑΡΑΖΩ",
  "ΣΕΙΕΙ",
  "ΦΤΗΝΕ",
  "ΒΑΖΟΥ",
  "ΛΩΤΟΣ",
  "ΚΩΝΟΙ",
  "ΞΥΝΕΙ",
  "ΟΞΙΚΗ",
  "ΚΙΛΟΥ",
  "ΞΕΙΠΕ",
  "ΤΥΧΩΝ",
  "ΣΤΥΒΩ",
  "ΟΡΝΙΣ",
  "ΛΕΡΕΣ",
  "ΛΟΞΟΙ",
  "ΠΕΛΜΑ",
  "ΘΑΜΠΗ",
  "ΨΑΧΝΗ",
  "ΛΟΒΟΣ",
  "ΡΟΔΟΥ",
  "ΦΕΤΙΧ",
  "ΘΑΛΠΗ",
  "ΠΙΛΩΝ",
  "ΣΒΗΣΕ",
  "ΓΟΡΓΑ",
  "ΚΟΛΛΑ",
  "ΡΙΧΤΩ",
  "ΑΓΟΝΕ",
  "ΠΛΩΤΑ",
  "ΟΣΦΥΝ",
  "ΑΘΩΟΙ",
  "ΤΑΚΤΑ",
  "ΗΒΙΚΑ",
  "ΦΩΛΕΟ",
  "ΟΡΟΥΣ",
  "ΧΟΡΑΣ",
  "ΤΑΓΗΝ",
  "ΟΧΛΟΥ",
  "ΕΠΗΡΕ",
  "ΚΛΑΣΩ",
  "ΤΡΗΜΑ",
  "ΑΓΙΟΣ",
  "ΚΡΟΚΕ",
  "ΣΤΕΚΙ",
  "ΛΑΛΑΝ",
  "ΤΙΤΛΕ",
  "ΕΛΞΙΣ",
  "ΕΦΕΡΕ",
  "ΣΤΑΣΙ",
  "ΧΙΑΖΕ",
  "ΜΥΩΝΑ",
  "ΞΗΡΩΝ",
  "ΓΥΑΛΑ",
  "ΠΟΥΣΙ",
  "ΧΥΔΗΝ",
  "ΑΛΟΓΕ",
  "ΓΕΙΡΕ",
  "ΓΟΠΕΣ",
  "ΟΞΙΝΑ",
  "ΓΕΥΘΩ",
  "ΑΠΑΧΗ",
  "ΤΣΑΜΙ",
  "ΑΓΩΓΗ",
  "ΓΑΝΤΙ",
  "ΜΗΝΗΣ",
  "ΗΣΥΧΕ",
  "ΚΑΒΩΝ",
  "ΗΤΤΑΣ",
  "ΧΩΡΟΣ",
  "ΤΖΑΜΙ",
  "ΜΑΝΕΣ",
  "ΔΕΘΕΙ",
  "ΙΔΙΚΑ",
  "ΑΠΑΛΕ",
  "ΤΣΙΠΣ",
  "ΦΤΕΝΑ",
  "ΤΑΧΕΑ",
  "ΔΙΨΑΝ",
  "ΣΙΖΕΙ",
  "ΦΟΒΟΝ",
  "ΠΩΛΟΙ",
  "ΑΘΛΙΑ",
  "ΠΑΓΡΑ",
  "ΦΥΛΛΑ",
  "ΜΙΓΑΣ",
  "ΓΥΜΝΑ",
  "ΟΡΧΟΙ",
  "ΦΑΙΟΙ",
  "ΡΑΓΙΑ",
  "ΚΟΛΠΕ",
  "ΑΜΑΚΑ",
  "ΜΙΤΡΑ",
  "ΣΚΑΣΩ",
  "ΚΑΡΦΗ",
  "ΕΖΩΣΕ",
  "ΛΩΡΙΑ",
  "ΠΑΘΩΝ",
  "ΟΥΔΟΝ",
  "ΔΕΞΗΣ",
  "ΣΠΛΗΝ",
  "ΠΕΣΑΝ",
  "ΓΟΟΥΣ",
  "ΑΓΙΕΣ",
  "ΧΩΣΙΑ",
  "ΤΡΙΞΩ",
  "ΔΡΑΣΩ",
  "ΣΗΤΕΣ",
  "ΣΤΥΣΗ",
  "ΘΗΛΗΣ",
  "ΔΗΛΟΥ",
  "ΕΙΡΜΕ",
  "ΜΗΝΑΩ",
  "ΕΞΑΡΩ",
  "ΤΡΕΙΣ",
  "ΑΤΟΚΕ",
  "ΤΥΦΟΥ",
  "ΑΚΤΩΝ",
  "ΚΤΙΣΕ",
  "ΘΟΡΙΑ",
  "ΚΡΕΠΑ",
  "ΨΗΣΤΕ",
  "ΙΣΧΥΩ",
  "ΜΕΝΟΥ",
  "ΑΡΧΕΙ",
  "ΟΥΔΟΙ",
  "ΤΥΛΟΣ",
  "ΔΟΜΟΥ",
  "ΕΘΑΨΕ",
  "ΔΙΗΘΩ",
  "ΙΔΙΑΣ",
  "ΚΟΛΙΟ",
  "ΜΑΝΟΙ",
  "ΨΕΚΤΗ",
  "ΗΡΑΙΑ",
  "ΝΕΟΥΣ",
  "ΥΓΙΩΣ",
  "ΚΟΥΤΙ",
  "ΕΔΡΑΣ",
  "ΜΥΧΩΝ",
  "ΟΡΝΙΑ",
  "ΠΗΛΩΝ",
  "ΥΦΑΝΕ",
  "ΧΑΦΤΕ",
  "ΚΟΡΩΝ",
  "ΚΛΕΙΩ",
  "ΦΙΝΟΥ",
  "ΕΧΩΝΑ",
  "ΟΛΜΟΣ",
  "ΑΔΑΩΝ",
  "ΑΣΠΡΗ",
  "ΧΗΡΟΣ",
  "ΤΡΕΛΕ",
  "ΚΑΙΡΕ",
  "ΧΟΙΚΕ",
  "ΑΓΑΜΟ",
  "ΑΙΝΟΣ",
  "ΜΙΑΡΕ",
  "ΦΩΤΑΣ",
  "ΛΙΩΣΕ",
  "ΙΣΛΑΜ",
  "ΦΡΑΖΕ",
  "ΑΠΑΝΩ",
  "ΣΚΑΣΕ",
  "ΑΝΟΥΣ",
  "ΨΗΓΜΑ",
  "ΛΟΥΣΑ",
  "ΧΙΑΖΩ",
  "ΤΥΠΑΣ",
  "ΔΕΤΟΣ",
  "ΞΗΡΟΣ",
  "ΙΔΕΑΝ",
  "ΨΕΚΤΕ",
  "ΑΦΩΝΗ",
  "ΟΡΑΜΑ",
  "ΕΔΙΚΑ",
  "ΤΑΣΣΩ",
  "ΥΒΩΣΗ",
  "ΑΦΗΝΑ",
  "ΑΜΩΜΕ",
  "ΤΑΖΑΝ",
  "ΕΦΗΒΕ",
  "ΝΕΦΡΟ",
  "ΠΗΞΗΣ",
  "ΑΟΠΛΕ",
  "ΝΕΥΡΟ",
  "ΡΗΤΕΣ",
  "ΑΤΟΠΑ",
  "ΞΑΝΤΟ",
  "ΦΩΝΕΣ",
  "ΤΟΜΟΙ",
  "ΠΟΚΕΡ",
  "ΒΩΒΗΣ",
  "ΣΚΑΡΟ",
  "ΔΩΡΟΥ",
  "ΟΛΒΙΕ",
  "ΤΟΥΠΕ",
  "ΑΔΡΗΣ",
  "ΒΟΥΝΑ",
  "ΙΣΙΑΣ",
  "ΨΕΓΕΙ",
  "ΣΑΠΡΑ",
  "ΥΝΙΩΝ",
  "ΡΑΓΗΣ",
  "ΑΞΙΖΩ",
  "ΒΡΥΩΝ",
  "ΡΑΚΩΝ",
  "ΞΙΝΟΥ",
  "ΦΤΩΧΟ",
  "ΑΝΗΘΕ",
  "ΧΥΛΟΙ",
  "ΚΟΨΗΣ",
  "ΞΥΡΩΝ",
  "ΚΩΝΩΝ",
  "ΑΦΙΣΑ",
  "ΟΞΥΝΩ",
  "ΕΚΤΟΣ",
  "ΦΡΙΖΑ",
  "ΑΣΙΤΑ",
  "ΘΕΙΟΙ",
  "ΤΥΦΩΝ",
  "ΚΑΠΝΕ",
  "ΠΑΤΩΝ",
  "ΒΑΤΕΣ",
  "ΟΠΑΙΑ",
  "ΓΕΡΩΝ",
  "ΙΣΤΟΥ",
  "ΠΩΡΩΝ",
  "ΦΛΟΚΟ",
  "ΦΡΑΓΗ",
  "ΚΑΚΩΝ",
  "ΣΑΛΟΣ",
  "ΚΟΜΙΚ",
  "ΛΙΓΟΙ",
  "ΕΡΜΟΙ",
  "ΓΟΥΒΑ",
  "ΚΟΡΜΙ",
  "ΜΥΣΙΣ",
  "ΓΟΜΑΣ",
  "ΑΣΒΟΙ",
  "ΔΥΑΡΑ",
  "ΑΔΕΙΑ",
  "ΕΛΗΓΕ",
  "ΠΙΕΣΑ",
  "ΓΝΩΘΩ",
  "ΛΟΒΟΥ",
  "ΠΟΛΚΑ",
  "ΕΘΝΩΝ",
  "ΦΛΕΒΑ",
  "ΨΟΓΟΥ",
  "ΜΙΣΘΟ",
  "ΨΕΝΑΝ",
  "ΟΞΙΝΟ",
  "ΨΩΛΩΝ",
  "ΘΙΝΩΝ",
  "ΛΑΓΝΟ",
  "ΟΡΘΙΕ",
  "ΔΗΜΙΟ",
  "ΑΧΕΡΟ",
  "ΤΡΟΧΟ",
  "ΩΩΔΗΣ",
  "ΤΡΟΠΕ",
  "ΙΚΑΝΗ",
  "ΟΧΥΡΕ",
  "ΑΛΑΝΑ",
  "ΕΝΕΟΣ",
  "ΛΕΙΨΑ",
  "ΕΥΡΥΣ",
  "ΠΗΔΟΙ",
  "ΦΩΛΕΑ",
  "ΣΤΙΖΩ",
  "ΠΤΕΡΟ",
  "ΜΟΡΦΗ",
  "ΠΡΟΚΑ",
  "ΕΛΑΤΗ",
  "ΜΕΣΤΟ",
  "ΧΛΩΜΟ",
  "ΦΗΓΟΣ",
  "ΨΥΧΕΣ",
  "ΝΕΦΡΕ",
  "ΠΑΡΕΟ",
  "ΙΣΟΙΣ",
  "ΑΠΟΞΩ",
  "ΦΛΕΓΕ",
  "ΣΗΚΟΥ",
  "ΤΕΡΠΕ",
  "ΣΓΑΡΑ",
  "ΠΑΣΤΗ",
  "ΑΛΛΩΝ",
  "ΑΠΛΕΣ",
  "ΛΟΓΩΝ",
  "ΓΚΑΒΗ",
  "ΣΑΠΙΑ",
  "ΑΞΕΝΕ",
  "ΞΥΣΕΙ",
  "ΑΤΟΜΑ",
  "ΟΚΝΟΣ",
  "ΚΟΤΣΙ",
  "ΑΩΡΕΣ",
  "ΑΕΡΓΗ",
  "ΔΑΣΙΑ",
  "ΟΓΡΟΥ",
  "ΣΚΥΡΑ",
  "ΦΥΛΑΝ",
  "ΤΡΑΝΟ",
  "ΨΥΞΕΙ",
  "ΔΟΥΛΑ",
  "ΛΟΥΦΑ",
  "ΙΠΠΕΑ",
  "ΧΑΝΟΙ",
  "ΧΩΝΙΑ",
  "ΑΡΤΟΣ",
  "ΡΗΤΩΡ",
  "ΘΡΥΒΩ",
  "ΚΟΣΜΕ",
  "ΔΡΥΟΣ",
  "ΧΟΡΧΕ",
  "ΣΙΓΕΙ",
  "ΤΟΣΟΙ",
  "ΚΑΛΜΑ",
  "ΕΛΑΤΟ",
  "ΚΛΗΤΗ",
  "ΤΡΕΨΩ",
  "ΚΟΡΜΕ",
  "ΝΗΣΙΑ",
  "ΡΕΓΧΩ",
  "ΦΕΡΘΩ",
  "ΟΡΘΙΟ",
  "ΚΟΥΤΗ",
  "ΡΥΠΟΥ",
  "ΚΟΠΟΣ",
  "ΕΤΗΞΕ",
  "ΙΤΑΜΗ",
  "ΚΟΤΣΟ",
  "ΕΔΥΣΕ",
  "ΓΟΜΩΝ",
  "ΠΥΚΝΗ",
  "ΠΙΛΟΥ",
  "ΘΥΜΟΥ",
  "ΔΑΥΤΟ",
  "ΜΕΛΗΣ",
  "ΑΘΩΩΝ",
  "ΚΡΥΑΝ",
  "ΔΟΚΟΣ",
  "ΗΤΤΩΝ",
  "ΑΝΙΟΝ",
  "ΟΥΡΙΟ",
  "ΨΑΞΟΥ",
  "ΑΘΕΛΗ",
  "ΝΥΚΤΑ",
  "ΚΥΚΛΩ",
  "ΑΡΑΖΑ",
  "ΚΙΣΣΑ",
  "ΞΕΝΟΣ",
  "ΑΤΥΠΗ",
  "ΤΗΡΑΣ",
  "ΑΓΑΠΑ",
  "ΚΗΤΟΣ",
  "ΠΙΟΤΑ",
  "ΑΓΡΙΑ",
  "ΒΟΛΕΣ",
  "ΣΟΥΠΕ",
  "ΠΑΚΟΥ",
  "ΠΑΓΟΥ",
  "ΑΙΓΕΣ",
  "ΕΞΟΧΗ",
  "ΨΟΦΙΟ",
  "ΜΑΚΡΗ",
  "ΑΔΕΙΣ",
  "ΣΥΛΕΙ",
  "ΗΧΕΙΑ",
  "ΒΑΤΟΣ",
  "ΕΤΥΧΑ",
  "ΟΝΟΜΑ",
  "ΤΟΥΤΗ",
  "ΜΥΗΘΩ",
  "ΛΑΧΝΕ",
  "ΘΕΛΓΩ",
  "ΚΑΥΤΕ",
  "ΧΑΡΟΙ",
  "ΜΕΛΩΝ",
  "ΠΝΙΓΗ",
  "ΟΣΙΟΥ",
  "ΣΟΟΥΛ",
  "ΒΩΒΩΝ",
  "ΛΑΓΙΟ",
  "ΛΑΗΝΙ",
  "ΕΝΘΕΑ",
  "ΝΕΚΡΟ",
  "ΨΗΛΗΣ",
  "ΟΡΦΝΟ",
  "ΚΑΥΣΗ",
  "ΦΑΣΕΣ",
  "ΑΕΝΑΟ",
  "ΝΙΟΤΗ",
  "ΧΡΥΣΗ",
  "ΛΥΓΑΣ",
  "ΖΩΝΤΑ",
  "ΟΥΔΩΝ",
  "ΜΑΓΙΑ",
  "ΝΟΣΤΟ",
  "ΚΗΡΟΙ",
  "ΠΑΤΟΥ",
  "ΕΦΥΓΑ",
  "ΑΠΛΩΣ",
  "ΣΑΠΙΟ",
  "ΥΕΛΟΣ",
  "ΒΥΘΩΝ",
  "ΚΟΠΤΗ",
  "ΒΙΟΥΣ",
  "ΨΥΧΗΣ",
  "ΕΝΕΟΙ",
  "ΠΟΡΟΣ",
  "ΖΥΘΟΣ",
  "ΛΑΙΚΕ",
  "ΛΕΜΒΟ",
  "ΛΑΜΑΣ",
  "ΛΥΤΩΝ",
  "ΕΠΛΕΕ",
  "ΣΟΥΝΑ",
  "ΠΤΩΧΗ",
  "ΕΚΤΙΩ",
  "ΧΑΛΑΩ",
  "ΗΣΣΟΝ",
  "ΕΚΟΨΑ",
  "ΣΙΓΑΝ",
  "ΒΛΗΤΟ",
  "ΛΕΡΟΥ",
  "ΔΙΚΙΟ",
  "ΒΑΨΤΕ",
  "ΜΑΞΙΣ",
  "ΡΕΙΚΙ",
  "ΜΠΟΡΩ",
  "ΣΤΟΑΣ",
  "ΨΑΛΤΑ",
  "ΕΔΙΚΟ",
  "ΟΓΔΟΑ",
  "ΒΑΣΤΩ",
  "ΦΕΥΓΕ",
  "ΤΑΦΤΑ",
  "ΘΑΜΒΗ",
  "ΦΙΛΑΣ",
  "ΔΙΚΕΣ",
  "ΧΑΒΕΙ",
  "ΚΥΡΤΗ",
  "ΑΤΑΦΕ",
  "ΝΕΤΟΙ",
  "ΣΤΑΛΕ",
  "ΤΟΤΕΣ",
  "ΕΩΛΕΣ",
  "ΔΙΚΟΣ",
  "ΝΟΘΕΣ",
  "ΩΡΙΜΕ",
  "ΛΕΠΙΑ",
  "ΜΑΚΡΑ",
  "ΟΡΓΗΣ",
  "ΧΟΥΣΙ",
  "ΠΟΔΟΣ",
  "ΛΕΣΙΑ",
  "ΛΟΦΙΟ",
  "ΑΘΡΟΟ",
  "ΛΑΧΟΣ",
  "ΓΙΑΤΙ",
  "ΕΝΕΚΑ",
  "ΛΟΙΠΗ",
  "ΛΙΤΕΣ",
  "ΕΥΚΕΣ",
  "ΤΕΚΕΣ",
  "ΜΕΘΗΣ",
  "ΨΙΧΕΣ",
  "ΚΛΩΝΟ",
  "ΧΑΡΙΣ",
  "ΟΙΝΟΣ",
  "ΛΥΡΕΣ",
  "ΝΙΛΑΣ",
  "ΟΥΛΟΣ",
  "ΛΑΙΜΑ",
  "ΑΠΑΛΟ",
  "ΨΙΧΑΣ",
  "ΚΟΡΗΝ",
  "ΑΣΩΤΑ",
  "ΒΡΟΧΟ",
  "ΖΟΥΣΑ",
  "ΗΤΑΝΕ",
  "ΘΛΙΒΕ",
  "ΦΕΛΑΣ",
  "ΕΡΕΤΗ",
  "ΜΠΕΙΣ",
  "ΑΡΓΩΝ",
  "ΛΑΤΙΝ",
  "ΤΑΓΟΙ",
  "ΠΡΑΣΑ",
  "ΑΡΓΕΣ",
  "ΟΥΖΩΝ",
  "ΣΩΘΕΙ",
  "ΛΑΒΡΟ",
  "ΘΟΡΙΟ",
  "ΜΠΕΡΕ",
  "ΣΑΚΟΙ",
  "ΝΟΜΩΝ",
  "ΝΕΚΡΑ",
  "ΣΚΟΤΑ",
  "ΑΛΥΤΕ",
  "ΣΟΦΟΥ",
  "ΤΟΡΝΕ",
  "ΙΚΑΝΕ",
  "ΝΑΥΛΟ",
  "ΣΤΕΨΩ",
  "ΜΑΤΗΝ",
  "ΟΛΙΓΑ",
  "ΖΥΓΩΝ",
  "ΛΟΥΣΗ",
  "ΑΦΥΕΣ",
  "ΓΑΡΟΥ",
  "ΠΑΘΟΝ",
  "ΕΥΗΘΗ",
  "ΠΑΧΟΣ",
  "ΓΑΥΡΗ",
  "ΜΕΘΑΣ",
  "ΕΝΙΚΟ",
  "ΛΑΓΟΙ",
  "ΕΤΥΧΕ",
  "ΕΧΘΕΣ",
  "ΠΗΚΤΟ",
  "ΧΩΝΕΣ",
  "ΧΡΗΖΩ",
  "ΙΩΔΕΣ",
  "ΤΕΡΕΝ",
  "ΤΑΙΣΩ",
  "ΟΛΟΕΝ",
  "ΛΙΑΝΑ",
  "ΔΙΗΓΕ",
  "ΛΑΓΩΝ",
  "ΨΕΛΛΕ",
  "ΦΛΟΚΕ",
  "ΑΒΟΛΕ",
  "ΜΕΝΑΝ",
  "ΤΕΡΠΩ",
  "ΡΑΚΟΣ",
  "ΛΑΒΑΣ",
  "ΞΗΡΟΥ",
  "ΛΕΣΧΗ",
  "ΩΧΡΙΑ",
  "ΛΟΓΑΣ",
  "ΦΤΕΝΟ",
  "ΣΑΡΠΑ",
  "ΗΡΕΜΟ",
  "ΨΕΥΔΑ",
  "ΟΞΙΩΝ",
  "ΠΟΥΘΕ",
  "ΚΟΦΤΩ",
  "ΠΛΗΡΗ",
  "ΔΑΡΤΗ",
  "ΚΟΛΟΥ",
  "ΑΚΟΝΗ",
  "ΚΥΛΑΩ",
  "ΤΥΠΟΙ",
  "ΠΡΑΟΝ",
  "ΟΖΩΔΗ",
  "ΓΙΑΠΙ",
  "ΜΟΣΧΟ",
  "ΚΥΛΛΕ",
  "ΤΑΧΙΑ",
  "ΛΙΤΟΥ",
  "ΚΑΛΙΟ",
  "ΔΥΟΥΝ",
  "ΜΑΝΟΣ",
  "ΗΘΕΛΕ",
  "ΑΕΤΟΥ",
  "ΑΣΥΛΑ",
  "ΑΔΡΑΣ",
  "ΠΑΥΛΑ",
  "ΘΥΜΟΙ",
  "ΣΚΟΠΟ",
  "ΦΑΙΟΣ",
  "ΣΩΖΕΙ",
  "ΜΕΜΨΗ",
  "ΑΓΟΝΑ",
  "ΘΑΝΩΝ",
  "ΚΩΛΟΣ",
  "ΤΡΩΓΩ",
  "ΚΩΦΟΙ",
  "ΣΙΤΩΝ",
  "ΣΤΟΛΕ",
  "ΠΟΝΤΕ",
  "ΠΕΝΑΣ",
  "ΠΤΥΞΗ",
  "ΤΡΟΠΩ",
  "ΣΩΣΤΗ",
  "ΘΝΗΤΑ",
  "ΧΑΙΔΙ",
  "ΚΡΙΟΣ",
  "ΚΙΜΑΣ",
  "ΓΟΥΛΑ",
  "ΚΙΤΡΟ",
  "ΑΚΑΚΗ",
  "ΜΥΕΛΕ",
  "ΡΙΚΝΟ",
  "ΣΤΙΜΗ",
  "ΚΕΔΡΑ",
  "ΑΤΜΟΙ",
  "ΚΡΙΩΝ",
  "ΧΑΓΗΣ",
  "ΠΥΩΣΗ",
  "ΤΡΙΑΣ",
  "ΣΒΕΣΗ",
  "ΑΛΕΘΩ",
  "ΕΥΩΔΗ",
  "ΣΠΑΡΟ",
  "ΑΘΕΟΥ",
  "ΜΩΡΟΥ",
  "ΞΕΣΤΑ",
  "ΕΡΠΕΙ",
  "ΥΠΝΩΝ",
  "ΓΟΝΟΙ",
  "ΑΛΑΤΙ",
  "ΠΟΛΟΥ",
  "ΚΟΙΤΩ",
  "ΠΡΙΝΕ",
  "ΜΠΛΟΚ",
  "ΙΤΙΩΝ",
  "ΚΟΜΒΟ",
  "ΣΟΥΚΟ",
  "ΒΙΤΣΑ",
  "ΦΟΡΜΑ",
  "ΟΛΚΟΙ",
  "ΑΡΠΕΣ",
  "ΕΡΗΜΟ",
  "ΣΤΕΝΟ",
  "ΖΩΘΕΙ",
  "ΑΧΑΝΗ",
  "ΘΡΕΨΩ",
  "ΡΙΚΝΑ",
  "ΣΑΝΟΙ",
  "ΒΛΑΨΩ",
  "ΨΗΤΟΥ",
  "ΛΕΥΚΑ",
  "ΑΚΡΩΣ",
  "ΑΘΛΩΝ",
  "ΛΟΤΟΣ",
  "ΨΙΧΙΟ",
  "ΦΟΡΩΝ",
  "ΑΔΗΛΗ",
  "ΑΣΘΜΑ",
  "ΔΟΝΕΙ",
  "ΖΟΡΙΑ",
  "ΛΕΠΤΗ",
  "ΧΤΗΜΑ",
  "ΑΛΥΚΗ",
  "ΠΑΡΚΕ",
  "ΝΟΜΟΣ",
  "ΝΥΞΙΣ",
  "ΔΕΜΑΣ",
  "ΚΙΡΣΟ",
  "ΑΠΑΠΑ",
  "ΡΕΑΤΕ",
  "ΡΕΥΣΕ",
  "ΑΨΥΧΗ",
  "ΦΟΡΑΣ",
  "ΛΟΦΟΥ",
  "ΦΑΙΟΥ",
  "ΠΟΡΝΟ",
  "ΑΥΤΗΝ",
  "ΟΡΜΟΙ",
  "ΜΙΚΤΗ",
  "ΒΡΕΞΗ",
  "ΧΥΜΟΙ",
  "ΚΡΥΟΥ",
  "ΑΒΑΦΑ",
  "ΦΘΙΝΕ",
  "ΚΩΜΗΣ",
  "ΣΚΑΖΩ",
  "ΤΣΑΠΙ",
  "ΟΣΜΕΣ",
  "ΚΟΥΡΑ",
  "ΜΟΧΤΟ",
  "ΤΑΦΡΟ",
  "ΤΟΚΟΙ",
  "ΥΜΝΩΝ",
  "ΑΧΥΜΟ",
  "ΜΟΜΦΗ",
  "ΨΕΛΛΟ",
  "ΤΑΚΤΕ",
  "ΡΙΧΤΗ",
  "ΠΕΝΙΑ",
  "ΠΑΛΗΣ",
  "ΚΛΕΩΝ",
  "ΕΥΝΟΥ",
  "ΠΙΟΤΟ",
  "ΟΠΩΡΑ",
  "ΝΟΜΕΣ",
  "ΚΛΙΜΑ",
  "ΑΠΕΧΩ",
  "ΒΓΑΛΩ",
  "ΤΡΩΕΙ",
  "ΟΠΙΟΥ",
  "ΙΣΧΝΟ",
  "ΘΟΛΩΝ",
  "ΚΟΜΨΕ",
  "ΣΤΕΓΗ",
  "ΔΙΑΓΕ",
  "ΗΜΕΙΣ",
  "ΚΟΜΗΣ",
  "ΑΞΙΣΕ",
  "ΙΤΙΑΣ",
  "ΑΨΥΧΕ",
  "ΝΩΘΡΟ",
  "ΚΙΣΣΟ",
  "ΠΗΓΩΝ",
  "ΠΕΡΑΝ",
  "ΠΕΡΚΑ",
  "ΠΑΡΚΑ",
  "ΚΟΤΣΕ",
  "ΛΑΛΑΣ",
  "ΠΡΑΟΙ",
  "ΟΧΘΟΥ",
  "ΛΙΜΝΗ",
  "ΔΟΘΕΙ",
  "ΘΕΣΗΣ",
  "ΜΟΛΟΣ",
  "ΑΥΠΝΟ",
  "ΒΛΑΒΗ",
  "ΑΠΗΝΗ",
  "ΕΡΙΟΝ",
  "ΔΑΥΛΟ",
  "ΚΟΠΙΩ",
  "ΚΟΥΠΙ",
  "ΛΟΓΓΟ",
  "ΚΑΦΡΟ",
  "ΦΤΕΝΕ",
  "ΑΨΗΤΑ",
  "ΗΒΙΚΟ",
  "ΨΟΦΟΣ",
  "ΕΚΔΥΩ",
  "ΒΕΡΩΝ",
  "ΕΡΑΝΕ",
  "ΑΝΟΣΕ",
  "ΥΨΩΣΕ",
  "ΚΑΦΕΣ",
  "ΠΑΙΝΑ",
  "ΦΟΡΤΙ",
  "ΘΕΑΜΑ",
  "ΠΟΤΗΣ",
  "ΑΔΥΤΑ",
  "ΔΡΟΜΕ",
  "ΦΑΚΟΣ",
  "ΚΑΠΑΣ",
  "ΑΧΥΜΗ",
  "ΜΥΔΡΕ",
  "ΕΥΗΧΟ",
  "ΞΕΡΟΙ",
  "ΕΞΕΧΩ",
  "ΖΑΒΕΣ",
  "ΒΑΡΔΕ",
  "ΙΔΙΟΝ",
  "ΠΝΙΞΕ",
  "ΒΑΛΕΙ",
  "ΧΑΡΟΣ",
  "ΑΧΡΟΟ",
  "ΜΥΙΚΑ",
  "ΞΕΝΗΝ",
  "ΡΥΓΧΗ",
  "ΗΧΕΡΗ",
  "ΧΟΜΠΙ",
  "ΗΡΕΜΕ",
  "ΤΕΡΨΩ",
  "ΟΞΥΝΑ",
  "ΦΟΚΟΥ",
  "ΒΟΥΒΕ",
  "ΠΡΩΤΗ",
  "ΧΕΙΛΙ",
  "ΙΣΑΛΕ",
  "ΠΥΘΙΑ",
  "ΤΑΚΤΟ",
  "ΡΑΚΕΣ",
  "ΠΡΑΜΑ",
  "ΛΑΛΑΩ",
  "ΜΝΗΜΗ",
  "ΚΡΙΝΑ",
  "ΑΗΧΟΣ",
  "ΚΡΩΖΩ",
  "ΓΡΥΠΑ",
  "ΨΩΝΙΟ",
  "ΜΥΤΩΝ",
  "ΣΚΟΡΟ",
  "ΕΞΑΔΑ",
  "ΦΥΣΗΣ",
  "ΕΡΠΩΝ",
  "ΒΟΥΛΑ",
  "ΑΘΕΤΩ",
  "ΦΟΝΟΥ",
  "ΟΥΛΕΣ",
  "ΝΩΔΩΝ",
  "ΟΔΙΚΑ",
  "ΣΦΗΚΑ",
  "ΖΕΨΟΥ",
  "ΠΥΤΙΑ",
  "ΚΡΕΠΙ",
  "ΠΑΛΚΟ",
  "ΡΙΠΤΩ",
  "ΧΕΝΑΣ",
  "ΑΣΩΤΟ",
  "ΟΡΙΩΝ",
  "ΦΕΞΕΙ",
  "ΩΧΡΟΙ",
  "ΜΑΜΜΗ",
  "ΣΤΗΝΕ",
  "ΑΡΧΟΥ",
  "ΑΧΘΕΙ",
  "ΥΦΑΛΑ",
  "ΠΕΣΕΙ",
  "ΗΠΑΤΑ",
  "ΑΝΕΤΑ",
  "ΕΝΑΓΗ",
  "ΕΞΗΓΕ",
  "ΜΠΕΤΑ",
  "ΠΕΔΙΟ",
  "ΚΑΙΝΗ",
  "ΑΘΟΛΕ",
  "ΟΙΚΟΥ",
  "ΓΕΡΟΙ",
  "ΟΔΙΚΟ",
  "ΚΕΔΡΟ",
  "ΠΥΞΟΙ",
  "ΧΥΘΕΙ",
  "ΠΕΙΝΩ",
  "ΙΩΔΗΣ",
  "ΣΑΧΗΣ",
  "ΠΟΘΟΝ",
  "ΧΑΝΟΣ",
  "ΠΟΛΟΙ",
  "ΠΟΝΤΟ",
  "ΠΑΓΙΑ",
  "ΗΧΗΣΩ",
  "ΨΥΧΡΟ",
  "ΖΗΛΩΝ",
  "ΞΥΡΟΙ",
  "ΠΛΑΝΗ",
  "ΑΛΟΓΗ",
  "ΜΠΕΣΑ",
  "ΨΩΡΑΣ",
  "ΛΩΡΟΙ",
  "ΤΟΝΩΝ",
  "ΛΙΧΝΟ",
  "ΑΡΑΙΟ",
  "ΟΡΑΣΗ",
  "ΦΕΞΗΣ",
  "ΥΛΙΚΗ",
  "ΧΑΣΑΝ",
  "ΦΟΥΜΕ",
  "ΒΙΩΣΕ",
  "ΜΙΣΤΕ",
  "ΚΑΥΛΙ",
  "ΗΣΥΧΟ",
  "ΦΑΓΙΑ",
  "ΔΕΥΤΕ",
  "ΛΗΨΙΣ",
  "ΟΜΟΙΕ",
  "ΛΑΛΙΑ",
  "ΨΕΛΛΗ",
  "ΕΡΕΒΕ",
  "ΠΥΡΡΟ",
  "ΚΑΙΝΕ",
  "ΠΙΕΤΑ",
  "ΑΚΤΗΣ",
  "ΟΨΕΙΣ",
  "ΣΕΚΩΝ",
  "ΑΟΣΜΟ",
  "ΦΥΛΕΣ",
  "ΘΗΤΩΝ",
  "ΡΟΧΘΟ",
  "ΛΟΥΣΕ",
  "ΗΣΥΧΗ",
  "ΑΡΜΗΣ",
  "ΑΛΩΝΙ",
  "ΑΥΡΑΣ",
  "ΧΗΛΕΣ",
  "ΨΕΚΤΟ",
  "ΣΟΥΜΑ",
  "ΚΟΙΛΗ",
  "ΔΕΥΡΟ",
  "ΟΛΙΓΗ",
  "ΘΟΛΗΣ",
  "ΧΟΑΝΗ",
  "ΗΠΙΑΝ",
  "ΚΟΤΑΣ",
  "ΑΓΑΘΟ",
  "ΤΥΠΩΝ",
  "ΦΥΛΩΝ",
  "ΠΡΩΙΑ",
  "ΓΕΡΑΣ",
  "ΜΕΙΞΗ",
  "ΦΕΤΑΣ",
  "ΒΡΟΜΩ",
  "ΜΥΡΤΑ",
  "ΨΗΘΕΙ",
  "ΞΕΧΝΩ",
  "ΤΡΥΖΩ",
  "ΜΑΣΕΡ",
  "ΜΠΑΤΗ",
  "ΤΣΕΠΗ",
  "ΚΕΝΟΥ",
  "ΡΟΓΩΝ",
  "ΖΑΒΟΙ",
  "ΒΑΘΡΟ",
  "ΔΕΣΤΕ",
  "ΓΕΙΣΑ",
  "ΤΕΥΧΗ",
  "ΖΗΛΟΥ",
  "ΣΩΡΟΣ",
  "ΣΑΛΑΣ",
  "ΤΟΚΩΝ",
  "ΗΡΘΕΣ",
  "ΑΛΚΕΣ",
  "ΑΡΡΕΝ",
  "ΤΗΡΑΩ",
  "ΛΟΞΩΣ",
  "ΗΣΟΥΝ",
  "ΤΟΥΦΑ",
  "ΕΔΕΣΑ",
  "ΔΕΛΤΑ",
  "ΤΡΑΝΑ",
  "ΕΨΑΞΕ",
  "ΣΠΑΘΗ",
  "ΣΠΑΧΗ",
  "ΜΥΛΟΥ",
  "ΑΓΑΛΙ",
  "ΚΛΑΚΑ",
  "ΗΧΗΡΟ",
  "ΧΤΥΠΟ",
  "ΣΤΟΦΑ",
  "ΓΟΜΟΙ",
  "ΣΑΠΙΕ",
  "ΑΦΟΡΑ",
  "ΧΩΡΕΙ",
  "ΕΞΩΜΟ",
  "ΖΩΣΤΕ",
  "ΑΡΙΩΝ",
  "ΑΦΟΡΩ",
  "ΒΑΛΛΕ",
  "ΜΙΑΝΩ",
  "ΑΖΥΓΕ",
  "ΑΦΡΟΣ",
  "ΑΜΕΣΗ",
  "ΠΕΖΟΣ",
  "ΧΕΡΣΟ",
  "ΒΟΥΩΝ",
  "ΤΡΟΦΟ",
  "ΧΩΛΟΣ",
  "ΘΕΣΙΣ",
  "ΕΡΕΨΑ",
  "ΔΙΕΠΩ",
  "ΑΙΔΩΣ",
  "ΛΥΤΟΙ",
  "ΗΞΕΡΑ",
  "ΑΥΤΩΝ",
  "ΚΑΛΕΙ",
  "ΙΑΧΗΣ",
  "ΡΩΓΜΗ",
  "ΙΔΙΚΟ",
  "ΕΣΧΕΣ",
  "ΡΗΓΑΣ",
  "ΤΑΙΣΑ",
  "ΚΩΦΩΝ",
  "ΛΥΣΣΩ",
  "ΠΕΡΝΑ",
  "ΓΑΝΑΣ",
  "ΛΕΥΚΩ",
  "ΑΓΙΑΣ",
  "ΖΕΡΒΕ",
  "ΑΚΟΝΙ",
  "ΗΔΕΩΣ",
  "ΑΓΑΝΗ",
  "ΚΤΙΣΩ",
  "ΜΟΔΙΑ",
  "ΟΡΙΣΩ",
  "ΡΟΛΟΣ",
  "ΣΤΟΧΟ",
  "ΟΥΔΟΥ",
  "ΑΔΙΚΕ",
  "ΟΧΕΤΕ",
  "ΣΧΟΛΗ",
  "ΞΕΡΝΩ",
  "ΕΛΑΤΕ",
  "ΤΕΠΕΣ",
  "ΕΙΠΕΣ",
  "ΡΙΖΩΝ",
  "ΣΧΑΡΑ",
  "ΝΕΦΡΙ",
  "ΜΙΖΩΝ",
  "ΡΙΜΕΣ",
  "ΕΧΑΨΕ",
  "ΑΓΝΩΝ",
  "ΦΛΟΙΕ",
  "ΠΤΙΛΟ",
  "ΤΡΕΨΕ",
  "ΓΥΦΤΟ",
  "ΑΠΤΗΣ",
  "ΒΩΜΟΙ",
  "ΥΔΡΙΑ",
  "ΠΗΝΙΑ",
  "ΑΤΜΟΣ",
  "ΦΡΑΞΑ",
  "ΡΟΥΣΕ",
  "ΚΡΥΟΝ",
  "ΑΓΝΟΥ",
  "ΛΗΘΗΝ",
  "ΛΟΥΡΑ",
  "ΓΟΕΡΟ",
  "ΚΡΑΞΕ",
  "ΣΟΥΔΑ",
  "ΠΟΝΑΩ",
  "ΘΙΝΕΣ",
  "ΨΑΛΤΕ",
  "ΠΟΙΟΥ",
  "ΑΡΕΣΩ",
  "ΑΒΑΘΗ",
  "ΟΛΜΩΝ",
  "ΚΟΛΑΙ",
  "ΜΩΡΟΙ",
  "ΕΛΥΝΕ",
  "ΥΠΝΟΥ",
  "ΑΛΑΛΕ",
  "ΩΜΙΚΕ",
  "ΣΩΡΩΝ",
  "ΒΟΔΙΑ",
  "ΥΦΕΣΗ",
  "ΧΑΜΩΝ",
  "ΡΥΖΙΑ",
  "ΑΘΥΜΟ",
  "ΜΑΛΗΣ",
  "ΡΙΧΤΕ",
  "ΨΕΥΔΕ",
  "ΤΟΠΟΙ",
  "ΥΦΑΛΟ",
  "ΙΣΧΝΗ",
  "ΒΑΙΝΩ",
  "ΠΟΖΕΣ",
  "ΕΤΕΡΟ",
  "ΜΑΝΤΟ",
  "ΜΕΣΟΥ",
  "ΔΥΑΡΙ",
  "ΤΡΥΦΗ",
  "ΟΝΙΚΕ",
  "ΤΖΟΓΟ",
  "ΠΥΡΓΙ",
  "ΨΕΞΤΕ",
  "ΓΟΥΛΙ",
  "ΦΑΚΑΣ",
  "ΖΥΓΟΝ",
  "ΠΩΛΩΝ",
  "ΔΙΤΤΗ",
  "ΩΚΙΜΑ",
  "ΤΖΙΦΕ",
  "ΟΦΣΕΤ",
  "ΖΩΣΕΙ",
  "ΚΑΝΑΣ",
  "ΠΥΡΡΑ",
  "ΕΡΕΒΗ",
  "ΤΡΑΤΑ",
  "ΚΡΩΖΕ",
  "ΑΒΑΘΑ",
  "ΙΤΑΜΑ",
  "ΚΗΠΟΙ",
  "ΤΟΜΟΥ",
  "ΤΕΙΝΩ",
  "ΧΑΘΕΙ",
  "ΜΠΟΕΜ",
  "ΔΑΣΜΟ",
  "ΑΞΥΛΟ",
  "ΦΤΩΧΑ",
  "ΚΑΡΩΝ",
  "ΦΑΓΑΝ",
  "ΑΤΥΠΟ",
  "ΣΙΓΗΣ",
  "ΣΥΡΜΑ",
  "ΕΞΩΣΗ",
  "ΤΟΞΟΝ",
  "ΚΑΡΦΙ",
  "ΠΑΓΩΝ",
  "ΚΑΒΑΣ",
  "ΙΠΠΟΣ",
  "ΖΥΜΕΣ",
  "ΑΗΔΙΑ",
  "ΕΠΑΨΑ",
  "ΚΥΛΛΗ",
  "ΕΧΕΤΕ",
  "ΕΘΙΞΑ",
  "ΝΟΝΟΣ",
  "ΤΥΠΤΕ",
  "ΜΟΛΙΣ",
  "ΙΤΑΜΕ",
  "ΛΑΙΜΟ",
  "ΜΑΥΡΕ",
  "ΞΕΝΙΟ",
  "ΕΨΗΣΕ",
  "ΦΡΑΠΑ",
  "ΗΛΙΩΝ",
  "ΜΥΡΤΟ",
  "ΔΕΟΥΣ",
  "ΒΑΛΛΩ",
  "ΚΛΩΒΟ",
  "ΙΜΑΜΗ",
  "ΧΛΟΜΗ",
  "ΣΚΑΚΙ",
  "ΑΣΤΗΣ",
  "ΧΡΥΣΕ",
  "ΩΛΕΝΗ",
  "ΛΟΣΤΟ",
  "ΗΡΩΟΝ",
  "ΕΝΙΨΕ",
  "ΚΥΑΝΟ",
  "ΦΑΛΛΟ",
  "ΘΥΤΩΝ",
  "ΤΟΚΑΣ",
  "ΣΚΕΛΗ",
  "ΣΟΜΦΟ",
  "ΒΑΛΤΗ",
  "ΜΙΝΑΣ",
  "ΔΙΩΡΟ",
  "ΘΑΛΠΩ",
  "ΘΛΙΨΗ",
  "ΒΑΤΟΥ",
  "ΨΑΡΟΥ",
  "ΦΑΒΕΣ",
  "ΑΠΙΟΥ",
  "ΕΙΡΩΝ",
  "ΜΩΡΟΣ",
  "ΛΟΥΖΕ",
  "ΤΕΝΙΣ",
  "ΙΑΧΕΣ",
  "ΜΙΚΡΟ",
  "ΑΛΥΠΟ",
  "ΔΙΨΕΣ",
  "ΔΙΚΗΣ",
  "ΣΤΥΨΗ",
  "ΕΞΩΜΑ",
  "ΤΑΓΩΝ",
  "ΚΑΣΜΑ",
  "ΚΛΑΡΑ",
  "ΡΥΜΕΣ",
  "ΜΟΣΚΕ",
  "ΓΡΙΠΟ",
  "ΚΛΙΤΗ",
  "ΘΙΑΣΟ",
  "ΣΟΦΙΑ",
  "ΚΑΠΝΑ",
  "ΟΡΟΙΣ",
  "ΧΑΛΚΕ",
  "ΗΠΙΕΣ",
  "ΑΣΑΦΗ",
  "ΑΡΚΤΟ",
  "ΛΑΙΚΑ",
  "ΟΡΙΣΕ",
  "ΖΕΣΤΕ",
  "ΔΟΛΩΝ",
  "ΣΑΡΕΣ",
  "ΟΡΘΡΕ",
  "ΣΥΡΤΟ",
  "ΨΗΤΩΝ",
  "ΕΤΥΜΑ",
  "ΚΑΚΤΟ",
  "ΚΥΒΟΙ",
  "ΚΡΙΚΟ",
  "ΑΞΕΝΗ",
  "ΔΙΚΟΥ",
  "ΑΝΑΒΕ",
  "ΓΑΔΟΣ",
  "ΣΦΙΞΕ",
  "ΣΕΠΤΟ",
  "ΕΡΩΤΩ",
  "ΕΤΟΥΣ",
  "ΚΛΗΘΩ",
  "ΑΨΗΛΗ",
  "ΕΜΙΡΗ",
  "ΑΔΟΛΗ",
  "ΛΕΙΨΩ",
  "ΕΙΠΑΝ",
  "ΟΧΤΩΝ",
  "ΛΙΠΟΣ",
  "ΛΗΡΟΥ",
  "ΧΟΙΡΕ",
  "ΟΡΘΩΣ",
  "ΠΥΡΟΥ",
  "ΒΛΙΤΑ",
  "ΕΦΥΗΝ",
  "ΔΟΚΟΙ",
  "ΟΦΡΥΣ",
  "ΑΨΗΤΕ",
  "ΧΝΟΤΟ",
  "ΜΠΑΣΕ",
  "ΑΓΟΥΝ",
  "ΖΗΛΟΙ",
  "ΠΟΣΤΑ",
  "ΣΤΥΒΕ",
  "ΜΑΧΩΝ",
  "ΡΟΙΔΟ",
  "ΖΥΘΟΙ",
  "ΑΛΕΕΣ",
  "ΒΟΗΘΑ",
  "ΕΝΝΟΩ",
  "ΞΗΡΟΝ",
  "ΒΟΥΒΗ",
  "ΕΛΑΒΑ",
  "ΓΕΡΜΑ",
  "ΧΕΙΛΗ",
  "ΕΙΣΑΙ",
  "ΝΤΥΝΩ",
  "ΑΝΟΣΑ",
  "ΟΠΙΣΩ",
  "ΕΡΜΟΣ",
  "ΚΑΜΠΗ",
  "ΚΕΙΝΑ",
  "ΠΟΣΟΙ",
  "ΤΟΝΟΙ",
  "ΩΧΡΕΣ",
  "ΑΦΘΕΣ",
  "ΑΟΙΔΟ",
  "ΕΦΕΤΗ",
  "ΚΟΛΛΩ",
  "ΑΘΕΟΣ",
  "ΓΕΝΩΝ",
  "ΔΙΩΡΗ",
  "ΤΕΦΡΕ",
  "ΟΜΝΥΩ",
  "ΨΕΥΤΗ",
  "ΥΠΝΟΣ",
  "ΒΑΣΕΙ",
  "ΛΟΥΚΙ",
  "ΡΑΦΙΣ",
  "ΚΟΜΨΟ",
  "ΜΑΚΡΟ",
  "ΛΑΓΙΕ",
  "ΑΣΚΙΑ",
  "ΨΗΤΟΙ",
  "ΧΗΛΩΝ",
  "ΒΟΛΗΣ",
  "ΣΩΤΗΡ",
  "ΜΙΛΑΝ",
  "ΕΡΕΒΑ",
  "ΚΑΔΟΣ",
  "ΦΑΚΟΥ",
  "ΤΥΠΟΝ",
  "ΞΥΘΕΙ",
  "ΧΩΣΤΕ",
  "ΝΙΚΕΣ",
  "ΓΕΡΤΗ",
  "ΚΥΣΤΗ",
  "ΣΚΑΒΕ",
  "ΚΟΥΝΩ",
  "ΛΟΡΔΕ",
  "ΑΛΤΗΣ",
  "ΙΔΙΟΙ",
  "ΕΒΑΨΑ",
  "ΟΣΙΟΙ",
  "ΜΕΝΤΑ",
  "ΓΛΑΡΗ",
  "ΒΙΑΣΕ",
  "ΙΔΙΑΝ",
  "ΘΑΦΤΩ",
  "ΟΜΒΡΟ",
  "ΚΟΥΦΑ",
  "ΤΟΦΟΣ",
  "ΙΔΡΟΙ",
  "ΚΡΑΣΙ",
  "ΕΔΩΣΕ",
  "ΠΑΛΑΣ",
  "ΝΟΣΤΕ",
  "ΣΩΣΙΑ",
  "ΔΙΝΩΝ",
  "ΑΠΥΡΗ",
  "ΑΧΥΡΟ",
  "ΠΕΥΚΕ",
  "ΖΕΥΚΙ",
  "ΤΟΝΑΖ",
  "ΣΤΥΦΟ",
  "ΖΗΛΟΣ",
  "ΞΟΦΛΩ",
  "ΦΑΡΟΙ",
  "ΚΟΜΜΟ",
  "ΜΟΡΟΥ",
  "ΖΩΜΟΥ",
  "ΙΚΡΙΟ",
  "ΦΑΡΔΥ",
  "ΚΤΙΖΩ",
  "ΣΚΟΠΩ",
  "ΟΛΙΚΑ",
  "ΚΟΥΛΑ",
  "ΒΡΟΤΟ",
  "ΚΑΙΝΑ",
  "ΓΕΓΕΣ",
  "ΦΑΛΛΕ",
  "ΦΑΡΣΙ",
  "ΣΟΥΡΑ",
  "ΕΥΧΗΣ",
  "ΠΡΑΑΣ",
  "ΕΣΟΧΗ",
  "ΤΑΠΗΣ",
  "ΑΣΒΟΣ",
  "ΣΑΖΕΙ",
  "ΒΡΟΜΑ",
  "ΛΕΠΡΑ",
  "ΕΘΙΖΩ",
  "ΣΧΑΖΩ",
  "ΙΣΚΙΟ",
  "ΑΔΥΤΕ",
  "ΑΤΑΦΗ",
  "ΓΟΒΕΣ",
  "ΦΑΡΩΝ",
  "ΑΘΩΟΣ",
  "ΧΤΥΠΑ",
  "ΖΥΓΟΙ",
  "ΜΑΝΤΗ",
  "ΥΙΙΚΕ",
  "ΛΙΒΑΣ",
  "ΜΟΝΟΙ",
  "ΤΟΡΜΕ",
  "ΝΟΜΟΙ",
  "ΘΑΠΤΩ",
  "ΑΧΑΤΗ",
  "ΜΥΕΛΟ",
  "ΨΥΧΡΕ",
  "ΞΙΝΟΣ",
  "ΜΥΗΣΩ",
  "ΜΠΑΣΟ",
  "ΕΑΥΤΟ",
  "ΕΝΝΟΑ",
  "ΡΑΠΤΩ",
  "ΒΟΓΚΑ",
  "ΟΡΦΝΗ",
  "ΒΑΖΕΙ",
  "ΑΝΙΕΣ",
  "ΩΔΙΚΕ",
  "ΝΗΣΟΣ",
  "ΟΡΙΖΑ",
  "ΚΡΥΦΟ",
  "ΠΑΤΕΡ",
  "ΝΟΘΟΙ",
  "ΜΕΘΑΝ",
  "ΒΑΒΩΣ",
  "ΝΙΩΣΕ",
  "ΟΓΡΩΝ",
  "ΑΝΕΜΗ",
  "ΝΙΚΑΣ",
  "ΛΑΙΚΟ",
  "ΒΙΑΣΑ",
  "ΘΙΝΑΣ",
  "ΑΘΥΜΩ",
  "ΓΛΥΚΥ",
  "ΩΧΡΟΣ",
  "ΗΤΤΕΣ",
  "ΖΟΥΣΕ",
  "ΕΧΕΖΑ",
  "ΔΑΥΤΗ",
  "ΑΘΕΡΑ",
  "ΚΛΟΠΗ",
  "ΓΕΡΝΑ",
  "ΟΠΤΩΝ",
  "ΑΛΥΠΑ",
  "ΕΤΑΦΗ",
  "ΡΗΧΕΣ",
  "ΑΙΝΟΙ",
  "ΕΛΟΥΣ",
  "ΕΘΝΟΣ",
  "ΔΕΣΙΣ",
  "ΡΑΣΠΑ",
  "ΑΜΩΜΗ",
  "ΑΞΥΛΑ",
  "ΛΙΑΝΟ",
  "ΕΦΟΡΕ",
  "ΧΑΡΑΝ",
  "ΚΑΛΦΑ",
  "ΧΙΟΝΗ",
  "ΑΖΥΓΗ",
  "ΛΕΓΕΙ",
  "ΣΑΛΤΑ",
  "ΠΛΕΝΕ",
  "ΠΟΛΦΕ",
  "ΑΠΑΧΕ",
  "ΠΙΚΡΗ",
  "ΚΡΕΑΣ",
  "ΠΕΛΑΑ",
  "ΜΕΛΙΑ",
  "ΧΙΠΙΣ",
  "ΜΥΗΣΑ",
  "ΑΚΤΗΝ",
  "ΥΨΗΛΟ",
  "ΟΔΗΓΕ",
  "ΚΑΙΕΙ",
  "ΞΕΝΟΙ",
  "ΟΚΝΗΣ",
  "ΒΛΑΨΕ",
  "ΔΙΑΓΩ",
  "ΚΛΟΜΠ",
  "ΒΡΑΚΑ",
  "ΝΙΑΤΑ",
  "ΚΥΤΩΝ",
  "ΑΜΙΓΗ",
  "ΘΑΡΡΗ",
  "ΒΓΑΖΩ",
  "ΧΑΣΙΣ",
  "ΠΙΤΑΣ",
  "ΠΛΥΝΩ",
  "ΕΞΗΓΩ",
  "ΚΤΗΝΗ",
  "ΚΕΡΑΣ",
  "ΣΕΙΑΝ",
  "ΗΝΙΟΥ",
  "ΤΑΓΟΥ",
  "ΞΥΓΚΙ",
  "ΔΕΚΤΕ",
  "ΠΑΡΙΟ",
  "ΦΟΙΤΑ",
  "ΠΕΖΕΣ",
  "ΟΞΕΩΝ",
  "ΝΩΠΟΙ",
  "ΒΟΕΡΟ",
  "ΑΚΕΦΗ",
  "ΚΛΙΝΗ",
  "ΧΑΥΝΑ",
  "ΡΑΦΤΗ",
  "ΠΙΑΤΟ",
  "ΩΘΗΣΕ",
  "ΛΙΘΟΙ",
  "ΠΕΝΤΕ",
  "ΥΦΑΔΙ",
  "ΛΙΧΝΕ",
  "ΣΥΣΠΩ",
  "ΣΦΑΛΩ",
  "ΛΑΙΔΗ",
  "ΥΨΩΘΩ",
  "ΝΟΥΝΑ",
  "ΕΦΟΔΟ",
  "ΣΟΔΙΑ",
  "ΔΙΕΤΗ",
  "ΑΔΙΚΑ",
  "ΜΙΣΧΕ",
  "ΧΑΨΙΑ",
  "ΑΡΑΙΕ",
  "ΦΕΡΕΙ",
  "ΛΟΓΟΝ",
  "ΒΟΣΚΗ",
  "ΑΒΙΟΣ",
  "ΕΝΔΥΕ",
  "ΚΡΥΨΩ",
  "ΔΟΞΕΣ",
  "ΤΡΑΤΟ",
  "ΝΑΟΥΣ",
  "ΕΠΕΣΕ",
  "ΒΟΡΙΟ",
  "ΧΕΡΣΑ",
  "ΓΡΑΔΑ",
  "ΣΤΡΕΣ",
  "ΓΙΔΩΝ",
  "ΣΑΜΠΙ",
  "ΦΤΥΣΩ",
  "ΑΦΙΛΑ",
  "ΚΥΑΝΗ",
  "ΑΜΕΛΩ",
  "ΙΣΚΑΣ",
  "ΤΑΙΖΑ",
  "ΤΟΛΜΗ",
  "ΜΝΕΙΑ",
  "ΣΑΞΑΝ",
  "ΑΞΙΕΣ",
  "ΡΑΔΙΑ",
  "ΕΠΟΥΣ",
  "ΝΕΤΕΣ",
  "ΔΙΚΩΝ",
  "ΠΙΛΟΣ",
  "ΣΚΙΝΑ",
  "ΤΡΕΝΟ",
  "ΡΟΦΩΝ",
  "ΚΑΤΟΥ",
  "ΜΟΙΧΕ",
  "ΤΟΠΟΣ",
  "ΚΑΚΗΣ",
  "ΠΛΙΘΙ",
  "ΣΑΧΛΗ",
  "ΛΑΣΚΕ",
  "ΦΥΡΟΣ",
  "ΣΚΑΙΗ",
  "ΛΙΒΡΑ",
  "ΝΤΥΝΕ",
  "ΚΟΒΕΙ",
  "ΦΑΚΩΝ",
  "ΔΙΩΡΑ",
  "ΛΑΧΕΙ",
  "ΑΚΙΔΑ",
  "ΛΑΣΟΥ",
  "ΑΘΡΟΕ",
  "ΘΕΟΥΣ",
  "ΕΛΞΗΣ",
  "ΑΝΙΣΟ",
  "ΑΡΓΟΝ",
  "ΛΕΠΤΕ",
  "ΙΣΙΟΙ",
  "ΑΠΟΔΑ",
  "ΣΕΚΟΙ",
  "ΖΩΗΡΕ",
  "ΟΥΡΕΙ",
  "ΞΕΝΟΥ",
  "ΥΓΡΕΣ",
  "ΟΜΠΟΕ",
  "ΨΑΛΘΩ",
  "ΜΕΤΡΟ",
  "ΔΗΛΟΝ",
  "ΜΠΟΓΟ",
  "ΛΕΠΡΟ",
  "ΩΔΙΚΗ",
  "ΕΛΙΩΝ",
  "ΑΦΑΤΕ",
  "ΧΤΙΣΕ",
  "ΑΙΧΜΗ",
  "ΑΔΟΤΑ",
  "ΛΥΓΑΩ",
  "ΜΟΙΧΟ",
  "ΙΝΩΜΑ",
  "ΚΤΥΠΩ",
  "ΟΠΙΟΝ",
  "ΕΡΙΦΗ",
  "ΙΔΡΥΕ",
  "ΑΝΦΑΣ",
  "ΕΜΕΤΕ",
  "ΑΦΗΝΕ",
  "ΒΡΟΧΗ",
  "ΕΦΕΡΑ",
  "ΔΕΤΟΙ",
  "ΘΕΤΩΝ",
  "ΜΟΝΗΝ",
  "ΣΦΗΝΑ",
  "ΨΙΛΟΥ",
  "ΛΑΜΕΣ",
  "ΧΟΡΤΑ",
  "ΑΔΑΕΣ",
  "ΘΙΞΕΙ",
  "ΦΑΠΩΝ",
  "ΒΟΛΤΑ",
  "ΣΑΧΤΩ",
  "ΣΧΗΜΑ",
  "ΚΤΕΝΙ",
  "ΡΙΝΩΝ",
  "ΣΠΙΖΑ",
  "ΒΡΙΣΩ",
  "ΦΑΓΕΙ",
  "ΔΟΞΑΝ",
  "ΗΠΙΑΣ",
  "ΞΕΦΤΙ",
  "ΕΡΙΖΕ",
  "ΑΟΚΝΑ",
  "ΕΞΟΧΟ",
  "ΘΗΚΩΝ",
  "ΑΠΗΧΩ",
  "ΕΣΑΕΙ",
  "ΛΑΚΚΟ",
  "ΡΟΥΣΑ",
  "ΧΛΟΜΑ",
  "ΟΧΥΡΟ",
  "ΡΟΔΙΕ",
  "ΟΓΡΕΣ",
  "ΓΟΕΡΕ",
  "ΦΟΒΟΙ",
  "ΜΠΟΡΑ",
  "ΚΙΡΚΕ",
  "ΚΡΥΒΩ",
  "ΚΑΦΕΑ",
  "ΙΔΙΚΗ",
  "ΙΖΗΜΑ",
  "ΕΧΤΡΕ",
  "ΕΞΩΜΕ",
  "ΗΠΙΩΣ",
  "ΕΥΔΙΑ",
  "ΚΟΥΠΕ",
  "ΤΕΘΕΝ",
  "ΑΦΩΝΑ",
  "ΚΥΦΟΙ",
  "ΑΕΡΕΣ",
  "ΩΔΙΝΩ",
  "ΑΝΕΒΩ",
  "ΑΡΟΥΝ",
  "ΙΕΡΟΙ",
  "ΜΥΓΕΣ",
  "ΦΘΑΡΩ",
  "ΜΕΡΕΣ",
  "ΣΠΟΔΟ",
  "ΣΟΔΑΣ",
  "ΔΡΑΜΑ",
  "ΑΦΥΩΝ",
  "ΠΡΑΞΕ",
  "ΦΙΣΚΑ",
  "ΧΑΜΟΣ",
  "ΣΕΛΕΣ",
  "ΣΙΕΛΟ",
  "ΝΩΘΡΕ",
  "ΕΜΕΤΟ",
  "ΚΡΑΛΗ",
  "ΕΦΑΓΑ",
  "ΠΑΙΔΙ",
  "ΑΝΟΙΑ",
  "ΛΑΙΚΗ",
  "ΚΩΦΕΣ",
  "ΓΡΑΨΩ",
  "ΡΩΜΗΣ",
  "ΚΕΔΡΕ",
  "ΕΤΑΖΕ",
  "ΝΕΥΡΑ",
  "ΦΥΤΟΥ",
  "ΦΟΛΑΣ",
  "ΛΕΚΕΣ",
  "ΖΑΡΑΣ",
  "ΑΗΔΕΣ",
  "ΟΣΚΑΡ",
  "ΝΕΤΟΣ",
  "ΕΣΜΟΣ",
  "ΣΩΝΕΙ",
  "ΑΔΡΩΝ",
  "ΕΙΔΑΝ",
  "ΒΡΟΧΙ",
  "ΖΩΩΔΗ",
  "ΛΙΝΟΥ",
  "ΥΨΟΥΣ",
  "ΑΘΛΙΟ",
  "ΔΙΑΝΑ",
  "ΘΗΡΕΣ",
  "ΣΥΧΝΟ",
  "ΠΡΙΝΟ",
  "ΓΙΟΚΑ",
  "ΘΑΦΤΕ",
  "ΑΥΘΙΣ",
  "ΑΧΝΗΣ",
  "ΑΖΗΛΟ",
  "ΤΙΜΕΣ",
  "ΤΡΑΓΟ",
  "ΓΑΜΟΥ",
  "ΜΠΟΛΙ",
  "ΑΕΤΩΝ",
  "ΒΙΛΕΣ",
  "ΠΝΟΗΣ",
  "ΑΓΕΤΕ",
  "ΠΑΡΚΟ",
  "ΑΓΟΡΙ",
  "ΛΙΩΜΑ",
  "ΚΡΥΑΣ",
  "ΙΜΕΡΕ",
  "ΟΓΚΟΣ",
  "ΘΑΝΗΣ",
  "ΑΦΟΒΑ",
  "ΣΤΙΒΟ",
  "ΑΖΥΜΗ",
  "ΙΔΕΩΝ",
  "ΤΥΠΟΥ",
  "ΠΙΚΡΕ",
  "ΜΟΡΕΑ",
  "ΕΘΑΒΑ",
  "ΤΡΥΓΕ",
  "ΥΠΑΓΩ",
  "ΡΟΓΧΕ",
  "ΥΑΡΔΑ",
  "ΧΤΥΠΕ",
  "ΖΗΣΗΣ",
  "ΞΕΡΟΣ",
  "ΒΙΔΕΣ",
  "ΥΒΩΜΑ",
  "ΑΚΕΦΑ",
  "ΑΧΟΛΑ",
  "ΗΧΟΥΝ",
  "ΤΥΡΦΗ",
  "ΥΕΤΟΥ",
  "ΡΑΙΒΕ",
  "ΑΚΟΕΣ",
  "ΑΞΙΝΑ",
  "ΨΩΡΕΣ",
  "ΔΙΤΤΟ",
  "ΣΥΡΜΟ",
  "ΛΙΑΝΕ",
  "ΣΙΦΟΝ",
  "ΜΩΡΕΣ",
  "ΜΑΖΕΣ",
  "ΠΕΣΩΝ",
  "ΣΜΥΡΗ",
  "ΣΩΖΩΝ",
  "ΙΛΑΡΕ",
  "ΓΔΥΤΕ",
  "ΑΝΘΙΑ",
  "ΤΑΚΟΙ",
  "ΠΛΗΞΗ",
  "ΡΑΧΩΝ",
  "ΚΛΗΡΕ",
  "ΟΡΘΟΝ",
  "ΒΟΗΘΕ",
  "ΟΞΙΕΣ",
  "ΙΣΧΝΕ",
  "ΜΟΡΙΟ",
  "ΑΧΑΡΑ",
  "ΕΠΑΘΕ",
  "ΠΙΣΤΑ",
  "ΚΥΑΝΕ",
  "ΕΣΕΙΣ",
  "ΑΜΑΞΙ",
  "ΣΑΡΓΟ",
  "ΜΟΥΛΟ",
  "ΜΑΓΟΥ",
  "ΘΗΡΙΑ",
  "ΕΣΕΝΑ",
  "ΑΞΙΟΝ",
  "ΑΡΑΤΟ",
  "ΦΡΑΚΟ",
  "ΜΕΣΤΗ",
  "ΚΛΕΒΕ",
  "ΑΜΥΝΑ",
  "ΛΟΜΠΙ",
  "ΟΑΣΗΣ",
  "ΑΜΟΝΩ",
  "ΤΡΑΒΩ",
  "ΛΙΓΟΣ",
  "ΜΕΡΙΑ",
  "ΑΔΩΡΕ",
  "ΑΖΗΛΗ",
  "ΑΛΛΟΥ",
  "ΥΑΛΟΥ",
  "ΑΨΙΔΑ",
  "ΚΟΛΕΟ",
  "ΒΟΥΤΑ",
  "ΑΜΑΧΟ",
  "ΑΕΝΑΑ",
  "ΧΑΒΡΗ",
  "ΣΚΙΑΝ",
  "ΚΑΡΠΕ",
  "ΚΟΥΒΑ",
  "ΧΡΗΖΕ",
  "ΑΘΥΜΕ",
  "ΑΝΟΜΑ",
  "ΩΡΑΙΕ",
  "ΜΥΡΙΑ",
  "ΛΩΡΩΝ",
  "ΑΤΥΠΑ",
  "ΩΤΙΤΗ",
  "ΝΕΦΟΣ",
  "ΦΘΗΝΗ",
  "ΒΟΜΒΩ",
  "ΧΕΖΕΙ",
  "ΠΑΙΞΕ",
  "ΠΥΓΗΣ",
  "ΣΙΜΗΣ",
  "ΤΥΨΗΣ",
  "ΒΗΧΕΙ",
  "ΟΚΝΕΣ",
  "ΧΑΥΝΟ",
  "ΚΟΡΣΕ",
  "ΚΑΗΜΕ",
  "ΑΛΓΟΣ",
  "ΚΟΡΝΟ",
  "ΜΕΙΟΝ",
  "ΘΑΦΤΗ",
  "ΒΥΘΟΥ",
  "ΤΕΚΝΟ",
  "ΤΑΓΕΡ",
  "ΤΑΧΤΩ",
  "ΠΕΙΘΩ",
  "ΣΤΑΛΟ",
  "ΠΥΚΝΕ",
  "ΡΕΣΤΑ",
  "ΦΡΥΞΩ",
  "ΑΜΕΤΕ",
  "ΑΩΤΟΥ",
  "ΓΡΟΣΑ",
  "ΕΜΕΝΕ",
  "ΑΥΓΗΣ",
  "ΛΑΖΟΥ",
  "ΚΟΛΕΕ",
  "ΚΑΛΙΑ",
  "ΣΚΥΛΕ",
  "ΘΩΡΑΞ",
  "ΒΑΡΩΝ",
  "ΠΕΝΣΑ",
  "ΘΗΤΕΣ",
  "ΣΚΑΡΙ",
  "ΑΧΙΝΕ",
  "ΕΡΙΣΑ",
  "ΚΛΙΚΑ",
  "ΤΖΙΡΟ",
  "ΞΕΒΓΑ",
  "ΙΣΘΜΕ",
  "ΕΡΕΑΝ",
  "ΞΥΠΝΗ",
  "ΣΦΑΞΕ",
  "ΠΟΡΘΩ",
  "ΣΟΦΟΣ",
  "ΠΕΝΩΝ",
  "ΦΡΥΓΕ",
  "ΧΤΕΝΑ",
  "ΦΑΡΜΑ",
  "ΑΓΝΟΣ",
  "ΕΨΑΛΑ",
  "ΕΤΑΖΩ",
  "ΨΥΞΗΣ",
  "ΧΥΤΟΣ",
  "ΙΣΤΩΝ",
  "ΔΑΔΕΣ",
  "ΤΡΥΦΩ",
  "ΛΟΥΦΕ",
  "ΟΡΑΝΕ",
  "ΦΥΛΟΝ",
  "ΓΥΠΕΣ",
  "ΑΜΩΜΑ",
  "ΛΙΝΕΣ",
  "ΚΑΔΟΥ",
  "ΜΠΑΛΟ",
  "ΡΥΠΩΝ",
  "ΚΟΦΤΗ",
  "ΦΥΓΗΣ",
  "ΖΟΥΝΕ",
  "ΤΜΗΤΟ",
  "ΣΒΗΝΩ",
  "ΒΙΩΜΑ",
  "ΠΙΕΝΑ",
  "ΙΚΑΝΟ",
  "ΔΟΤΩΝ",
  "ΑΛΩΣΩ",
  "ΣΩΣΤΑ",
  "ΔΕΚΤΟ",
  "ΡΑΔΙΟ",
  "ΑΡΠΩΝ",
  "ΕΣΥΡΕ",
  "ΠΑΡΤΕ",
  "ΟΚΝΟΥ",
  "ΟΓΚΩΝ",
  "ΣΚΑΣΗ",
  "ΧΡΗΣΗ",
  "ΦΙΛΙΑ",
  "ΣΤΕΨΕ",
  "ΑΡΜΩΝ",
  "ΑΝΗΚΑ",
  "ΚΙΣΣΕ",
  "ΑΤΥΧΩ",
  "ΠΗΞΙΣ",
  "ΞΥΛΟΥ",
  "ΞΟΥΡΑ",
  "ΣΗΚΟΙ",
  "ΕΠΙΚΕ",
  "ΧΡΙΖΕ",
  "ΙΔΙΕΣ",
  "ΛΙΑΣΩ",
  "ΑΥΓΟΥ",
  "ΒΑΖΩΝ",
  "ΤΑΧΟΣ",
  "ΞΟΑΝΑ",
  "ΑΥΛΕΣ",
  "ΑΜΕΣΟ",
  "ΛΩΛΟΥ",
  "ΓΗΙΝΑ",
  "ΜΟΥΝΙ",
  "ΣΧΙΖΩ",
  "ΠΟΣΟΝ",
  "ΔΟΤΕΣ",
  "ΚΟΠΕΣ",
  "ΞΙΦΩΝ",
  "ΧΟΥΝΕ",
  "ΧΩΛΗΣ",
  "ΣΑΠΡΗ",
  "ΞΕΡΟΥ",
  "ΣΙΓΜΑ",
  "ΣΕΜΝΟ",
  "ΖΕΡΒΗ",
  "ΘΕΑΤΟ",
  "ΡΗΤΟΣ",
  "ΠΑΧΩΝ",
  "ΑΓΝΟΩ",
  "ΤΥΡΑΣ",
  "ΝΥΧΤΑ",
  "ΛΙΜΟΣ",
  "ΓΑΜΟΙ",
  "ΤΜΗΤΑ",
  "ΧΙΠΗΣ",
  "ΟΜΗΡΕ",
  "ΝΩΡΙΣ",
  "ΡΕΥΕΙ",
  "ΣΤΗΤΟ",
  "ΞΑΝΕΙ",
  "ΥΑΛΟΣ",
  "ΟΛΒΙΑ",
  "ΠΟΥΛΙ",
  "ΗΔΟΝΗ",
  "ΤΡΑΓΕ",
  "ΛΙΝΟΝ",
  "ΓΚΑΖΙ",
  "ΦΥΚΙΑ",
  "ΤΣΕΡΙ",
  "ΑΚΟΩΝ",
  "ΑΗΧΟΥ",
  "ΛΙΤΟΣ",
  "ΟΧΘΟΣ",
  "ΩΘΗΣΩ",
  "ΜΑΜΠΟ",
  "ΝΟΥΝΕ",
  "ΛΩΡΟΥ",
  "ΚΥΡΑΣ",
  "ΦΙΛΟΙ",
  "ΠΟΚΟΣ",
  "ΑΙΩΡΑ",
  "ΑΦΟΒΗ",
  "ΡΟΔΩΝ",
  "ΣΙΤΟΥ",
  "ΕΔΩΔΑ",
  "ΑΛΑΛΑ",
  "ΦΙΛΩΝ",
  "ΤΡΥΓΑ",
  "ΥΓΡΟΝ",
  "ΡΗΧΗΣ",
  "ΚΟΦΕΣ",
  "ΣΚΥΛΙ",
  "ΔΟΜΟΣ",
  "ΧΩΡΩΝ",
  "ΜΙΑΝΑ",
  "ΝΑΔΙΡ",
  "ΑΣΩΤΗ",
  "ΡΑΧΕΣ",
  "ΕΨΑΛΕ",
  "ΡΗΣΗΣ",
  "ΛΥΚΟΥ",
  "ΑΔΑΗΣ",
  "ΕΡΙΣΕ",
  "ΣΥΜΒΩ",
  "ΖΩΙΚΑ",
  "ΧΑΨΤΕ",
  "ΑΓΡΙΟ",
  "ΕΝΔΕΗ",
  "ΚΟΜΕΣ",
  "ΣΜΙΞΕ",
  "ΜΕΧΡΙ",
  "ΕΝΟΡΩ",
  "ΚΗΛΗΣ",
  "ΞΕΦΤΑ",
  "ΒΟΕΡΕ",
  "ΜΠΑΖΩ",
  "ΡΟΠΗΣ",
  "ΜΥΟΥΝ",
  "ΣΑΜΑΝ",
  "ΑΟΠΛΗ",
  "ΠΡΟΒΑ",
  "ΜΠΡΙΚ",
  "ΕΝΩΝΑ",
  "ΜΠΟΧΑ",
  "ΝΩΔΟΙ",
  "ΜΥΩΜΑ",
  "ΑΡΠΑΣ",
  "ΠΟΝΩΝ",
  "ΚΩΜΩΝ",
  "ΔΕΡΝΕ",
  "ΘΡΟΦΗ",
  "ΙΩΣΙΣ",
  "ΛΗΝΩΝ",
  "ΟΜΑΛΟ",
  "ΡΕΤΡΟ",
  "ΕΙΛΕΟ",
  "ΘΑΜΒΟ",
  "ΕΞΠΕΡ",
  "ΜΕΛΑΣ",
  "ΓΙΔΕΣ",
  "ΕΓΓΥΣ",
  "ΑΔΟΞΑ",
  "ΓΕΡΤΟ",
  "ΔΟΞΗΣ",
  "ΟΞΙΝΗ",
  "ΖΑΒΟΣ",
  "ΤΑΠΑΣ",
  "ΚΑΦΡΕ",
  "ΤΡΙΒΕ",
  "ΜΟΝΟΥ",
  "ΤΗΞΕΙ",
  "ΨΗΛΟΥ",
  "ΚΑΒΟΣ",
  "ΥΛΙΚΕ",
  "ΠΑΝΑΣ",
  "ΛΟΥΖΩ",
  "ΦΕΛΛΕ",
  "ΕΛΥΣΑ",
  "ΑΔΟΞΕ",
  "ΘΑΜΝΟ",
  "ΕΧΟΥΝ",
  "ΠΛΑΤΟ",
  "ΣΚΕΥΗ",
  "ΡΙΜΑΣ",
  "ΑΚΑΚΕ",
  "ΘΕΑΤΑ",
  "ΚΑΛΕΣ",
  "ΣΕΜΝΗ",
  "ΟΔΕΥΕ",
  "ΑΧΟΛΟ",
  "ΠΑΧΝΗ",
  "ΒΡΑΚΙ",
  "ΑΤΙΜΕ",
  "ΡΥΑΚΙ",
  "ΓΚΕΛΑ",
  "ΔΗΛΟΙ",
  "ΣΠΑΜΕ",
  "ΙΠΠΩΝ",
  "ΜΑΣΑΖ",
  "ΛΥΔΙΕ",
  "ΙΔΙΩΣ",
  "ΚΑΤΣΕ",
  "ΠΑΣΤΕ",
  "ΒΙΑΟΙ",
  "ΣΕΠΤΕ",
  "ΚΟΛΑΝ",
  "ΑΠΑΣΑ",
  "ΓΟΦΟΥ",
  "ΓΔΥΘΩ",
  "ΑΘΥΜΗ",
  "ΜΙΣΟΙ",
  "ΑΙΤΟΥ",
  "ΚΡΙΝΟ",
  "ΟΞΙΚΟ",
  "ΦΟΥΜΟ",
  "ΚΟΡΜΟ",
  "ΚΟΙΝΗ",
  "ΦΟΒΙΑ",
  "ΧΩΛΟΥ",
  "ΦΗΓΩΝ",
  "ΕΝΑΟΣ",
  "ΡΗΣΙΣ",
  "ΕΝΔΥΩ",
  "ΒΡΑΔΥ",
  "ΡΕΠΙΟ",
  "ΠΙΟΜΑ",
  "ΣΑΠΡΟ",
  "ΤΥΡΙΑ",
  "ΒΙΤΡΟ",
  "ΜΕΝΩΝ",
  "ΖΗΣΕΙ",
  "ΠΡΕΠΕ",
  "ΨΥΛΛΕ",
  "ΨΙΛΟΣ",
  "ΜΟΝΗΣ",
  "ΚΑΛΗΝ",
  "ΓΝΕΦΩ",
  "ΒΡΥΟΥ",
  "ΓΛΑΡΟ",
  "ΚΥΑΜΕ",
  "ΞΥΣΤΕ",
  "ΞΙΔΙΑ",
  "ΑΡΕΤΗ",
  "ΠΛΑΚΑ",
  "ΚΟΡΙΟ",
  "ΦΕΡΤΗ",
  "ΓΝΩΜΗ",
  "ΑΤΟΝΑ",
  "ΣΠΟΡΟ",
  "ΜΑΤΣΑ",
  "ΔΑΔΙΑ",
  "ΚΑΛΑΙ",
  "ΕΣΜΩΝ",
  "ΝΩΜΟΥ",
  "ΛΑΜΠΑ",
  "ΠΥΛΩΝ",
  "ΚΗΤΩΝ",
  "ΣΕΡΝΕ",
  "ΕΥΧΗΝ",
  "ΛΙΡΑΣ",
  "ΩΣΕΩΝ",
  "ΒΙΖΕΣ",
  "ΧΛΟΩΝ",
  "ΑΓΩΓΕ",
  "ΛΑΓΟΥ",
  "ΠΟΜΠΗ",
  "ΦΤΕΝΗ",
  "ΣΠΑΤΕ",
  "ΣΟΝΑΡ",
  "ΩΔΙΚΑ",
  "ΑΨΙΩΝ",
  "ΑΛΕΑΣ",
  "ΑΓΩΝΑ",
  "ΖΩΙΚΗ",
  "ΣΤΡΑΣ",
  "ΑΞΙΣΑ",
  "ΛΗΓΟΝ",
  "ΑΛΑΝΗ",
  "ΕΨΗΣΑ",
  "ΤΙΡΑΖ",
  "ΑΥΓΩΝ",
  "ΑΛΣΩΝ",
  "ΑΛΑΛΗ",
  "ΓΚΑΒΑ",
  "ΝΩΘΡΗ",
  "ΑΔΙΚΗ",
  "ΖΗΤΑΣ",
  "ΞΕΝΙΕ",
  "ΗΧΗΡΕ",
  "ΣΕΙΧΗ",
  "ΠΑΤΑΣ",
  "ΕΡΜΙΑ",
  "ΚΕΛΗΣ",
  "ΧΑΧΑΣ",
  "ΜΗΛΙΑ",
  "ΦΡΙΚΗ",
  "ΓΡΙΠΗ",
  "ΜΑΘΟΙ",
  "ΡΙΨΙΣ",
  "ΠΙΑΣΕ",
  "ΛΗΜΜΑ",
  "ΕΝΟΧΗ",
  "ΜΑΚΡΕ",
  "ΠΤΗΝΑ",
  "ΑΒΡΟΥ",
  "ΕΠΙΚΗ",
  "ΔΑΚΝΩ",
  "ΔΕΡΝΩ",
  "ΔΗΜΟΙ",
  "ΒΙΑΣΗ",
  "ΧΑΜΟΥ",
  "ΖΗΛΙΑ",
  "ΧΑΣΕΣ",
  "ΕΖΩΣΑ",
  "ΑΧΕΡΑ",
  "ΣΤΑΞΩ",
  "ΑΦΗΝΩ",
  "ΤΕΙΟΥ",
  "ΞΙΦΙΕ",
  "ΕΝΩΣΑ",
  "ΒΑΘΟΣ",
  "ΝΟΤΟΝ",
  "ΠΩΓΩΝ",
  "ΗΓΕΤΗ",
  "ΘΩΚΩΝ",
  "ΧΑΣΚΕ",
  "ΛΙΜΑΣ",
  "ΑΨΙΟΙ",
  "ΟΥΡΩΝ",
  "ΨΕΙΡΑ",
  "ΜΑΝΩΝ",
  "ΕΚΑΨΕ",
  "ΚΛΙΣΗ",
  "ΕΒΗΞΑ",
  "ΦΡΑΖΩ",
  "ΦΩΤΙΑ",
  "ΝΤΑΜΑ",
  "ΤΗΞΤΕ",
  "ΚΕΝΟΙ",
  "ΓΡΑΦΩ",
  "ΣΙΩΠΑ",
  "ΑΓΗΜΑ",
  "ΑΡΔΗΝ",
  "ΤΡΩΜΕ",
  "ΟΧΕΥΩ",
  "ΣΤΑΧΥ",
  "ΠΟΘΕΝ",
  "ΚΗΡΟΥ",
  "ΕΔΩΚΕ",
  "ΠΑΧΙΑ",
  "ΠΛΩΤΕ",
  "ΩΔΙΝΕ",
  "ΑΛΕΣΗ",
  "ΣΠΑΖΩ",
  "ΧΗΡΕΣ",
  "ΜΠΥΡΑ",
  "ΠΛΙΣΕ",
  "ΕΔΙΝΕ",
  "ΑΛΚΗΝ",
  "ΖΩΝΗΣ",
  "ΧΑΣΕΙ",
  "ΤΑΦΕΣ",
  "ΕΘΝΙΚ",
  "ΚΛΩΘΩ",
  "ΡΟΥΦΩ",
  "ΤΑΧΥΣ",
  "ΟΡΔΩΝ",
  "ΑΧΘΟΣ",
  "ΒΕΣΠΑ",
  "ΠΛΗΞΩ",
  "ΑΝΙΨΙ",
  "ΣΕΡΤΗ",
  "ΠΛΥΜΑ",
  "ΝΙΚΕΛ",
  "ΒΑΤΑΣ",
  "ΛΕΞΙΝ",
  "ΚΑΔΩΝ",
  "ΔΙΑΒΑ",
  "ΓΥΠΑΣ",
  "ΕΛΕΓΑ",
  "ΚΥΛΙΩ",
  "ΚΩΛΟΥ",
  "ΞΥΠΝΕ",
  "ΧΩΡΑΩ",
  "ΜΥΑΛΟ",
  "ΦΤΗΝΗ",
  "ΦΥΣΙΣ",
  "ΦΟΛΩΝ",
  "ΤΥΦΟΙ",
  "ΟΧΛΟΙ",
  "ΒΑΡΟΣ",
  "ΚΟΣΤΗ",
  "ΞΕΣΠΑ",
  "ΠΟΠΟΣ",
  "ΤΡΥΠΑ",
  "ΡΟΝΤΟ",
  "ΔΙΧΩΣ",
  "ΒΓΑΖΕ",
  "ΖΕΨΕΙ",
  "ΥΒΡΙΣ",
  "ΥΠΝΟΝ",
  "ΑΟΠΛΟ",
  "ΙΧΝΩΝ",
  "ΜΑΣΚΕ",
  "ΑΡΣΙΣ",
  "ΜΥΗΣΕ",
  "ΧΑΝΟΥ",
  "ΔΙΝΕΙ",
  "ΧΟΡΟΥ",
  "ΦΕΞΤΕ",
  "ΧΕΡΣΕ",
  "ΚΟΜΒΕ",
  "ΙΧΝΟΣ",
  "ΔΟΤΟΙ",
  "ΒΙΩΝΕ",
  "ΑΝΟΧΗ",
  "ΡΙΓΑΣ",
  "ΠΑΙΞΩ",
  "ΑΔΡΕΣ",
  "ΧΡΩΜΑ",
  "ΛΕΡΗΣ",
  "ΠΑΡΕΑ",
  "ΦΥΡΩΝ",
  "ΔΙΤΤΕ",
  "ΑΤΟΝΟ",
  "ΚΙΧΛΗ",
  "ΣΥΧΝΗ",
  "ΕΛΩΔΗ",
  "ΑΦΑΛΟ",
  "ΕΛΥΝΑ",
  "ΤΑΡΤΑ",
  "ΘΑΒΕΙ",
  "ΠΡΑΩΝ",
  "ΝΥΓΜΑ",
  "ΦΑΙΕΣ",
  "ΠΥΡΡΕ",
  "ΚΕΚΕΣ",
  "ΘΥΡΣΟ",
  "ΝΕΑΡΗ",
  "ΑΨΟΓΟ",
  "ΕΘΑΒΕ",
  "ΧΙΑΣΑ",
  "ΧΟΥΝΙ",
  "ΤΙΜΙΟ",
  "ΧΡΗΜΑ",
  "ΗΠΙΩΝ",
  "ΜΙΣΩΝ",
  "ΚΟΡΙΕ",
  "ΕΞΑΨΗ",
  "ΓΔΥΤΗ",
  "ΨΗΛΟΙ",
  "ΟΡΘΟΙ",
  "ΒΙΩΝΩ",
  "ΑΝΑΜΑ",
  "ΕΧΡΙΕ",
  "ΕΣΟΔΟ",
  "ΝΟΤΑΣ",
  "ΤΡΕΛΑ",
  "ΗΘΙΚΑ",
  "ΤΥΦΟΣ",
  "ΟΑΣΙΣ",
  "ΣΑΜΠΑ",
  "ΑΒΟΛΑ",
  "ΑΛΥΠΗ",
  "ΧΡΟΙΑ",
  "ΑΝΑΒΑ",
  "ΠΥΡΩΝ",
  "ΦΕΡΩΝ",
  "ΞΑΠΛΑ",
  "ΛΑΣΙΑ",
  "ΑΙΘΗΡ",
  "ΟΥΣΗΣ",
  "ΤΑΥΡΟ",
  "ΙΣΤΙΟ",
  "ΧΑΣΚΩ",
  "ΕΠΛΕΑ",
  "ΞΑΝΘΟ",
  "ΘΥΡΣΕ",
  "ΖΕΣΗΣ",
  "ΕΛΞΕΙ",
  "ΤΡΑΒΑ",
  "ΕΧΘΡΕ",
  "ΜΩΡΙΑ",
  "ΟΡΔΕΣ",
  "ΣΒΩΛΟ",
  "ΖΩΔΙΑ",
  "ΜΑΓΙΟ",
  "ΑΠΤΟΙ",
  "ΠΑΣΗΣ",
  "ΣΜΗΝΗ",
  "ΕΧΡΙΑ",
  "ΝΕΥΜΑ",
  "ΡΟΖΟΙ",
  "ΜΠΗΖΕ",
  "ΨΗΣΤΗ",
  "ΑΨΙΛΗ",
  "ΓΡΥΛΟ",
  "ΠΥΞΩΝ",
  "ΑΛΑΤΑ",
  "ΤΑΝΥΑ",
  "ΡΟΖΟΣ",
  "ΓΡΙΚΩ",
  "ΑΥΤΟΣ",
  "ΛΙΓΗΣ",
  "ΑΙΓΛΗ",
  "ΠΛΟΥΝ",
  "ΜΗΔΕΝ",
  "ΔΕΣΕΙ",
  "ΙΔΕΕΣ",
  "ΜΟΡΙΑ",
  "ΩΜΙΚΟ",
  "ΑΓΧΩΝ",
  "ΦΡΥΝΕ",
  "ΡΟΦΟΣ",
  "ΠΡΕΖΑ",
  "ΚΟΣΜΩ",
  "ΚΑΛΛΗ",
  "ΚΡΥΩΝ",
  "ΚΡΑΤΑ",
  "ΝΗΣΟΙ",
  "ΓΑΤΟΙ",
  "ΠΥΓΜΗ",
  "ΓΡΑΦΗ",
  "ΛΥΤΡΑ",
  "ΒΕΡΕΣ",
  "ΨΑΛΛΕ",
  "ΠΡΟΒΩ",
  "ΑΜΟΡΕ",
  "ΜΑΥΡΑ",
  "ΑΝΤΡΑ",
  "ΕΦΕΞΕ",
  "ΠΕΦΤΩ",
  "ΓΛΥΦΕ",
  "ΔΑΥΤΕ",
  "ΨΥΧΕΙ",
  "ΑΡΕΣΕ",
  "ΦΑΒΩΝ",
  "ΚΩΜΕΣ",
  "ΓΛΥΦΩ",
  "ΟΠΩΔΗ",
  "ΑΡΙΟΥ",
  "ΩΩΔΩΝ",
  "ΛΕΙΨΗ",
  "ΘΕΡΜΑ",
  "ΚΡΙΜΑ",
  "ΑΜΒΛΥ",
  "ΛΕΡΟΙ",
  "ΣΤΟΜΑ",
  "ΗΡΕΜΗ",
  "ΓΝΕΘΩ",
  "ΦΑΥΛΗ",
  "ΕΚΡΑΝ",
  "ΚΑΖΜΑ",
  "ΤΖΙΡΕ",
  "ΑΛΛΟΣ",
  "ΣΑΛΗΣ",
  "ΦΗΜΕΣ",
  "ΧΛΟΜΕ",
  "ΝΟΟΥΝ",
  "ΦΡΟΝΩ",
  "ΣΑΚΙΑ",
  "ΙΓΓΛΑ",
  "ΨΟΦΩΝ",
  "ΓΟΜΟΣ",
  "ΜΗΝΟΣ",
  "ΡΗΣΟΣ",
  "ΕΚΑΜΑ",
  "ΣΚΥΒΕ",
  "ΑΦΗΣΕ",
  "ΥΒΡΗΣ",
  "ΚΛΩΒΕ",
  "ΟΡΜΟΣ",
  "ΘΩΡΑΩ",
  "ΙΕΡΩΝ",
  "ΑΚΟΝΩ",
  "ΖΥΓΙΑ",
  "ΖΑΒΟΥ",
  "ΔΑΣΥΣ",
  "ΒΩΒΕΣ",
  "ΧΝΑΡΙ",
  "ΑΤΘΙΣ",
  "ΕΧΤΡΑ",
  "ΚΥΡΤΕ",
  "ΣΧΑΖΕ",
  "ΚΡΥΦΗ",
  "ΗΣΚΙΕ",
  "ΑΥΤΟΥ",
  "ΓΥΦΤΕ",
  "ΑΖΥΜΟ",
  "ΑΚΜΗΝ",
  "ΜΟΥΣΙ",
  "ΟΓΔΟΗ",
  "ΠΛΗΡΩ",
  "ΒΙΚΟΥ",
  "ΤΡΕΠΩ",
  "ΔΑΡΜΟ",
  "ΜΑΚΑΣ",
  "ΑΖΥΜΑ",
  "ΑΝΘΩΝ",
  "ΠΥΡΑΣ",
  "ΦΩΛΙΑ",
  "ΚΕΝΩΝ",
  "ΚΑΙΓΕ",
  "ΧΡΑΜΙ",
  "ΜΕΛΕΙ",
  "ΕΖΩΝΕ",
  "ΩΑΡΙΑ",
  "ΟΡΜΕΣ",
  "ΙΕΡΕΑ",
  "ΣΤΥΨΩ",
  "ΛΑΣΙΟ",
  "ΨΥΞΙΣ",
  "ΤΡΩΤΑ",
  "ΣΚΟΠΕ",
  "ΣΘΕΝΗ",
  "ΣΚΑΙΕ",
  "ΥΠΑΤΕ",
  "ΛΙΩΘΩ",
  "ΦΑΥΛΟ",
  "ΧΑΩΔΗ",
  "ΑΝΗΘΑ",
  "ΣΩΣΤΟ",
  "ΚΑΥΣΟ",
  "ΣΤΕΨΗ",
  "ΙΝΑΤΙ",
  "ΓΑΡΩΝ",
  "ΟΓΔΟΟ",
  "ΑΝΑΣΑ",
  "ΟΙΚΟΝ",
  "ΟΣΙΩΝ",
  "ΚΟΚΑΣ",
  "ΖΩΔΙΟ",
  "ΦΑΡΙΑ",
  "ΤΕΧΝΗ",
  "ΑΤΑΦΟ",
  "ΧΑΙΡΕ",
  "ΕΛΙΑΣ",
  "ΒΕΛΟΥ",
  "ΜΟΤΕΡ",
  "ΡΑΦΩΝ",
  "ΘΙΓΕΙ",
  "ΠΙΚΡΑ",
  "ΦΘΗΝΕ",
  "ΦΙΝΑΣ",
  "ΠΡΑΞΩ",
  "ΠΟΘΟΣ",
  "ΚΑΙΣΙ",
  "ΕΥΗΧΕ",
  "ΚΟΝΙΑ",
  "ΑΛΣΟΣ",
  "ΜΙΚΡΗ",
  "ΠΙΑΣΩ",
  "ΔΙΠΛΗ",
  "ΑΣΕΒΩ",
  "ΟΠΛΟΝ",
  "ΔΑΣΜΕ",
  "ΒΑΡΔΑ",
  "ΚΟΠΙΣ",
  "ΤΥΦΛΑ",
  "ΛΥΣΤΕ",
  "ΣΤΕΚΑ",
  "ΜΙΣΘΕ",
  "ΕΨΑΛΗ",
  "ΜΟΧΛΕ",
  "ΓΚΕΤΟ",
  "ΠΛΑΘΩ",
  "ΨΑΘΙΑ",
  "ΔΟΜΟΙ",
  "ΕΟΡΤΗ",
  "ΚΑΨΑΝ",
  "ΣΑΡΑΙ",
  "ΘΝΗΤΟ",
  "ΔΕΙΛΑ",
  "ΕΔΕΝΕ",
  "ΝΥΦΕΣ",
  "ΑΦΗΣΑ",
  "ΓΝΩΣΗ",
  "ΤΣΙΤΙ",
  "ΜΟΔΑΣ",
  "ΕΘΕΤΕ",
  "ΛΙΒΕΣ",
  "ΣΚΟΡΕ",
  "ΟΡΜΑΣ",
  "ΚΕΡΝΩ",
  "ΕΙΡΜΟ",
  "ΕΧΥΣΕ",
  "ΖΕΣΤΟ",
  "ΓΥΡΟΥ",
  "ΣΙΝΙΕ",
  "ΧΥΜΑΩ",
  "ΧΡΟΝΟ",
  "ΓΙΓΑΣ",
  "ΟΓΔΟΕ",
  "ΩΡΙΕΣ",
  "ΔΥΣΙΣ",
  "ΣΙΡΟΣ",
  "ΣΕΚΤΑ",
  "ΙΑΙΝΩ",
  "ΘΕΤΟΥ",
  "ΖΥΘΟΥ",
  "ΞΗΡΟΙ",
  "ΒΛΑΚΑ",
  "ΑΦΑΓΑ",
  "ΗΛΘΟΝ",
  "ΚΥΦΕΣ",
  "ΓΥΜΝΕ",
  "ΧΡΙΕΙ",
  "ΕΤΗΚΕ",
  "ΣΑΠΡΕ",
  "ΟΧΙΕΣ",
  "ΑΨΗΦΩ",
  "ΣΩΣΤΕ",
  "ΠΟΡΟΥ",
  "ΧΑΣΙΑ",
  "ΚΛΗΤΟ",
  "ΟΝΕΙΟ",
  "ΑΛΩΣΑ",
  "ΕΝΙΚΑ",
  "ΑΔΕΤΑ",
  "ΔΟΛΟΥ",
  "ΑΚΜΩΝ",
  "ΚΥΦΟΣ",
  "ΒΑΤΩΝ",
  "ΟΙΚΩΝ",
  "ΠΙΣΤΩ",
  "ΧΑΜΑΜ",
  "ΧΑΝΝΕ",
  "ΜΑΙΩΝ",
  "ΑΦΟΡΕ",
  "ΙΟΝΤΑ",
  "ΑΤΜΩΝ",
  "ΣΚΕΠΟ",
  "ΛΑΚΑΣ",
  "ΟΠΛΟΥ",
  "ΚΟΜΜΕ",
  "ΚΕΣΕΣ",
  "ΦΙΛΗΣ",
  "ΚΑΔΙΑ",
  "ΒΙΚΟΣ",
  "ΟΧΤΟΙ",
  "ΟΜΙΛΕ",
  "ΦΙΡΜΑ",
  "ΞΟΑΝΟ",
  "ΣΚΑΛΟ",
  "ΦΑΝΟΙ",
  "ΑΥΡΙΟ",
  "ΔΕΣΜΑ",
  "ΣΧΙΣΕ",
  "ΛΥΘΕΙ",
  "ΟΛΟΥΣ",
  "ΨΗΦΩΝ",
  "ΘΩΚΟΥ",
  "ΝΤΑΒΑ",
  "ΜΙΣΤΑ",
  "ΣΟΦΟΝ",
  "ΤΕΜΝΕ",
  "ΟΧΕΤΟ",
  "ΓΥΡΝΑ",
  "ΑΓΛΑΑ",
  "ΙΣΩΜΑ",
  "ΧΟΥΝΗ",
  "ΕΨΥΞΑ",
  "ΜΥΙΚΕ",
  "ΔΑΣΟΥ",
  "ΠΕΨΗΣ",
  "ΧΑΖΟΣ",
  "ΑΖΩΤΑ",
  "ΞΥΡΟΥ",
  "ΕΤΗΚΑ",
  "ΤΡΕΝΑ",
  "ΑΦΘΑΣ",
  "ΒΛΕΨΗ",
  "ΑΟΚΝΕ",
  "ΑΡΓΕΙ",
  "ΑΚΡΙΑ",
  "ΠΟΩΔΗ",
  "ΧΗΝΩΝ",
  "ΜΩΡΩΝ",
  "ΓΑΜΙΑ",
  "ΑΡΕΙΟ",
  "ΚΑΣΤΑ",
  "ΡΕΒΕΡ",
  "ΑΘΛΟΣ",
  "ΠΑΨΤΕ",
  "ΛΥΣΕΙ",
  "ΞΥΣΤΗ",
  "ΦΙΛΟΝ",
  "ΜΗΡΟΣ",
  "ΜΑΓΚΑ",
  "ΣΠΑΕΙ",
  "ΤΑΖΕΙ",
  "ΖΑΦΤΙ",
  "ΑΜΑΧΑ",
  "ΑΦΥΩΣ",
  "ΤΥΡΒΗ",
  "ΠΟΙΟΣ",
  "ΕΛΑΤΑ",
  "ΨΑΧΝΑ",
  "ΑΚΡΟΝ",
  "ΚΟΡΦΕ",
  "ΟΡΝΙΟ",
  "ΡΙΓΩΝ",
  "ΠΟΡΤΑ",
  "ΣΕΛΑΣ",
  "ΕΘΕΣΑ",
  "ΓΟΠΑΣ",
  "ΑΗΧΩΝ",
  "ΜΠΑΡΑ",
  "ΑΘΡΟΑ",
  "ΑΣΤΡΑ",
  "ΕΥΘΕΑ",
  "ΦΤΕΡΑ",
  "ΤΑΠΙΑ",
  "ΛΑΠΑΣ",
  "ΛΑΛΟΣ",
  "ΔΑΚΟΥ",
  "ΑΓΩΓΙ",
  "ΠΟΥΜΑ",
  "ΞΙΝΗΣ",
  "ΠΑΙΣΩ",
  "ΚΛΑΣΕ",
  "ΑΛΙΚΗ",
  "ΟΥΖΟΥ",
  "ΦΕΓΓΕ",
  "ΕΑΥΤΗ",
  "ΠΑΤΟΙ",
  "ΑΛΩΘΩ",
  "ΡΗΧΙΑ",
  "ΝΗΠΙΑ",
  "ΔΟΡΩΝ",
  "ΤΑΦΟΣ",
  "ΕΛΕΩΝ",
  "ΓΑΜΟΣ",
  "ΠΙΣΣΑ",
  "ΜΥΓΩΝ",
  "ΣΙΜΟΙ",
  "ΒΟΜΒΑ",
  "ΕΧΘΡΟ",
  "ΕΤΕΘΗ",
  "ΚΕΝΤΑ",
  "ΚΑΔΡΑ",
  "ΕΡΙΩΝ",
  "ΑΥΤΗΣ",
  "ΟΜΙΛΩ",
  "ΤΥΨΕΙ",
  "ΠΙΣΤΕ",
  "ΓΚΑΒΕ",
  "ΓΛΥΨΩ",
  "ΕΧΟΜΕ",
  "ΦΤΥΝΩ",
  "ΤΟΣΟΣ",
  "ΝΕΤΩΝ",
  "ΙΑΜΒΕ",
  "ΤΡΑΧΥ",
  "ΑΓΡΟΝ",
  "ΕΨΥΞΕ",
  "ΕΝΕΣΗ",
  "ΛΗΨΗΣ",
  "ΕΝΔΟΝ",
  "ΣΦΑΛΑ",
  "ΨΑΛΜΕ",
  "ΑΜΑΘΟ",
  "ΠΑΣΧΩ",
  "ΗΜΟΥΝ",
  "ΧΤΙΖΕ",
  "ΠΗΧΤΟ",
  "ΔΙΩΚΑ",
  "ΒΟΥΕΣ",
  "ΥΠΗΓΑ",
  "ΚΟΥΦΕ",
  "ΕΝΑΓΕ",
  "ΤΟΣΟΝ",
  "ΕΛΕΕΙ",
  "ΟΧΛΟΣ",
  "ΠΗΓΗΣ",
  "ΑΚΟΥΣ",
  "ΨΗΤΕΣ",
  "ΣΟΥΡΕ",
  "ΚΛΩΣΑ",
  "ΓΥΝΗΣ",
  "ΗΓΑΓΑ",
  "ΣΚΙΣΕ",
  "ΛΕΙΠΩ",
  "ΨΟΦΙΑ",
  "ΔΙΗΚΩ",
  "ΤΗΚΕΙ",
  "ΓΗΡΑΣ",
  "ΡΟΙΑΣ",
  "ΣΟΜΦΕ",
  "ΟΡΟΦΗ",
  "ΒΡΑΧΥ",
  "ΓΥΡΕΣ",
  "ΡΟΠΕΣ",
  "ΦΤΕΡΟ",
  "ΣΟΛΕΣ",
  "ΧΑΝΕΙ",
  "ΑΠΩΣΗ",
  "ΕΥΚΗΣ",
  "ΒΙΑΖΕ",
  "ΕΘΙΣΩ",
  "ΟΜΟΙΟ",
  "ΤΖΑΚΙ",
  "ΙΞΩΔΗ",
  "ΛΑΤΕΞ",
  "ΠΕΥΚΑ",
  "ΒΥΣΜΑ",
  "ΣΧΟΛΩ",
  "ΑΔΡΩΣ",
  "ΠΡΗΝΗ",
  "ΟΒΟΛΑ",
  "ΒΑΛΘΩ",
  "ΝΕΑΡΑ",
  "ΝΟΕΡΟ",
  "ΚΕΝΟΝ",
  "ΣΤΥΦΕ",
  "ΚΥΗΣΗ",
  "ΠΡΥΜΗ",
  "ΑΨΗΦΑ",
  "ΝΟΘΟΣ",
  "ΩΧΡΟΥ",
  "ΑΚΑΤΟ",
  "ΤΙΛΙΑ",
  "ΠΑΥΣΩ",
  "ΗΜΕΡΑ",
  "ΟΠΛΕΣ",
  "ΠΑΤΕΙ",
  "ΤΑΡΣΕ",
  "ΠΥΡΗΣ",
  "ΕΞΩΜΗ",
  "ΔΥΑΔΑ",
  "ΟΙΝΩΝ",
  "ΚΑΘΩΣ",
  "ΜΑΜΟΥ",
  "ΜΙΤΩΝ",
  "ΛΙΜΗΝ",
  "ΔΥΣΕΙ",
  "ΠΤΩΧΑ",
  "ΟΡΘΟΥ",
  "ΣΗΨΙΣ",
  "ΣΤΕΝΑ",
  "ΔΥΣΗΣ",
  "ΝΙΠΤΩ",
  "ΑΔΟΞΗ",
  "ΚΑΝΝΗ",
  "ΜΥΕΙΣ",
  "ΟΥΔΕΝ",
  "ΒΥΘΟΣ",
  "ΑΡΕΝΑ",
  "ΣΙΑΛΕ",
  "ΤΕΙΟΝ",
  "ΑΡΤΟΝ",
  "ΕΝΕΕΣ",
  "ΙΕΡΕΣ",
  "ΔΟΣΗΣ",
  "ΘΡΑΚΑ",
  "ΑΗΧΗΣ",
  "ΑΞΙΩΣ",
  "ΤΑΥΡΙ",
  "ΛΟΞΙΑ",
  "ΑΨΙΕΣ",
  "ΑΧΡΟΑ",
  "ΠΕΛΑΟ",
  "ΜΙΑΟΥ",
  "ΝΟΝΑΣ",
  "ΤΣΙΤΑ",
  "ΓΛΥΦΟ",
  "ΕΠΗΞΑ",
  "ΟΚΝΟΙ",
  "ΤΑΣΙΑ",
  "ΥΨΩΣΗ",
  "ΣΟΦΩΝ",
  "ΑΣΤΕΙ",
  "ΡΕΥΜΑ",
  "ΝΤΟΡΕ",
  "ΡΟΥΠΙ",
  "ΓΕΛΙΟ",
  "ΚΙΛΩΝ",
  "ΝΙΨΟΥ",
  "ΖΩΣΑΣ",
  "ΠΟΙΑΝ",
  "ΚΑΛΥΞ",
  "ΤΑΔΕΣ",
  "ΝΑΥΤΗ",
  "ΚΛΑΜΑ",
  "ΕΡΠΙΣ",
  "ΝΟΤΟΥ",
  "ΣΤΟΚΕ",
  "ΠΑΡΜΕ",
  "ΑΔΕΤΗ",
  "ΧΟΥΑΝ",
  "ΖΕΥΞΗ",
  "ΟΛΚΩΝ",
  "ΩΘΗΣΑ",
  "ΡΥΘΜΟ",
  "ΡΙΧΤΑ",
  "ΕΨΥΧΑ",
  "ΠΟΛΥΝ",
  "ΕΞΕΩΣ",
  "ΠΑΛΑΙ",
  "ΕΣΜΟΙ",
  "ΕΔΩΚΑ",
  "ΠΙΟΥΝ",
  "ΩΡΥΓΗ",
  "ΡΙΝΤΟ",
  "ΑΔΕΤΕ",
  "ΜΠΙΝΕ",
  "ΠΟΛΦΟ",
  "ΝΙΨΙΣ",
  "ΠΡΑΟΥ",
  "ΤΕΛΕΙ",
  "ΚΑΥΤΑ",
  "ΒΑΣΙΝ",
  "ΩΘΗΣΗ",
  "ΕΚΤΟΥ",
  "ΤΣΙΝΩ",
  "ΡΙΚΝΕ",
  "ΝΤΥΣΕ",
  "ΣΦΑΞΩ",
  "ΠΛΗΓΗ",
  "ΑΦΡΟΥ",
  "ΑΤΙΜΗ",
  "ΦΟΡΕΑ",
  "ΣΜΙΞΩ",
  "ΕΧΕΖΕ",
  "ΑΣΙΤΗ",
  "ΣΤΥΛΕ",
  "ΧΥΤΡΑ",
  "ΑΦΟΒΟ",
  "ΠΕΖΩΝ",
  "ΑΤΕΝΗ",
  "ΛΟΙΜΟ",
  "ΑΠΑΓΕ",
  "ΡΕΚΟΡ",
  "ΝΩΔΗΣ",
  "ΔΑΚΩΝ",
  "ΔΙΠΛΟ",
  "ΤΡΙΞΕ",
  "ΑΔΙΚΟ",
  "ΓΡΥΖΩ",
  "ΠΕΙΣΕ",
  "ΠΕΤΑΩ",
  "ΔΑΣΕΑ",
  "ΦΥΓΑΣ",
  "ΜΑΘΩΝ",
  "ΑΠΑΤΑ",
  "ΟΚΝΙΑ",
  "ΖΕΥΓΗ",
  "ΨΟΦΑΝ",
  "ΕΛΑΦΙ",
  "ΣΚΕΠΗ",
  "ΚΗΠΟΣ",
  "ΛΙΤΡΟ",
  "ΠΑΠΑΣ",
  "ΚΟΠΠΑ",
  "ΜΥΛΟΙ",
  "ΓΕΛΑΩ",
  "ΣΧΙΣΩ",
  "ΕΙΛΕΕ",
  "ΔΕΙΛΟ",
  "ΣΚΑΡΕ",
  "ΙΝΤΣΑ",
  "ΕΞΑΡΙ",
  "ΟΡΘΗΣ",
  "ΜΥΛΕΣ",
  "ΠΙΝΕΙ",
  "ΠΟΛΥΣ",
  "ΦΡΕΖΑ",
  "ΑΔΕΙΕ",
  "ΞΕΝΗΣ",
  "ΜΕΝΟΣ",
  "ΛΕΙΟΥ",
  "ΑΜΝΟΙ",
  "ΨΩΛΕΣ",
  "ΑΞΙΟΙ",
  "ΜΥΧΙΟ",
  "ΠΟΔΑΣ",
  "ΑΡΜΟΥ",
  "ΣΟΥΕΤ",
  "ΔΕΙΛΙ",
  "ΑΠΟΧΗ",
  "ΛΗΘΗΣ",
  "ΦΘΑΣΕ",
  "ΙΝΩΔΗ",
  "ΡΟΥΓΑ",
  "ΗΡΩΟΥ",
  "ΚΛΑΡΙ",
  "ΧΑΡΠΟ",
  "ΗΡΩΕΣ",
  "ΕΨΕΝΕ",
  "ΑΨΙΛΑ",
  "ΠΗΔΟΣ",
  "ΑΦΩΤΟ",
  "ΠΛΥΣΗ",
  "ΡΙΑΛΙ",
  "ΟΜΑΔΑ",
  "ΦΘΗΝΑ",
  "ΛΙΛΙΑ",
  "ΚΑΚΑΟ",
  "ΣΒΟΛΟ",
  "ΛΗΞΗΣ",
  "ΙΠΠΟΥ",
  "ΟΧΥΡΗ",
  "ΛΙΒΩΝ",
  "ΤΜΗΤΗ",
  "ΑΖΗΛΑ",
  "ΦΟΝΟΙ",
  "ΑΠΕΞΩ",
  "ΑΡΤΟΥ",
  "ΑΕΝΑΕ",
  "ΑΣΟΦΑ",
  "ΚΗΡΩΝ",
  "ΜΑΣΑΣ",
  "ΕΞΟΧΑ",
  "ΡΑΜΦΙ",
  "ΡΟΔΕΣ",
  "ΑΤΟΥΣ",
  "ΤΑΞΑΝ",
  "ΝΥΣΤΑ",
  "ΦΕΤΕΣ",
  "ΨΙΛΩΝ",
  "ΤΖΟΓΕ",
  "ΔΟΝΤΙ",
  "ΔΟΜΕΣ",
  "ΤΥΛΟΥ",
  "ΔΟΘΕΝ",
  "ΥΓΡΟΙ",
  "ΜΟΣΧΕ",
  "ΟΓΚΟΥ",
  "ΧΤΙΣΗ",
  "ΡΑΣΩΝ",
  "ΦΑΙΩΝ",
  "ΑΠΑΧΟ",
  "ΜΙΣΕΙ",
  "ΑΣΣΟΣ",
  "ΔΕΤΗΣ",
  "ΜΑΥΡΟ",
  "ΡΗΤΟΝ",
  "ΕΞΟΔΑ",
  "ΑΣΒΩΝ",
  "ΑΧΝΟΙ",
  "ΕΔΥΑΝ",
  "ΑΝΤΛΩ",
  "ΑΓΑΠΗ",
  "ΟΥΛΟΙ",
  "ΚΟΜΠΕ",
  "ΑΡΑΤΑ",
  "ΛΕΞΗΣ",
  "ΑΝΕΒΑ",
  "ΞΕΝΩΝ",
  "ΧΛΩΜΑ",
  "ΖΕΒΡΑ",
  "ΜΑΥΡΗ",
  "ΦΥΛΗΣ",
  "ΜΑΤΣΟ",
  "ΛΑΜΠΩ",
  "ΔΟΛΙΕ",
  "ΛΙΜΠΑ",
  "ΠΟΣΟΥ",
  "ΛΙΘΟΝ",
  "ΑΘΕΛΕ",
  "ΕΧΕΣΕ",
  "ΚΙΝΑΣ",
  "ΦΙΝΟΣ",
  "ΑΚΟΥΝ",
  "ΒΕΛΩΝ",
  "ΚΑΛΗΣ",
  "ΠΕΤΑΣ",
  "ΟΔΥΝΗ",
  "ΜΑΠΕΣ",
  "ΕΝΟΧΑ",
  "ΟΥΔΟΣ",
  "ΠΟΝΟΥ",
  "ΑΦΡΟΝ",
  "ΚΛΑΔΟ",
  "ΦΟΥΡΟ",
  "ΑΣΟΥΣ",
  "ΟΝΙΚΗ",
  "ΠΟΛΛΗ",
  "ΧΩΛΟΙ",
  "ΑΛΓΩΝ",
  "ΠΟΣΗΣ",
  "ΧΟΚΕΙ",
  "ΑΠΟΡΩ",
  "ΜΠΗΚΕ",
  "ΛΟΦΙΑ",
  "ΑΝΗΒΗ",
  "ΕΝΑΤΑ",
  "ΔΙΑΚΟ",
  "ΣΚΕΤΗ",
  "ΠΕΛΤΕ",
  "ΥΓΡΗΣ",
  "ΑΧΝΕΣ",
  "ΣΙΡΟΥ",
  "ΤΟΠΙΑ",
  "ΜΙΑΝΕ",
  "ΚΑΥΛΑ",
  "ΡΑΜΦΗ",
  "ΠΕΝΕΣ",
  "ΚΛΑΔΙ",
  "ΑΠΑΧΑ",
  "ΑΔΩΡΗ",
  "ΟΡΥΞΗ",
  "ΥΛΙΚΑ",
  "ΘΥΡΩΝ",
  "ΚΡΥΟΣ",
  "ΤΡΕΧΕ",
  "ΚΡΥΒΕ",
  "ΤΡΑΚΟ",
  "ΓΥΠΩΝ",
  "ΚΟΝΤΗ",
  "ΡΟΙΕΣ",
  "ΔΡΥΜΟ",
  "ΟΒΕΛΕ",
  "ΠΙΕΣΩ",
  "ΜΥΗΣΗ",
  "ΩΧΡΙΩ",
  "ΚΑΝΘΕ",
  "ΔΥΤΕΣ",
  "ΚΡΟΚΗ",
  "ΘΕΙΟΝ",
  "ΤΡΑΚΑ",
  "ΑΜΕΣΕ",
  "ΡΩΓΑΣ",
  "ΤΕΜΝΩ",
  "ΨΥΧΡΑ",
  "ΘΕΡΩΝ",
  "ΚΥΤΟΣ",
  "ΘΕΤΕΣ",
  "ΕΙΧΑΝ",
  "ΧΑΥΝΗ",
  "ΑΝΑΒΩ",
  "ΣΤΕΚΕ",
  "ΛΩΛΩΝ",
  "ΧΥΛΟΣ",
  "ΓΥΑΛΙ",
  "ΠΕΥΚΙ",
  "ΠΛΑΒΑ",
  "ΖΩΙΚΕ",
  "ΑΙΤΟΙ",
  "ΣΚΙΖΑ",
  "ΠΑΤΣΙ",
  "ΑΩΡΩΣ",
  "ΛΟΥΣΟ",
  "ΖΟΥΜΕ",
  "ΓΔΥΤΑ",
  "ΕΡΑΨΕ",
  "ΠΙΣΤΗ",
  "ΠΕΡΑΣ",
  "ΣΤΥΦΑ",
  "ΑΒΙΕΣ",
  "ΔΕΧΤΕ",
  "ΠΗΛΟΣ",
  "ΕΠΗΡΑ",
  "ΛΙΩΝΕ",
  "ΦΤΑΝΕ",
  "ΣΙΓΗΝ",
  "ΠΙΡΩΝ",
  "ΒΑΡΕΑ",
  "ΤΥΡΟΥ",
  "ΞΕΡΕΙ",
  "ΓΡΥΠΕ",
  "ΕΙΣΘΕ",
  "ΧΟΙΡΟ",
  "ΣΙΩΠΗ",
  "ΠΙΡΟΙ",
  "ΞΙΓΚΙ",
  "ΜΑΣΚΑ",
  "ΠΑΝΤΑ",
  "ΣΙΑΛΟ",
  "ΔΟΡΑΣ",
  "ΕΚΤΕΣ",
  "ΜΥΘΟΥ",
  "ΜΗΝΥΕ",
  "ΚΟΡΑΣ",
  "ΣΑΓΜΑ",
  "ΤΑΞΟΥ",
  "ΑΣΤΟΥ",
  "ΙΑΝΑΝ",
  "ΛΟΙΜΕ",
  "ΜΥΘΩΝ",
  "ΕΥΡΩΝ",
  "ΕΡΙΞΕ",
  "ΚΑΡΠΟ",
  "ΦΙΛΕΣ",
  "ΕΧΥΣΑ",
  "ΦΑΠΕΣ",
  "ΠΙΘΟΣ",
  "ΣΠΥΡΙ",
  "ΝΟΜΗΣ",
  "ΚΟΠΙΑ",
  "ΞΑΝΘΕ",
  "ΧΑΙΝΩ",
  "ΕΝΩΘΩ",
  "ΕΝΟΧΟ",
  "ΖΥΓΕΣ",
  "ΡΗΤΟΥ",
  "ΝΟΜΕΑ",
  "ΤΑΓΟΣ",
  "ΔΕΗΣΗ",
  "ΓΟΜΦΟ",
  "ΘΕΡΟΣ",
  "ΨΥΧΘΩ",
  "ΝΙΤΡΟ",
  "ΓΡΕΓΕ",
  "ΔΙΚΟΙ",
  "ΚΟΥΡΟ",
  "ΜΠΕΖΕ",
  "ΣΥΡΤΑ",
  "ΘΕΣΤΕ",
  "ΜΕΛΠΩ",
  "ΔΕΤΕΣ",
  "ΠΙΘΟΥ",
  "ΕΛΕΓΕ",
  "ΥΕΤΟΣ",
  "ΦΡΙΞΕ",
  "ΧΩΛΕΣ",
  "ΣΟΦΡΑ",
  "ΕΠΟΨΗ",
  "ΕΔΙΚΗ",
  "ΤΣΙΝΑ",
  "ΟΜΙΛΟ",
  "ΠΗΔΑΝ",
  "ΑΝΟΔΟ",
  "ΒΥΘΟΙ",
  "ΟΡΘΟΣ",
  "ΖΕΧΝΩ",
  "ΔΟΚΩΝ",
  "ΑΠΟΝΟ",
  "ΙΒΕΩΝ",
  "ΕΝΩΣΩ",
  "ΑΡΜΟΙ",
  "ΑΔΟΞΟ",
  "ΑΡΤΙΑ",
  "ΤΡΑΦΕ",
  "ΖΕΙΤΕ",
  "ΜΥΔΡΟ",
  "ΝΩΤΩΝ",
  "ΜΑΛΛΙ",
  "ΣΤΙΞΗ",
  "ΩΔΙΚΟ",
  "ΠΑΠΩΝ",
  "ΗΜΕΡΗ",
  "ΜΟΝΟΝ",
  "ΚΥΦΗΣ",
  "ΠΕΔΗΣ",
  "ΛΑΤΡΗ",
  "ΖΟΥΔΙ",
  "ΟΨΙΜΑ",
  "ΘΥΛΑΞ",
  "ΕΛΑΙΑ",
  "ΣΦΥΡΑ",
  "ΤΕΙΧΗ",
  "ΝΟΤΙΟ",
  "ΚΛΙΤΟ",
  "ΑΡΩΜΑ",
  "ΜΕΡΑΝ",
  "ΑΒΑΤΑ",
  "ΜΗΝΩΝ",
  "ΘΕΙΑΣ",
  "ΛΙΣΤΑ",
  "ΣΟΦΑΣ",
  "ΕΠΗΞΕ",
  "ΕΛΑΙΟ",
  "ΕΛΑΧΑ",
  "ΚΗΠΩΝ",
  "ΘΡΗΝΕ",
  "ΛΕΙΠΕ",
  "ΛΙΓΩΝ",
  "ΑΦΗΣΩ",
  "ΓΑΙΜΑ",
  "ΙΚΑΝΑ",
  "ΔΗΜΟΥ",
  "ΣΠΟΡΕ",
  "ΠΗΚΤΗ",
  "ΑΒΙΟΙ",
  "ΑΥΤΕΣ",
  "ΛΟΓΙΑ",
  "ΠΕΖΟΙ",
  "ΥΠΕΡΟ",
  "ΑΙΣΙΑ",
  "ΥΦΟΥΣ",
  "ΨΕΝΕΙ",
  "ΗΘΙΚΗ",
  "ΒΓΑΛΕ",
  "ΘΡΑΦΩ",
  "ΕΝΑΤΗ",
  "ΣΕΡΒΙ",
  "ΨΑΞΤΕ",
  "ΑΗΔΩΣ",
  "ΦΟΝΤΑ",
  "ΔΕΣΜΗ",
  "ΑΝΑΝΑ",
  "ΠΡΩΤΑ",
  "ΣΚΙΩΝ",
  "ΠΙΕΙΣ",
  "ΒΑΘΜΟ",
  "ΛΑΔΗΣ",
  "ΕΧΥΝΕ",
  "ΘΛΑΣΗ",
  "ΣΚΑΤΑ",
  "ΞΥΣΤΑ",
  "ΕΠΑΛΑ",
  "ΑΛΤΩΝ",
  "ΓΔΥΤΟ",
  "ΜΑΣΑΝ",
  "ΘΑΜΠΟ",
  "ΘΡΑΥΩ",
  "ΘΕΩΡΩ",
  "ΦΡΑΞΕ",
  "ΕΚΤΩΝ",
  "ΦΤΩΧΕ",
  "ΚΡΙΤΗ",
  "ΔΩΡΩΝ",
  "ΓΡΙΕΣ",
  "ΑΞΙΟΣ",
  "ΜΟΝΑΣ",
  "ΑΨΗΤΗ",
  "ΒΟΑΝΕ",
  "ΠΡΩΤΟ",
  "ΠΟΥΜΕ",
  "ΔΥΕΙΣ",
  "ΕΤΗΞΑ",
  "ΝΤΙΒΑ",
  "ΠΑΠΙΑ",
  "ΣΥΣΠΑ",
  "ΣΤΙΦΗ",
  "ΠΑΡΩΝ",
  "ΑΨΙΑΣ",
  "ΒΑΡΑΣ",
  "ΑΚΩΚΗ",
  "ΑΚΛΕΗ",
  "ΦΥΚΩΝ",
  "ΑΦΕΤΗ",
  "ΑΤΛΑΣ",
  "ΑΛΟΕΣ",
  "ΠΑΛΙΕ",
  "ΒΟΥΚΑ",
  "ΨΗΦΟΙ",
  "ΔΕΙΡΩ",
  "ΣΩΣΜΟ",
  "ΦΑΓΑΣ",
  "ΜΙΛΙΑ",
  "ΜΟΡΤΗ",
  "ΠΑΠΠΕ",
  "ΘΡΥΨΩ",
  "ΘΕΡΜΩ",
  "ΟΥΓΙΑ",
  "ΑΧΥΜΕ",
  "ΤΟΠΙΟ",
  "ΒΡΥΣΗ",
  "ΑΘΛΟΙ",
  "ΣΦΙΞΗ",
  "ΑΟΣΜΑ",
  "ΑΦΟΡΗ",
  "ΟΝΥΧΑ",
  "ΑΜΥΛΟ",
  "ΘΑΨΤΕ",
  "ΑΝΗΒΕ",
  "ΖΑΒΗΣ",
  "ΕΝΕΗΣ",
  "ΣΦΥΞΗ",
  "ΔΡΥΜΕ",
  "ΑΛΟΗΣ",
  "ΧΑΡΤΑ",
  "ΜΗΤΡΑ",
  "ΦΑΟΥΛ",
  "ΒΟΡΙΑ",
  "ΚΛΟΙΕ",
  "ΑΝΑΨΕ",
  "ΠΙΠΤΩ",
  "ΑΠΥΡΑ",
  "ΛΥΠΩΝ",
  "ΞΕΡΑΝ",
  "ΠΟΚΟΥ",
  "ΔΩΣΕΙ",
  "ΡΙΠΕΣ",
  "ΔΙΝΕΣ",
  "ΑΦΩΤΗ",
  "ΦΕΤΟΣ",
  "ΒΟΕΙΑ",
  "ΣΤΑΖΕ",
  "ΚΙΝΕΙ",
  "ΑΣΗΜΑ",
  "ΟΠΑΙΕ",
  "ΜΟΧΘΩ",
  "ΩΜΟΥΣ",
  "ΜΑΓΩΝ",
  "ΒΑΡΥΣ",
  "ΟΓΚΟΝ",
  "ΛΕΙΟΙ",
  "ΓΟΦΩΝ",
  "ΦΩΚΙΑ",
  "ΑΞΥΛΕ",
  "ΜΙΝΙΑ",
  "ΑΙΡΕΙ",
  "ΝΗΣΩΝ",
  "ΣΙΜΕΣ",
  "ΣΚΑΙΑ",
  "ΧΗΛΗΣ",
  "ΓΕΩΔΗ",
  "ΝΤΟΥΖ",
  "ΤΑΜΑΜ",
  "ΦΘΙΝΩ",
  "ΕΤΑΞΕ",
  "ΕΓΙΝΑ",
  "ΑΡΣΗΣ",
  "ΡΩΘΩΝ",
  "ΟΥΡΕΣ",
  "ΘΥΝΝΕ",
  "ΤΥΨΙΣ",
  "ΞΟΔΙΑ",
  "ΡΗΓΜΑ",
  "ΑΛΩΝΑ",
  "ΓΟΝΑΣ",
  "ΣΟΥΡΩ",
  "ΒΡΥΟΝ",
  "ΕΝΝΕΑ",
  "ΜΠΟΓΕ",
  "ΜΑΣΤΟ",
  "ΗΜΕΡΟ",
  "ΠΑΓΚΑ",
  "ΟΜΟΙΑ",
  "ΧΑΠΙΑ",
  "ΘΡΕΨΗ",
  "ΜΕΣΟΙ",
  "ΦΡΑΞΟ",
  "ΣΟΚΙΝ",
  "ΣΦΥΖΩ",
  "ΑΓΟΡΑ",
  "ΑΠΥΡΕ",
  "ΜΥΘΟΙ",
  "ΤΥΧΟΝ",
  "ΘΡΥΛΕ",
  "ΤΙΜΑΝ",
  "ΑΜΟΛΑ",
  "ΔΟΤΗΣ",
  "ΧΕΣΕΙ",
  "ΜΥΘΟΣ",
  "ΠΙΡΟΥ",
  "ΒΑΡΙΑ",
  "ΞΕΣΠΩ",
  "ΡΟΥΛΑ",
  "ΜΟΙΡΑ",
  "ΜΠΟΤΑ",
  "ΑΝΕΤΕ",
  "ΠΕΡΛΑ",
  "ΔΕΣΜΟ",
  "ΨΥΛΛΟ",
  "ΛΟΣΤΕ",
  "ΚΥΡΟΣ",
  "ΚΡΟΥΩ",
  "ΕΠΙΝΑ",
  "ΦΥΣΙΝ",
  "ΚΗΠΟΥ",
  "ΚΟΡΦΗ",
  "ΘΑΨΕΙ",
  "ΑΚΡΟΥ",
  "ΛΙΜΩΝ",
  "ΠΗΚΤΕ",
  "ΧΕΣΟΥ",
  "ΑΝΗΚΩ",
  "ΤΙΝΟΣ",
  "ΚΥΤΙΟ",
  "ΖΟΦΩΝ",
  "ΖΕΒΡΟ",
  "ΚΑΚΩΣ",
  "ΟΣΧΕΑ",
  "ΚΑΜΨΕ",
  "ΠΗΔΑΩ",
  "ΤΡΙΩΝ",
  "ΒΛΕΠΕ",
  "ΠΑΡΕΙ",
  "ΟΣΤΩΝ",
  "ΛΟΤΤΟ",
  "ΚΥΛΑΣ",
  "ΚΟΡΦΟ",
  "ΜΥΙΚΟ",
  "ΑΩΤΟΙ",
  "ΤΑΝΥΩ",
  "ΧΙΛΗΣ",
  "ΘΡΕΦΩ",
  "ΜΗΚΟΣ",
  "ΚΩΛΩΝ",
  "ΡΕΣΤΟ",
  "ΣΒΟΛΕ",
  "ΗΡΘΑΝ",
  "ΜΥΞΗΣ",
  "ΑΓΝΗΣ",
  "ΕΞΑΨΩ",
  "ΨΗΛΟΣ",
  "ΗΧΕΡΕ",
  "ΓΕΛΙΑ",
  "ΛΑΡΟΙ",
  "ΓΥΡΗΣ",
  "ΔΑΔΩΝ",
  "ΑΙΝΟΥ",
  "ΤΟΡΝΟ",
  "ΟΥΣΑΙ",
  "ΔΙΨΑΣ",
  "ΣΤΙΣΩ",
  "ΣΚΙΖΩ",
  "ΝΤΟΡΟ",
  "ΗΧΗΡΑ",
  "ΜΟΚΑΣ",
  "ΨΗΣΑΝ",
  "ΓΟΩΔΗ",
  "ΩΚΙΜΟ",
  "ΚΑΜΕΙ",
  "ΑΥΡΕΣ",
  "ΚΡΟΚΟ",
  "ΕΧΕΣΑ",
  "ΑΛΩΝΕ",
  "ΝΟΗΤΕ",
  "ΒΟΥΒΟ",
  "ΑΠΟΝΑ",
  "ΕΡΙΞΑ",
  "ΣΙΜΟΣ",
  "ΛΙΝΩΝ",
  "ΚΕΡΝΑ",
  "ΜΩΛΟΥ",
  "ΑΒΑΤΗ",
  "ΑΚΡΟΙ",
  "ΑΛΛΟΙ",
  "ΧΩΝΑΝ",
  "ΠΑΛΙΟ",
  "ΑΠΑΓΩ",
  "ΛΙΘΟΣ",
  "ΡΩΤΑΝ",
  "ΑΧΑΡΟ",
  "ΑΝΑΓΩ",
  "ΜΠΗΞΩ",
  "ΑΝΩΓΙ",
  "ΕΖΗΣΑ",
  "ΔΕΧΘΩ",
  "ΛΥΤΕΣ",
  "ΚΛΩΝΕ",
  "ΧΩΝΕΙ",
  "ΜΥΞΩΝ",
  "ΧΑΛΚΟ",
  "ΦΕΥΓΑ",
  "ΠΙΕΖΑ",
  "ΕΠΕΣΑ",
  "ΗΜΙΣΥ",
  "ΚΡΙΝΕ",
  "ΡΙΝΟΣ",
  "ΩΣΠΕΡ",
  "ΕΠΙΖΩ",
  "ΠΛΑΣΕ",
  "ΦΤΗΝΟ",
  "ΤΗΡΩΝ",
  "ΛΕΦΤΑ",
  "ΠΟΣΙΣ",
  "ΜΙΝΘΗ",
  "ΟΞΕΟΣ",
  "ΕΝΝΙΑ",
  "ΠΑΡΟΝ",
  "ΑΓΥΙΑ",
  "ΑΡΓΚΟ",
  "ΠΥΕΛΟ",
  "ΛΟΧΟΣ",
  "ΔΙΤΤΑ",
  "ΛΑΜΠΕ",
  "ΛΗΝΟΥ",
  "ΨΑΡΕΣ",
  "ΤΕΛΩΝ",
  "ΑΡΙΑΣ",
  "ΑΦΙΛΗ",
  "ΕΡΙΦΙ",
  "ΑΣΚΟΣ",
  "ΠΕΠΛΑ",
  "ΡΟΓΑΣ",
  "ΕΞΗΡΕ",
  "ΞΕΣΤΕ",
  "ΑΩΤΟΣ",
  "ΕΙΔΕΙ",
  "ΑΝΟΣΟ",
  "ΧΙΑΖΑ",
  "ΘΡΥΛΟ",
  "ΗΧΕΙΟ",
  "ΛΑΚΚΕ",
  "ΒΡΑΧΕ",
  "ΕΧΩΝΕ",
  "ΦΕΛΑΩ",
  "ΞΑΝΤΑ",
  "ΑΣΕΒΟ",
  "ΩΝΙΩΝ",
  "ΠΥΞΟΣ",
  "ΒΑΛΤΕ",
  "ΥΨΗΛΗ",
  "ΑΣΣΩΝ",
  "ΚΗΡΙΑ",
  "ΘΕΑΤΕ",
  "ΜΟΡΑΣ",
  "ΦΥΛΑΩ",
  "ΔΗΛΩΝ",
  "ΗΧΗΣΕ",
  "ΕΜΠΥΑ",
  "ΗΔΕΙΑ",
  "ΞΥΛΩΝ",
  "ΝΟΘΩΝ",
  "ΟΔΙΚΗ",
  "ΧΕΡΣΙ",
  "ΝΩΠΟΥ",
  "ΑΚΑΗΣ",
  "ΑΩΡΗΣ",
  "ΧΑΨΑΝ",
  "ΛΙΓΔΑ",
  "ΑΘΕΟΙ",
  "ΘΕΑΤΗ",
  "ΞΕΝΟΝ",
  "ΣΑΘΡΕ",
  "ΕΨΕΓΑ",
  "ΦΕΛΠΑ",
  "ΟΡΧΙΣ",
  "ΧΩΣΕΙ",
  "ΚΑΟΥΝ",
  "ΑΛΜΕΣ",
  "ΚΑΝΤΕ",
  "ΠΟΜΠΟ",
  "ΠΤΥΕΙ",
  "ΤΟΥΤΟ",
  "ΨΑΘΑΣ",
  "ΧΑΖΩΝ",
  "ΤΑΙΖΕ",
  "ΣΙΕΛΕ",
  "ΚΑΜΑΣ",
  "ΟΙΚΟΙ",
  "ΜΗΛΟΥ",
  "ΓΑΛΟΥ",
  "ΓΑΛΟΙ",
  "ΔΟΚΟΥ",
  "ΠΟΤΩΝ",
  "ΦΑΣΩΝ",
  "ΤΟΥΛΙ",
  "ΡΙΦΙΑ",
  "ΔΟΝΑΣ",
  "ΣΤΑΛΩ",
  "ΤΑΥΤΑ",
  "ΞΥΣΤΩ",
  "ΤΡΥΠΩ",
  "ΦΙΛΟΥ",
  "ΒΑΓΙΑ",
  "ΛΥΡΑΣ",
  "ΑΣΟΦΗ",
  "ΒΡΗΚΑ",
  "ΔΟΥΜΕ",
  "ΖΩΜΩΝ",
  "ΣΑΡΙΑ",
  "ΚΑΚΕΣ",
  "ΟΧΤΡΕ",
  "ΜΠΕΛΑ",
  "ΣΜΙΓΩ",
  "ΙΑΣΙΣ",
  "ΡΑΚΗΣ",
  "ΕΖΩΝΑ",
  "ΙΝΙΟΝ",
  "ΟΥΡΙΑ",
  "ΑΛΗΤΗ",
  "ΥΛΑΚΗ",
  "ΕΧΑΣΕ",
  "ΤΡΙΚΟ",
  "ΚΟΙΝΕ",
  "ΗΘΜΟΣ",
  "ΦΙΟΡΟ",
  "ΛΑΖΟΙ",
  "ΤΑΚΟΥ",
  "ΓΩΝΙΑ",
  "ΓΟΕΡΑ",
  "ΨΑΘΕΣ",
  "ΕΝΙΚΕ",
  "ΘΥΕΙΣ",
  "ΝΟΥΑΡ",
  "ΡΗΧΟΣ",
  "ΧΟΙΚΗ",
  "ΕΔΩΣΑ",
  "ΑΧΑΡΗ",
  "ΜΗΤΗΡ",
  "ΜΥΞΑΣ",
  "ΛΩΤΩΝ",
  "ΑΠΝΟΑ",
  "ΑΝΕΤΗ",
  "ΠΛΑΝΑ",
  "ΚΑΣΩΝ",
  "ΓΑΜΑΝ",
  "ΑΣΠΡΑ",
  "ΤΡΑΠΩ",
  "ΑΦΑΓΟ",
  "ΣΗΚΩΝ",
  "ΒΡΗΚΕ",
  "ΑΚΟΜΑ",
  "ΡΗΞΙΣ",
  "ΤΡΕΧΩ",
  "ΤΖΙΝΙ",
  "ΛΙΑΖΩ",
  "ΖΗΜΙΑ",
  "ΡΟΓΕΣ",
  "ΘΗΛΩΝ",
  "ΛΩΛΗΣ",
  "ΚΟΡΑΞ",
  "ΗΧΕΡΑ",
  "ΑΠΑΤΟ",
  "ΒΕΡΑΣ",
  "ΛΥΤΟΥ",
  "ΞΙΦΙΑ",
  "ΕΓΙΝΕ",
  "ΟΡΧΟΥ",
  "ΡΕΖΕΣ",
  "ΛΑΘΟΣ",
  "ΑΓΟΜΕ",
  "ΒΟΛΩΝ",
  "ΠΗΓΕΣ",
  "ΟΡΑΤΟ",
  "ΑΣΤΗΡ",
  "ΚΛΗΡΑ",
  "ΦΙΑΛΗ",
  "ΤΣΑΛΙ",
  "ΦΕΞΕΣ",
  "ΣΦΑΖΕ",
  "ΑΠΛΟΥ",
  "ΜΠΑΖΑ",
  "ΠΑΧΝΙ",
  "ΡΑΨΕΙ",
  "ΓΟΝΟΥ",
  "ΛΙΓΝΕ",
  "ΒΡΑΖΕ",
  "ΟΣΟΥΣ",
  "ΑΓΡΟΥ",
  "ΚΑΛΟΥ",
  "ΒΑΡΚΑ",
  "ΓΝΟΙΑ",
  "ΑΡΑΤΗ",
  "ΕΨΗΝΕ",
  "ΒΥΤΙΑ",
  "ΨΟΓΩΝ",
  "ΕΛΑΒΕ",
  "ΓΑΡΟΣ",
  "ΣΚΑΜΕ",
  "ΞΕΝΙΑ",
  "ΑΦΤΡΑ",
  "ΚΛΗΡΟ",
  "ΛΙΡΕΣ",
  "ΑΠΤΗΝ",
  "ΠΡΙΜΑ",
  "ΨΙΧΙΑ",
  "ΔΕΧΤΑ",
  "ΟΥΣΕΣ",
  "ΣΩΣΜΑ",
  "ΠΟΜΠΕ",
  "ΓΟΝΙΕ",
  "ΓΕΛΑΣ",
  "ΣΑΡΑΣ",
  "ΦΗΜΗΣ",
  "ΧΟΛΟΣ",
  "ΔΑΡΤΕ",
  "ΥΓΡΟΣ",
  "ΑΓΑΘΕ",
  "ΣΥΚΟΝ",
  "ΥΜΕΝΑ",
  "ΧΡΟΝΕ",
  "ΑΕΤΟΣ",
  "ΣΑΝΟΥ",
  "ΣΕΒΡΟ",
  "ΑΣΤΡΙ",
  "ΕΘΙΞΕ",
  "ΠΑΤΑΝ",
  "ΤΡΑΦΩ",
  "ΟΧΤΟΣ",
  "ΑΡΧΑΣ",
  "ΒΡΑΧΟ",
  "ΜΕΛΟΣ",
  "ΛΙΠΩΝ",
  "ΚΑΣΑΣ",
  "ΚΡΟΥΕ",
  "ΑΓΕΡΑ",
  "ΑΞΙΑΝ",
  "ΠΩΛΕΙ",
  "ΑΟΚΝΟ",
  "ΟΠΟΙΟ",
  "ΣΚΑΛΕ",
  "ΚΑΜΠΕ",
  "ΣΠΑΣΩ",
  "ΟΙΩΝΟ",
  "ΚΕΡΙΑ",
  "ΠΑΤΟΣ",
  "ΒΑΡΑΝ",
  "ΑΜΟΝΙ",
  "ΑΒΡΟΙ",
  "ΝΙΨΤΕ",
  "ΑΝΙΣΕ",
  "ΦΡΥΓΩ",
  "ΓΥΡΑΣ",
  "ΧΟΙΔΑ",
  "ΚΑΚΗΝ",
  "ΚΑΝΙΑ",
  "ΒΡΙΖΑ",
  "ΑΓΕΡΙ",
  "ΟΛΙΓΕ",
  "ΑΜΥΛΑ",
  "ΚΤΙΖΕ",
  "ΠΕΙΤΕ",
  "ΕΝΙΚΗ",
  "ΤΟΜΩΝ",
  "ΑΣΟΦΕ",
  "ΙΤΙΕΣ",
  "ΨΗΛΩΝ",
  "ΟΡΟΦΟ",
  "ΑΣΤΟΣ",
  "ΡΟΥΧΟ",
  "ΡΕΜΒΗ",
  "ΙΚΑΡΕ",
  "ΑΝΤΙΟ",
  "ΝΕΥΕΙ",
  "ΚΑΖΟΥ",
  "ΛΟΡΔΑ",
  "ΑΠΩΘΩ",
  "ΙΕΡΟΥ",
  "ΒΟΗΘΟ",
  "ΚΑΨΑΣ",
  "ΤΡΙΣΕ",
  "ΒΑΤΗΣ",
  "ΥΠΤΙΕ",
  "ΓΥΡΝΩ",
  "ΑΣΗΜΟ",
  "ΠΙΑΝΑ",
  "ΙΣΧΙΑ",
  "ΠΕΟΥΣ",
  "ΚΑΗΚΕ",
  "ΨΑΛΤΗ",
  "ΠΗΧΤΗ",
  "ΑΡΑΠΗ",
  "ΠΙΠΕΣ",
  "ΕΜΒΙΑ",
  "ΕΧΑΒΑ",
  "ΑΦΟΡΟ",
  "ΑΚΥΡΕ",
  "ΒΛΟΓΩ",
  "ΣΤΗΝΩ",
  "ΟΡΟΥΝ",
  "ΓΕΡΝΩ",
  "ΦΤΑΣΩ",
  "ΠΟΛΛΑ",
  "ΓΡΑΙΑ",
  "ΤΟΝΟΣ",
  "ΑΤΟΠΕ",
  "ΑΓΝΕΣ",
  "ΣΝΟΜΠ",
  "ΘΕΣΜΕ",
  "ΤΑΚΤΗ",
  "ΠΛΗΞΕ",
  "ΤΡΑΝΗ",
  "ΤΡΟΜΕ",
  "ΖΑΡΙΑ",
  "ΠΑΥΣΗ",
  "ΦΟΝΤΟ",
  "ΜΑΦΙΑ",
  "ΑΛΟΓΑ",
  "ΛΕΓΟΝ",
  "ΔΗΜΙΕ",
  "ΒΩΜΩΝ",
  "ΟΡΦΝΕ",
  "ΒΟΕΡΗ",
  "ΦΕΡΤΟ",
  "ΡΑΜΠΑ",
  "ΕΛΚΥΑ",
  "ΤΑΣΙΣ",
  "ΔΙΔΕΙ",
  "ΣΗΠΙΑ",
  "ΚΡΙΘΩ",
  "ΡΑΓΑΣ",
  "ΠΗΔΟΥ",
  "ΛΑΛΕΙ",
  "ΜΑΟΝΙ",
  "ΠΩΡΟΙ",
  "ΟΙΚΤΟ",
  "ΚΙΛΕΡ",
  "ΡΕΠΕΙ",
  "ΛΑΓΝΕ",
  "ΛΕΜΕΣ",
  "ΣΩΟΥΣ",
  "ΔΡΟΜΟ",
  "ΑΜΑΞΑ",
  "ΜΙΞΑΖ",
  "ΛΟΓΙΟ",
  "ΣΙΦΩΝ",
  "ΣΠΟΡΙ",
  "ΣΤΗΣΩ",
  "ΑΙΝΩΝ",
  "ΘΗΚΕΣ",
  "ΤΕΛΕΞ",
  "ΕΞΑΓΕ",
  "ΛΥΤΟΣ",
  "ΑΒΑΘΕ",
  "ΕΞΑΓΩ",
  "ΣΠΙΛΟ",
  "ΣΚΕΤΣ",
  "ΓΚΑΦΑ",
  "ΑΠΕΒΗ",
  "ΕΩΛΟΙ",
  "ΣΑΚΑΣ",
  "ΟΓΡΗΣ",
  "ΡΟΥΣΟ",
  "ΖΗΤΑΝ",
  "ΛΩΤΟΙ",
  "ΘΕΑΣΗ",
  "ΝΕΓΡΑ",
  "ΑΔΟΤΗ",
  "ΥΑΛΟΙ",
  "ΑΔΡΟΥ",
  "ΥΦΑΝΑ",
  "ΑΝΟΣΗ",
  "ΣΤΙΧΟ",
  "ΚΡΑΣΗ",
  "ΜΙΝΕΣ",
  "ΧΩΛΩΝ",
  "ΕΣΩΣΕ",
  "ΑΡΓΟΣ",
  "ΠΑΛΤΟ",
  "ΑΡΙΟΣ",
  "ΠΟΣΤΟ",
  "ΕΡΚΩΝ",
  "ΥΓΙΕΣ",
  "ΠΑΣΤΟ",
  "ΝΩΠΟΣ",
  "ΤΙΛΩΝ",
  "ΒΑΘΥΣ",
  "ΖΩΜΟΙ",
  "ΛΕΙΡΙ",
  "ΑΨΑΔΑ",
  "ΧΑΧΕΣ",
  "ΚΑΠΕΣ",
  "ΑΜΑΧΗ",
  "ΣΤΕΡΩ",
  "ΚΡΙΝΩ",
  "ΘΗΡΩΝ",
  "ΓΝΕΜΑ",
  "ΤΡΙΒΩ",
  "ΒΑΝΕΣ",
  "ΓΟΓΓΩ",
  "ΟΘΟΝΗ",
  "ΟΜΗΡΟ",
  "ΒΥΤΙΟ",
  "ΜΑΝΝΑ",
  "ΓΚΑΒΟ",
  "ΑΥΞΩΝ",
  "ΚΥΡΕΣ",
  "ΧΟΡΟΙ",
  "ΣΠΑΘΑ",
  "ΚΛΗΜΑ",
  "ΜΑΧΗΣ",
  "ΕΧΕΙΣ",
  "ΕΩΛΟΣ",
  "ΣΟΡΓΟ",
  "ΑΛΙΚΕ",
  "ΧΩΡΕΣ",
  "ΤΕΛΙΑ",
  "ΓΑΖΑΣ",
  "ΙΣΩΝΩ",
  "ΑΤΥΧΗ",
  "ΝΤΑΝΑ",
  "ΦΤΑΝΩ",
  "ΨΗΤΗΣ",
  "ΑΞΙΑΣ",
  "ΑΘΕΕΣ",
  "ΜΗΛΩΝ",
  "ΗΧΟΥΣ",
  "ΚΡΙΚΕ",
  "ΓΝΩΣΩ",
  "ΟΛΒΙΟ",
  "ΟΥΣΙΑ",
  "ΜΠΟΞΑ",
  "ΦΘΟΝΟ",
  "ΦΕΡΝΩ",
  "ΠΑΛΛΩ",
  "ΤΡΩΤΗ",
  "ΚΑΡΜΑ",
  "ΠΤΥΧΗ",
  "ΙΣΑΣΑ",
  "ΤΕΛΜΑ",
  "ΔΙΑΒΩ",
  "ΠΟΝΟΙ",
  "ΑΨΟΓΑ",
  "ΟΦΕΩΣ",
  "ΔΥΝΩΝ",
  "ΕΩΛΩΝ",
  "ΓΑΥΡΕ",
  "ΟΝΕΙΕ",
  "ΥΔΑΡΗ",
  "ΥΜΝΟΙ",
  "ΦΥΡΕΣ",
  "ΑΛΛΑΣ",
  "ΖΩΝΕΙ",
  "ΑΖΥΓΑ",
  "ΑΣΚΩΝ",
  "ΟΣΙΕΣ",
  "ΑΜΜΟΥ",
  "ΡΑΣΟΥ",
  "ΟΠΑΙΟ",
  "ΣΤΗΛΗ",
  "ΒΛΩΜΟ",
  "ΤΡΙΖΕ",
  "ΚΑΛΟΣ",
  "ΘΑΜΠΑ",
  "ΒΙΟΛΑ",
  "ΝΤΟΡΗ",
  "ΜΑΡΚΟ",
  "ΑΙΣΧΗ",
  "ΣΙΤΟΣ",
  "ΧΥΤΗΣ",
  "ΔΙΩΞΗ",
  "ΚΛΗΣΗ",
  "ΜΙΜΟΥ",
  "ΑΠΙΚΟ",
  "ΚΟΝΤΕ",
  "ΕΧΕΙΝ",
  "ΛΟΧΟΙ",
  "ΣΙΑΖΕ",
  "ΔΑΚΟΣ",
  "ΛΑΖΩΝ",
  "ΑΤΟΝΕ",
  "ΛΥΝΕΙ",
  "ΤΑΙΡΙ",
  "ΑΝΑΨΩ",
  "ΒΟΡΑΣ",
  "ΚΥΡΗΣ",
  "ΥΣΤΕΡ",
  "ΣΚΕΤΕ",
  "ΑΠΤΟΥ",
  "ΝΕΑΡΕ",
  "ΖΟΥΡΑ",
  "ΤΟΣΗΣ",
  "ΠΟΙΟΙ",
  "ΣΤΗΘΙ",
  "ΝΕΚΡΕ",
  "ΤΑΚΟΣ",
  "ΥΕΛΟΙ",
  "ΛΕΡΑΣ",
  "ΦΕΡΑΝ",
  "ΡΕΛΙΑ",
  "ΔΕΚΤΗ",
  "ΠΕΣΣΕ",
  "ΝΗΣΟΥ",
  "ΕΛΕΟΣ",
  "ΦΛΟΜΟ",
  "ΦΑΣΚΕ",
  "ΑΚΑΝΕ",
  "ΜΑΡΚΑ",
  "ΠΙΟΤΙ",
  "ΚΥΑΜΟ",
  "ΕΡΑΨΑ",
  "ΔΟΛΙΟ",
  "ΛΑΣΠΗ",
  "ΔΟΞΑΣ",
  "ΗΛΘΑΝ",
  "ΟΡΕΞΗ",
  "ΠΥΓΩΝ",
  "ΠΡΑΟΣ",
  "ΑΘΛΙΕ",
  "ΟΡΥΖΑ",
  "ΡΟΚΕΣ",
  "ΚΥΗΜΑ",
  "ΛΟΦΟΙ",
  "ΔΡΑΚΑ",
  "ΚΑΒΟΙ",
  "ΒΛΑΤΙ",
  "ΣΤΟΛΟ",
  "ΜΠΑΣΑ",
  "ΕΞΑΡΑ",
  "ΕΘΙΓΕ",
  "ΝΙΚΗΣ",
  "ΞΙΦΙΟ",
  "ΜΟΛΩΝ",
  "ΘΡΥΒΕ",
  "ΓΡΙΚΑ",
  "ΓΚΑΛΑ",
  "ΦΑΥΛΕ",
  "ΚΙΟΤΗ",
  "ΚΥΦΟΥ",
  "ΔΙΩΜΑ",
  "ΚΑΛΠΗ",
  "ΑΘΛΟΥ",
  "ΛΕΙΨΟ",
  "ΜΙΜΟΙ",
  "ΠΟΙΝΗ",
  "ΑΚΥΡΟ",
  "ΜΙΑΡΟ",
  "ΦΥΤΡΟ",
  "ΝΙΚΑΩ",
  "ΚΟΡΕΣ",
  "ΑΒΙΑΣ",
  "ΠΟΥΡΑ",
  "ΡΕΒΕΙ",
  "ΙΣΙΟΥ",
  "ΡΑΝΤΑ",
  "ΛΑΛΟΥ",
  "ΜΙΣΧΟ",
  "ΞΥΛΙΑ",
  "ΧΥΣΑΝ",
  "ΝΕΑΡΟ",
  "ΛΑΛΕΣ",
  "ΟΠΛΗΣ",
  "ΦΕΤΩΝ",
  "ΓΟΝΕΑ",
  "ΠΕΖΗΣ",
  "ΑΕΡΟΣ",
  "ΩΡΙΜΑ",
  "ΔΟΥΚΑ",
  "ΤΡΥΓΩ",
  "ΑΦΑΛΙ",
  "ΑΓΟΝΗ",
  "ΙΔΡΟΣ",
  "ΑΦΕΣΗ",
  "ΤΥΜΒΟ",
  "ΓΛΥΦΑ",
  "ΚΑΝΑΝ",
  "ΣΚΑΛΑ",
  "ΑΔΡΟΙ",
  "ΚΟΛΑΣ",
  "ΨΕΚΤΑ",
  "ΦΑΝΩΝ",
  "ΨΕΥΔΟ",
  "ΑΓΡΟΙ",
  "ΓΟΥΡΙ",
  "ΚΕΙΝΗ",
  "ΟΡΚΟΥ",
  "ΣΠΙΛΕ",
  "ΑΘΟΛΑ",
  "ΣΥΡΩΝ",
  "ΦΟΒΟΣ",
  "ΑΣΠΡΕ",
  "ΒΡΑΣΩ",
  "ΓΛΑΟΣ",
  "ΜΙΣΟΣ",
  "ΘΕΣΜΟ",
  "ΩΧΡΗΣ",
  "ΧΡΙΣΩ",
  "ΑΡΚΕΙ",
  "ΟΡΙΖΩ",
  "ΧΑΣΗΣ",
  "ΕΠΟΧΗ",
  "ΤΑΞΕΙ",
  "ΕΣΩΣΑ",
  "ΓΚΙΣΕ",
  "ΡΟΠΩΝ",
  "ΑΤΟΚΟ",
  "ΛΑΣΚΑ",
  "ΦΩΤΑΝ",
  "ΠΡΟΣΩ",
  "ΟΞΙΝΕ",
  "ΝΟΝΟΙ",
  "ΑΡΧΗΣ",
  "ΜΕΣΗΣ",
  "ΓΑΥΡΑ",
  "ΛΑΜΨΗ",
  "ΛΙΓΕΣ",
  "ΤΡΟΠΟ",
  "ΒΡΑΣΕ",
  "ΔΡΟΣΟ",
  "ΤΥΧΗΣ",
  "ΠΕΡΣΙ",
  "ΛΙΓΟΥ",
  "ΡΑΧΗΣ",
  "ΤΡΑΝΕ",
  "ΚΡΑΤΩ",
  "ΑΜΝΟΥ",
  "ΣΥΡΤΕ",
  "ΘΕΤΟΣ",
  "ΣΚΗΝΗ",
  "ΡΑΙΒΗ",
  "ΞΕΣΤΟ",
  "ΧΑΒΑΣ",
  "ΖΕΣΤΗ",
  "ΙΣΤΟΙ",
  "ΞΑΝΘΗ",
  "ΒΑΝΩΝ",
  "ΒΟΜΒΟ",
  "ΡΙΞΤΕ",
  "ΘΗΛΕΑ",
  "ΕΠΑΥΑ",
  "ΕΠΗΖΕ",
  "ΞΩΘΙΑ",
  "ΔΡΩΣΑ",
  "ΠΙΑΝΟ",
  "ΓΛΥΚΟ",
  "ΕΠΙΚΑ",
  "ΤΥΜΒΕ",
  "ΛΙΝΗΣ",
  "ΨΙΛΗΣ",
  "ΖΕΣΙΣ",
  "ΡΥΠΟΣ",
  "ΠΗΝΙΟ",
  "ΠΛΕΞΩ",
  "ΡΕΥΣΗ",
  "ΟΝΤΕΣ",
  "ΓΕΛΑΝ",
  "ΛΑΒΡΑ",
  "ΑΚΤΕΣ",
  "ΛΑΒΕΣ",
  "ΑΞΙΖΕ",
  "ΑΗΔΗΣ",
  "ΞΑΣΟΥ",
  "ΣΒΕΝΩ",
  "ΥΠΗΓΕ",
  "ΑΡΧΩΝ",
  "ΔΕΚΤΑ",
  "ΡΙΨΗΣ",
  "ΟΙΚΕΙ",
  "ΤΡΑΓΙ",
  "ΜΥΤΙΑ",
  "ΚΡΑΖΩ",
  "ΕΧΑΨΑ",
  "ΣΑΝΟΣ",
  "ΧΡΥΣΑ",
  "ΡΟΜΒΕ",
  "ΟΡΜΗΝ",
  "ΨΩΝΙΑ",
  "ΣΥΡΜΗ",
  "ΓΟΝΩΝ",
  "ΦΡΕΝΑ",
  "ΤΡΩΣΗ",
  "ΡΑΣΟΝ",
  "ΕΚΤΟΙ",
  "ΧΕΣΑΝ",
  "ΠΙΑΤΑ",
  "ΣΤΗΘΩ",
  "ΚΑΚΟΙ",
  "ΔΥΑΤΕ",
  "ΒΡΩΜΑ",
  "ΞΗΡΕΣ",
  "ΦΤΑΙΝ",
  "ΠΟΡΟΙ",
  "ΚΟΥΡΕ",
  "ΔΙΩΡΕ",
  "ΙΛΥΟΣ",
  "ΚΛΗΤΕ",
  "ΑΨΥΧΟ",
  "ΕΞΕΩΝ",
  "ΑΜΑΔΑ",
  "ΡΑΦΤΩ",
  "ΠΟΛΟΣ",
  "ΟΠΑΔΕ",
  "ΒΟΓΚΕ",
  "ΜΑΖΩΝ",
  "ΕΝΘΕΗ",
  "ΜΥΩΣΗ",
  "ΑΒΡΩΝ",
  "ΡΟΔΑΣ",
  "ΔΩΡΕΙ",
  "ΡΥΜΩΝ",
  "ΕΨΕΓΕ",
  "ΟΠΕΡΑ",
  "ΠΗΡΑΝ",
  "ΑΛΥΤΟ",
  "ΕΝΙΒΕ",
  "ΠΟΛΗΣ",
  "ΣΙΜΟΥ",
  "ΑΣΠΡΟ",
  "ΕΔΙΔΕ",
  "ΟΣΦΥΣ",
  "ΤΣΟΥΠ",
  "ΕΘΙΜΑ",
  "ΠΑΙΖΩ",
  "ΕΞΟΔΟ",
  "ΝΙΛΕΣ",
  "ΒΟΛΕΥ",
  "ΤΟΝΙΚ",
  "ΤΣΑΚΑ",
  "ΔΗΜΩΝ",
  "ΔΡΟΓΗ",
  "ΟΡΝΕΑ",
  "ΚΑΝΙΣ",
  "ΑΟΣΜΗ",
  "ΕΝΤΟΣ",
  "ΑΣΙΣΤ",
  "ΑΠΛΟΣ",
  "ΚΟΜΨΗ",
  "ΒΟΥΛΗ",
  "ΖΥΜΗΣ",
  "ΨΑΡΑΣ",
  "ΣΕΚΟΣ",
  "ΒΑΡΔΟ",
  "ΠΡΗΖΕ",
  "ΑΜΑΧΕ",
  "ΜΥΛΩΝ",
  "ΛΑΜΝΩ",
  "ΣΕΒΑΣ",
  "ΟΠΙΩΝ",
  "ΤΑΒΑΣ",
  "ΑΜΑΘΑ",
  "ΠΤΑΙΩ",
  "ΡΟΔΟΝ",
  "ΑΒΡΕΣ",
  "ΛΟΓΟΙ",
  "ΕΣΩΖΕ",
  "ΤΕΦΡΟ",
  "ΤΕΦΡΗ",
  "ΠΗΧΩΝ",
  "ΣΕΚΟΥ",
  "ΜΑΡΕΣ",
  "ΧΥΛΟΥ",
  "ΔΕΧΤΟ",
  "ΒΡΕΙΣ",
  "ΣΤΑΞΕ",
  "ΟΡΩΔΗ",
  "ΑΦΕΛΗ",
  "ΣΑΖΑΝ",
  "ΣΩΣΕΙ",
  "ΟΡΚΩΝ",
  "ΑΠΟΡΑ",
  "ΛΕΧΘΩ",
  "ΛΟΒΟΙ",
  "ΦΛΕΡΤ",
  "ΑΞΙΣΩ",
  "ΝΕΓΡΕ",
  "ΞΥΠΝΑ",
  "ΞΕΣΙΣ",
  "ΣΥΡΑΝ",
  "ΠΑΣΤΑ",
  "ΔΙΕΣΗ",
  "ΙΣΧΙΟ",
  "ΧΕΣΤΗ",
  "ΨΑΛΤΟ",
  "ΣΩΣΟΥ",
  "ΕΝΟΧΕ",
  "ΜΑΣΑΩ",
  "ΑΘΛΟΝ",
  "ΓΥΙΟΙ",
  "ΟΛΚΟΣ",
  "ΧΟΡΟΣ",
  "ΦΥΡΗΣ",
  "ΤΙΤΛΟ",
  "ΜΕΣΤΕ",
  "ΦΟΡΑΝ",
  "ΘΑΝΕΣ",
  "ΕΒΙΒΑ",
  "ΟΣΤΙΑ",
  "ΝΕΦΡΑ",
  "ΣΑΙΤΑ",
  "ΠΕΡΙΞ",
  "ΠΕΠΛΟ",
  "ΤΑΝΥΕ",
  "ΓΚΕΚΑ",
  "ΒΥΝΕΣ",
  "ΝΙΚΩΝ",
  "ΕΡΕΑΣ",
  "ΦΥΤΩΝ",
  "ΓΑΛΕΕ",
  "ΧΙΜΑΣ",
  "ΠΩΡΟΣ",
  "ΑΓΛΑΕ",
  "ΒΟΛΟΙ",
  "ΣΟΒΕΙ",
  "ΚΕΦΤΕ",
  "ΓΕΛΗΣ",
  "ΑΝΚΟΡ",
  "ΝΟΕΡΗ",
  "ΚΤΗΣΗ",
  "ΤΡΥΓΟ",
  "ΕΒΑΖΑ",
  "ΠΤΥΟΥ",
  "ΟΧΤΡΟ",
  "ΕΒΕΝΟ",
  "ΛΗΣΤΗ",
  "ΙΑΜΒΟ",
  "ΝΑΡΚΗ",
  "ΑΡΑΙΑ",
  "ΒΩΒΟΣ",
  "ΜΙΜΟΣ",
  "ΕΥΡΕΑ",
  "ΧΑΙΡΙ",
  "ΝΟΗΣΕ",
  "ΦΡΥΔΙ",
  "ΧΑΜΟΙ",
  "ΑΕΡΙΑ",
  "ΕΧΩΣΑ",
  "ΥΨΗΛΕ",
  "ΦΙΛΙΟ",
  "ΡΑΚΙΑ",
  "ΨΥΧΗΝ",
  "ΣΒΗΝΕ",
  "ΜΕΣΟΝ",
  "ΡΟΧΘΕ",
  "ΚΛΕΒΩ",
  "ΓΕΡΗΣ",
  "ΑΜΝΩΝ",
  "ΣΚΙΝΟ",
  "ΕΣΤΕΤ",
  "ΛΩΒΕΣ",
  "ΜΠΑΞΕ",
  "ΜΟΣΚΟ",
  "ΛΥΓΚΑ",
  "ΩΡΙΜΟ",
  "ΠΟΘΟΙ",
  "ΕΒΑΖΕ",
  "ΑΒΓΟΥ",
  "ΑΒΡΗΣ",
  "ΑΚΕΦΕ",
  "ΣΑΛΩΝ",
  "ΜΗΝΑΣ",
  "ΓΔΥΣΩ",
  "ΑΠΟΨΕ",
  "ΠΗΧΕΣ",
  "ΑΩΡΟΙ",
  "ΟΙΗΣΗ",
  "ΧΝΟΤΑ",
  "ΦΙΛΑΩ",
  "ΧΑΣΚΑ",
  "ΟΙΝΟΥ",
  "ΞΑΙΝΩ",
  "ΛΙΘΩΝ",
  "ΑΡΜΕΣ",
  "ΡΩΓΩΝ",
  "ΤΑΨΙΑ",
  "ΥΠΑΤΗ",
  "ΜΗΛΕΑ",
  "ΓΝΑΘΕ",
  "ΛΟΓΓΕ",
  "ΒΥΝΩΝ",
  "ΔΑΥΛΙ",
  "ΡΑΙΖΕ",
  "ΘΥΜΟΣ",
  "ΠΤΩΜΑ",
  "ΛΟΓΧΗ",
  "ΚΑΔΟΙ",
  "ΚΤΥΠΑ",
  "ΑΝΕΣΗ",
  "ΚΟΨΟΥ",
  "ΡΟΥΦΑ",
  "ΦΑΠΑΣ",
  "ΔΕΧΤΗ",
  "ΤΡΕΞΕ",
  "ΜΙΛΑΩ",
  "ΕΝΙΒΑ",
  "ΡΑΔΑΣ",
  "ΚΡΥΦΑ",
  "ΚΟΣΜΟ",
  "ΘΙΑΣΕ",
  "ΤΑΞΟΣ",
  "ΟΜΠΥΟ",
  "ΠΙΚΕΣ",
  "ΑΣΗΜΗ",
  "ΚΥΡΙΑ",
  "ΑΓΧΟΣ",
  "ΥΜΝΟΥ",
  "ΝΗΣΣΑ",
  "ΤΕΝΤΑ",
  "ΗΘΙΚΕ",
  "ΝΙΝΙΑ",
  "ΑΥΠΝΕ",
  "ΟΠΤΟΥ",
  "ΠΟΤΟΝ",
  "ΣΑΚΩΝ",
  "ΚΥΠΤΩ",
  "ΨΑΛΑΝ",
  "ΧΟΙΚΟ",
  "ΤΣΕΛΟ",
  "ΡΑΒΔΙ",
  "ΦΕΤΦΑ",
  "ΔΟΤΟΥ",
  "ΠΟΙΑΣ",
  "ΙΓΚΛΑ",
  "ΘΑΜΒΕ",
  "ΞΕΙΠΑ",
  "ΧΕΙΡΑ",
  "ΡΕΚΤΗ",
  "ΕΣΑΖΕ",
  "ΜΕΤΡΩ",
  "ΣΧΕΣΗ",
  "ΨΟΦΙΕ",
  "ΤΡΕΠΕ",
  "ΠΛΕΟΝ",
  "ΒΟΣΚΟ",
  "ΚΑΤΣΩ",
  "ΨΙΑΘΕ",
  "ΚΡΑΜΑ",
  "ΜΑΛΩΝ",
  "ΠΝΙΞΩ",
  "ΣΑΘΡΑ",
  "ΥΛΙΚΟ",
  "ΝΙΤΡΑ",
  "ΓΥΡΤΕ",
  "ΑΡΑΞΕ",
  "ΨΕΛΝΩ",
  "ΜΥΧΟΣ",
  "ΛΑΒΡΕ",
  "ΒΙΩΣΑ",
  "ΚΛΑΠΑ",
  "ΑΝΟΜΗ",
  "ΒΑΦΛΑ",
  "ΑΡΓΟΙ",
  "ΧΕΣΤΩ",
  "ΟΖΟΥΣ",
  "ΠΥΓΕΣ",
  "ΦΘΑΝΕ",
  "ΚΑΛΟΙ",
  "ΤΡΕΧΑ",
  "ΧΩΣΤΑ",
  "ΑΧΛΥΣ",
  "ΚΡΕΜΩ",
  "ΑΓΚΩΝ",
  "ΛΙΩΣΩ",
  "ΝΟΤΙΑ",
  "ΡΙΧΝΩ",
  "ΑΤΥΧΟ",
  "ΚΛΑΔΑ",
  "ΑΟΚΝΗ",
  "ΕΞΗΡΑ",
  "ΚΡΟΤΟ",
  "ΤΑΦΟΝ",
  "ΕΛΚΟΣ",
  "ΛΕΠΤΑ",
  "ΦΛΩΡΟ",
  "ΒΙΚΩΝ",
  "ΠΡΙΜΕ",
  "ΑΠΑΛΑ",
  "ΑΜΕΣΑ",
  "ΔΕΤΟΥ",
  "ΑΝΕΤΟ",
  "ΛΥΠΕΙ",
  "ΦΛΩΡΙ",
  "ΕΣΘΙΩ",
  "ΟΥΡΟΝ",
  "ΔΡΙΜΥ",
  "ΛΟΒΩΝ",
  "ΕΡΑΝΟ",
  "ΥΙΙΚΟ",
  "ΠΡΕΣΑ",
  "ΚΟΝΤΑ",
  "ΘΑΥΜΑ",
  "ΑΠΙΔΙ",
  "ΑΡΤΙΟ",
  "ΙΕΡΟΣ",
  "ΜΑΠΩΝ",
  "ΓΛΑΥΞ",
  "ΒΡΑΖΩ",
  "ΔΥΤΩΝ",
  "ΧΑΣΟΥ",
  "ΒΥΖΟΥ",
  "ΚΟΛΑΖ",
  "ΠΩΡΟΥ",
  "ΩΧΡΑΣ",
  "ΞΕΡΝΑ",
  "ΠΥΡΣΕ",
  "ΦΡΥΝΟ",
  "ΟΜΑΛΕ",
  "ΠΟΤΟΙ",
  "ΣΤΑΝΗ",
  "ΘΛΙΨΩ",
  "ΜΥΑΛΑ",
  "ΠΟΚΑΣ",
  "ΑΡΤΟΙ",
  "ΑΔΟΛΟ",
  "ΘΗΛΙΑ",
  "ΑΝΩΣΗ",
  "ΧΛΩΜΕ",
  "ΨΑΡΟΙ",
  "ΞΗΡΗΣ",
  "ΦΟΡΟΝ",
  "ΤΑΣΗΣ",
  "ΡΙΓΕΣ",
  "ΘΥΤΕΣ",
  "ΛΕΜΦΟ",
  "ΘΕΛΙΑ",
  "ΡΕΓΚΑ",
  "ΓΑΛΕΣ",
  "ΟΙΝΟΙ",
  "ΓΔΑΡΕ",
  "ΟΥΡΟΥ",
  "ΕΔΕΝΑ",
  "ΛΩΤΟΥ",
  "ΘΡΟΝΕ",
  "ΣΚΗΤΗ",
  "ΚΙΡΣΕ",
  "ΑΦΩΝΟ",
  "ΡΑΙΝΩ",
  "ΕΦΥΓΕ",
  "ΥΨΩΣΩ",
  "ΟΠΤΗΣ",
  "ΞΕΣΤΗ",
  "ΕΚΑΝΑ",
  "ΕΘΙΜΟ",
  "ΛΑΧΝΗ",
  "ΣΙΑΖΩ",
  "ΟΙΚΤΕ",
  "ΓΙΝΟΥ",
  "ΨΗΣΕΙ",
  "ΦΙΛΟΣ",
  "ΑΡΝΙΑ",
  "ΓΥΨΟΥ",
  "ΘΑΨΑΝ",
  "ΛΕΠΡΕ",
  "ΕΖΕΨΑ",
  "ΑΣΩΤΕ",
  "ΙΣΙΟΣ",
  "ΧΙΜΑΩ",
  "ΣΒΗΣΩ",
  "ΠΥΡΣΟ",
  "ΡΑΦΗΣ",
  "ΔΙΑΤΑ",
  "ΚΙΤΡΑ",
  "ΠΑΠΠΟ",
  "ΑΔΗΛΕ",
  "ΘΕΣΕΙ",
  "ΛΙΡΩΝ",
  "ΕΧΥΝΑ",
  "ΤΙΜΙΕ",
  "ΤΣΙΡΕ",
  "ΓΑΛΗΣ",
  "ΒΟΘΡΟ",
  "ΚΟΥΚΕ",
  "ΓΝΩΘΙ",
  "ΙΣΚΙΕ",
  "ΚΟΠΕΙ",
  "ΑΩΡΟΥ",
  "ΔΕΞΙΟ",
  "ΕΛΙΕΣ",
  "ΟΡΘΙΑ",
  "ΞΙΝΩΝ",
  "ΓΝΑΘΟ",
  "ΝΤΑΗΣ",
  "ΣΤΕΦΩ",
  "ΓΑΥΡΟ",
  "ΑΥΤΟΝ",
  "ΧΥΝΕΙ",
  "ΚΛΙΝΩ",
  "ΩΡΙΟΣ",
  "ΠΙΚΑΠ",
  "ΑΡΘΡΑ",
  "ΜΥΔΙΑ",
  "ΒΡΟΥΝ",
  "ΕΚΑΤΟ",
  "ΜΕΤΖΟ",
  "ΚΡΑΝΟ",
  "ΘΑΜΠΕ",
  "ΓΚΑΜΑ",
  "ΔΙΩΞΑ",
  "ΣΤΥΦΗ",
  "ΒΩΜΟΥ",
  "ΠΙΡΟΣ",
  "ΠΟΣΕΣ",
  "ΣΜΙΓΕ",
  "ΤΑΓΜΑ",
  "ΤΙΑΡΑ",
  "ΦΟΒΩΝ",
  "ΧΤΕΝΙ",
  "ΡΥΜΗΣ",
  "ΣΟΦΕΣ",
  "ΕΙΚΩΝ",
  "ΛΟΞΗΣ",
  "ΧΛΩΡΑ",
  "ΜΟΥΡΑ",
  "ΕΙΔΩΝ",
  "ΡΙΞΙΑ",
  "ΔΙΝΗΣ",
  "ΚΟΧΕΣ",
  "ΡΟΦΑΝ",
  "ΘΕΤΕΙ",
  "ΓΡΥΛΕ",
  "ΧΛΩΡΗ",
  "ΦΟΙΤΩ",
  "ΑΚΑΚΟ",
  "ΤΕΛΟΣ",
  "ΚΥΛΛΑ",
  "ΕΡΓΩΝ",
  "ΚΑΚΙΑ",
  "ΔΡΑΚΟ",
  "ΔΙΩΚΩ",
  "ΥΑΙΝΑ",
  "ΝΟΕΡΑ",
  "ΨΥΧΡΩ",
  "ΠΟΝΕΙ",
  "ΚΥΠΡΙ",
  "ΑΝΟΜΟ",
  "ΠΕΜΠΩ",
  "ΑΡΟΔΟ",
  "ΚΟΝΤΟ",
  "ΞΑΝΤΗ",
  "ΛΙΤΗΣ",
  "ΜΗΛΗΣ",
  "ΛΥΔΙΑ",
  "ΚΟΙΝΑ",
  "ΑΔΟΤΕ",
  "ΖΗΤΕΙ",
  "ΓΑΜΑΣ",
  "ΚΡΕΜΑ",
  "ΠΡΕΚΙ",
  "ΨΑΛΘΕ",
  "ΑΠΟΝΗ",
  "ΡΕΣΤΗ",
  "ΦΡΥΔΑ",
  "ΙΑΘΕΙ",
  "ΕΣΟΔΑ",
  "ΛΩΛΕΣ",
  "ΤΣΑΠΑ",
  "ΤΡΗΣΗ",
  "ΒΙΖΟΝ",
  "ΚΛΑΨΕ",
  "ΛΟΧΙΑ",
  "ΓΑΛΩΝ",
  "ΑΜΑΘΕ",
  "ΓΑΡΟΝ",
  "ΟΜΠΥΑ",
  "ΣΚΟΝΗ",
  "ΒΑΦΩΝ",
  "ΚΑΡΝΕ",
  "ΗΘΕΛΑ",
  "ΣΚΑΡΑ",
  "ΚΑΡΤΑ",
  "ΑΝΙΑΣ",
  "ΚΑΡΥΑ",
  "ΡΟΛΩΝ",
  "ΛΟΧΟΥ",
  "ΑΙΤΩΝ",
  "ΣΑΛΟΥ",
  "ΓΑΤΟΣ",
  "ΑΗΧΟΙ",
  "ΣΠΑΖΕ",
  "ΚΕΝΟΣ",
  "ΧΥΜΟΣ",
  "ΓΟΜΕΣ",
  "ΠΗΓΜΑ",
  "ΣΟΡΟΙ",
  "ΜΑΔΑΝ",
  "ΡΗΧΟΥ",
  "ΛΙΩΝΩ",
  "ΦΙΚΟΙ",
  "ΛΑΒΕΙ",
  "ΞΥΣΤΟ",
  "ΠΕΝΝΑ",
  "ΔΙΟΡΩ",
  "ΤΕΙΝΕ",
  "ΥΕΤΟΙ",
  "ΦΟΥΛΙ",
  "ΒΡΩΣΗ",
  "ΕΥΘΥΣ",
  "ΦΡΕΑΡ",
  "ΔΑΣΟΣ",
  "ΛΙΑΖΕ",
  "ΑΡΤΩΝ",
  "ΣΙΤΟΙ",
  "ΣΙΤΕΣ",
  "ΓΑΝΕΣ",
  "ΒΛΑΧΑ",
  "ΕΧΩΣΕ",
  "ΤΟΡΒΑ",
  "ΚΟΥΤΕ",
  "ΖΟΥΠΑ",
  "ΛΕΡΩΝ",
  "ΘΗΡΙΟ",
  "ΑΔΩΡΑ",
  "ΓΕΡΟΣ",
  "ΤΟΥΤΑ",
  "ΨΑΧΤΕ",
  "ΟΞΥΝΕ",
  "ΠΟΝΖΕ",
  "ΤΡΑΓΗ",
  "ΡΙΝΑΣ",
  "ΑΞΥΛΗ",
  "ΟΜΟΡΟ",
  "ΚΑΜΒΑ",
  "ΧΥΣΤΕ",
  "ΤΡΑΣΤ",
  "ΔΡΑΣΗ",
  "ΚΑΣΕΣ",
  "ΑΨΙΛΟ",
  "ΜΠΡΙΟ",
  "ΚΟΠΟΙ",
  "ΓΙΑΠΗ",
  "ΔΙΑΝΕ",
  "ΕΛΑΧΕ",
  "ΚΕΙΘΕ",
  "ΓΑΖΙΑ",
  "ΠΕΙΝΑ",
  "ΧΑΦΙΕ",
  "ΛΥΧΝΟ",
  "ΜΥΡΩΝ",
  "ΡΙΠΩΝ",
  "ΣΑΚΟΥ",
  "ΤΜΗΜΑ",
  "ΟΨΕΩΝ",
  "ΑΣΟΦΟ",
  "ΚΑΥΧΗ",
  "ΜΙΤΟΥ",
  "ΚΥΚΛΟ",
  "ΚΑΡΟΣ",
  "ΛΥΟΥΝ",
  "ΜΕΡΕΙ",
  "ΓΚΟΜΑ",
  "ΡΗΤΩΣ",
  "ΓΕΝΕΑ",
  "ΔΟΓΜΑ",
  "ΔΙΣΚΟ",
  "ΦΘΟΝΩ",
  "ΦΕΞΑΝ",
  "ΚΑΨΕΣ",
  "ΒΙΓΛΑ",
  "ΑΡΡΗΝ",
  "ΟΞΕΙΑ",
  "ΙΕΡΗΣ",
  "ΣΜΑΡΙ",
  "ΚΡΥΕΣ",
  "ΑΡΩΓΟ",
  "ΓΗΙΝΗ",
  "ΝΟΗΣΑ",
  "ΦΥΛΟΥ",
  "ΣΚΙΕΣ",
  "ΘΡΥΨΕ",
  "ΠΑΛΤΑ",
  "ΟΙΩΝΕ",
  "ΘΡΑΣΥ",
  "ΒΡΙΣΕ",
  "ΣΕΙΣΕ",
  "ΓΛΥΚΕ",
  "ΑΛΩΣΗ",
  "ΕΡΚΟΣ",
  "ΧΑΤΖΗ",
  "ΓΟΥΦΑ",
  "ΗΠΙΟΥ",
  "ΑΘΕΛΟ",
  "ΣΥΚΩΝ",
  "ΣΕΜΝΑ",
  "ΝΑΝΟΣ",
  "ΚΥΡΤΑ",
  "ΑΘΑΛΗ",
  "ΑΚΟΠΑ",
  "ΒΟΓΚΟ",
  "ΜΑΘΟΣ",
  "ΚΑΚΟΣ",
  "ΧΑΦΤΩ",
  "ΕΦΕΣΗ",
  "ΦΑΡΔΗ",
  "ΣΑΞΟΥ",
  "ΨΙΔΙΑ",
  "ΓΥΛΙΟ",
  "ΕΑΥΤΑ",
  "ΚΑΔΕΣ",
  "ΛΟΞΟΣ",
  "ΠΤΥΣΕ",
  "ΧΤΙΖΩ",
  "ΕΒΑΛΕ",
  "ΚΟΧΗΣ",
  "ΗΛΙΟΣ",
  "ΙΣΑΛΗ",
  "ΛΕΙΟΣ",
  "ΚΩΦΟΣ",
  "ΕΖΕΨΕ",
  "ΧΥΜΑΣ",
  "ΚΑΜΑΝ",
  "ΕΚΟΒΕ",
  "ΣΑΦΕΣ",
  "ΑΦΥΙΑ",
  "ΜΙΛΑΣ",
  "ΣΥΚΙΑ",
  "ΑΓΡΩΝ",
  "ΒΡΑΣΗ",
  "ΕΙΝΑΙ",
  "ΠΑΝΕΣ",
  "ΧΟΛΩΝ",
  "ΕΠΩΔΟ",
  "ΚΕΤΣΕ",
  "ΣΤΗΘΗ",
  "ΡΟΛΟΥ",
  "ΟΛΚΟΥ",
  "ΚΩΝΟΣ",
  "ΨΑΡΙΑ",
  "ΔΕΙΝΕ",
  "ΤΟΤΕΜ",
  "ΒΟΣΚΩ",
  "ΡΟΔΙΑ",
  "ΒΙΩΘΩ",
  "ΑΣΕΒΑ",
  "ΝΕΦΤΙ",
  "ΑΚΡΕΣ",
  "ΠΕΥΚΟ",
  "ΖΥΜΩΝ",
  "ΠΟΡΡΩ",
  "ΝΩΔΟΣ",
  "ΡΟΣΤΟ",
  "ΧΑΖΟΥ",
  "ΦΤΑΣΕ",
  "ΟΝΤΑΣ",
  "ΛΗΠΤΗ",
  "ΧΕΣΤΕ",
  "ΑΓΑΜΑ",
  "ΟΡΜΩΝ",
  "ΤΡΕΦΩ",
  "ΕΨΑΞΑ",
  "ΤΙΜΙΑ",
  "ΞΥΛΑΣ",
  "ΔΡΑΤΕ",
  "ΧΥΣΕΙ",
  "ΨΟΦΑΩ",
  "ΘΥΜΩΝ",
  "ΑΣΙΚΗ",
  "ΑΡΩΓΗ",
  "ΜΥΩΔΗ",
  "ΟΔΟΥΣ",
  "ΟΞΟΝΗ",
  "ΒΓΗΚΕ",
  "ΘΥΡΕΕ",
  "ΣΥΡΕΙ",
  "ΚΟΨΤΕ",
  "ΙΞΟΥΣ",
  "ΙΑΙΝΑ",
  "ΚΩΝΟΥ",
  "ΣΑΡΓΕ",
  "ΠΝΙΓΩ",
  "ΦΥΚΟΣ",
  "ΒΙΩΝΑ",
  "ΣΠΡΕΙ",
  "ΑΛΥΠΕ",
  "ΤΡΩΤΕ",
  "ΡΥΠΟΙ",
  "ΚΡΥΨΕ",
  "ΕΔΥΕΣ",
  "ΤΡΙΤΑ",
  "ΔΙΦΡΟ",
  "ΛΥΧΝΕ",
  "ΡΑΙΖΑ",
  "ΟΡΙΖΕ",
  "ΚΩΦΟΥ",
  "ΚΟΡΗΣ",
  "ΚΥΡΙΟ",
  "ΨΗΤΟΣ",
  "ΣΕΠΤΗ",
  "ΝΟΤΟΙ",
  "ΕΜΑΘΑ",
  "ΞΥΡΟΣ",
  "ΛΙΚΝΟ",
  "ΘΑΜΝΕ",
  "ΡΙΠΗΣ",
  "ΚΥΡΙΕ",
  "ΤΟΙΧΟ",
  "ΣΤΑΖΩ",
  "ΕΛΑΦΟ",
  "ΝΟΗΤΟ",
  "ΔΟΜΕΙ",
  "ΠΕΨΙΣ",
  "ΑΛΕΣΕ",
  "ΗΣΥΧΑ",
  "ΠΙΑΝΕ",
  "ΕΡΙΟΥ",
  "ΥΓΡΩΝ",
  "ΗΛΟΥΣ",
  "ΒΡΙΘΩ",
  "ΜΕΘΑΩ",
  "ΡΑΨΟΥ",
  "ΦΘΟΡΑ",
  "ΠΟΥΡΟ",
  "ΛΙΚΝΑ",
  "ΜΑΣΙΑ",
  "ΒΟΛΟΣ",
  "ΠΝΟΩΝ",
  "ΠΙΝΩΝ",
  "ΧΕΖΟΥ",
  "ΑΙΤΙΕ",
  "ΨΑΞΑΝ",
  "ΑΔΕΤΟ",
  "ΓΡΥΠΗ",
  "ΓΑΤΙΑ",
  "ΤΥΡΩΝ",
  "ΛΙΘΟΥ",
  "ΗΡΘΗΝ",
  "ΤΡΙΖΩ",
  "ΡΙΧΤΟ",
  "ΣΕΛΩΝ",
  "ΣΚΙΝΕ",
  "ΑΘΩΟΥ",
  "ΒΑΛΤΑ",
  "ΕΦΑΓΕ",
  "ΦΙΝΙΣ",
  "ΜΥΓΑΣ",
  "ΦΗΓΟΥ",
  "ΖΥΓΟΥ",
  "ΑΦΙΛΟ",
  "ΣΚΥΨΩ",
  "ΟΡΜΑΝ",
  "ΦΙΝΕΣ",
  "ΒΑΤΟΙ",
  "ΛΑΤΡΑ",
  "ΡΑΓΩΝ",
  "ΧΡΙΖΩ",
  "ΣΚΑΨΕ",
  "ΡΗΤΩΝ",
  "ΟΡΑΤΗ",
  "ΑΧΝΟΥ",
  "ΛΑΓΝΑ",
  "ΠΡΙΒΕ",
  "ΧΕΡΙΑ",
  "ΡΑΙΒΑ",
  "ΟΥΡΑΣ",
  "ΕΝΩΣΗ",
  "ΤΑΙΣΕ",
  "ΟΡΜΙΑ",
  "ΚΡΑΞΩ",
  "ΩΡΑΙΟ",
  "ΝΕΑΖΩ",
  "ΚΟΡΟΣ",
  "ΣΑΥΡΑ",
  "ΤΑΦΕΙ",
  "ΔΕΝΑΝ",
  "ΠΑΙΣΑ",
  "ΒΟΛΒΕ",
  "ΝΩΠΕΣ",
  "ΠΛΟΚΗ",
  "ΘΥΣΙΑ",
  "ΠΕΝΘΗ",
  "ΔΩΣΤΕ",
  "ΤΙΓΡΗ",
  "ΑΒΙΟΥ",
  "ΨΥΞΤΕ",
  "ΞΕΡΙΑ",
  "ΒΑΣΤΑ",
  "ΚΛΑΙΣ",
  "ΕΡΙΔΑ",
  "ΓΗΡΙΑ",
  "ΦΥΤΟΝ",
  "ΕΘΙΣΑ",
  "ΠΛΩΤΗ",
  "ΠΕΝΗΣ",
  "ΑΛΩΝΩ",
  "ΨΗΦΟΥ",
  "ΘΕΙΩΝ",
  "ΚΑΠΟΥ",
  "ΝΤΕΦΙ",
  "ΝΟΜΟΝ",
  "ΜΕΖΕΣ",
  "ΑΠΥΡΟ",
  "ΙΔΡΥΑ",
  "ΛΟΧΩΝ",
  "ΕΝΩΝΩ",
  "ΨΕΛΕΙ",
  "ΣΟΜΠΑ",
  "ΣΚΙΡΟ",
  "ΑΥΤΙΑ",
  "ΕΡΙΖΩ",
  "ΦΥΛΛΟ",
  "ΦΙΛΑΝ",
  "ΓΝΕΥΩ",
  "ΕΩΛΟΥ",
  "ΚΟΚΚΟ",
  "ΠΟΡΤΟ",
  "ΟΡΚΟΣ",
  "ΚΟΚΚΕ",
  "ΑΝΑΓΕ",
  "ΕΣΑΞΑ",
  "ΠΑΙΖΕ",
  "ΡΑΤΣΑ",
  "ΘΕΤΗΣ",
  "ΚΑΨΤΕ",
  "ΠΥΛΗΣ",
  "ΓΑΜΨΟ",
  "ΠΤΩΧΟ",
  "ΚΛΙΝΕ",
  "ΠΙΤΩΝ",
  "ΚΑΒΓΑ",
  "ΜΩΡΗΣ",
  "ΔΙΚΗΝ",
  "ΒΡΩΜΗ",
  "ΛΕΙΕΣ",
  "ΧΩΡΑΝ",
  "ΠΟΝΟΣ",
  "ΑΨΗΛΟ",
  "ΘΕΡΙΟ",
  "ΣΕΙΣΩ",
  "ΑΘΕΛΑ",
  "ΣΕΧΤΑ",
  "ΕΣΥΡΑ",
  "ΔΑΝΔΗ",
  "ΛΗΡΟΣ",
  "ΑΧΝΩΝ",
  "ΣΚΑΓΙ",
  "ΟΛΙΓΟ",
  "ΥΓΙΩΝ",
  "ΦΟΡΤΕ",
  "ΤΟΙΧΕ",
  "ΦΛΟΜΕ",
  "ΣΩΡΟΥ",
  "ΕΡΠΗΣ",
  "ΕΡΗΜΕ",
  "ΑΝΙΣΑ",
  "ΥΦΑΛΕ",
  "ΡΟΤΕΣ",
  "ΣΤΟΥΣ",
  "ΕΥΑΓΗ",
  "ΑΡΑΔΑ",
  "ΒΟΕΙΕ",
  "ΗΣΣΩΝ",
  "ΕΖΕΥΕ",
  "ΦΛΟΙΟ",
  "ΚΡΟΣΕ",
  "ΑΔΥΤΗ",
  "ΘΕΙΚΗ",
  "ΟΞΙΚΕ",
  "ΙΣΩΝΕ",
  "ΑΡΘΕΙ",
  "ΝΟΕΡΕ",
  "ΨΟΦΟΙ",
  "ΚΩΛΥΑ",
  "ΒΡΕΞΩ",
  "ΑΓΝΟΙ",
  "ΓΕΝΟΣ",
  "ΑΛΑΛΟ",
  "ΦΥΡΟΥ",
  "ΨΕΛΛΑ",
  "ΒΥΝΗΣ",
  "ΛΩΛΟΣ",
  "ΡΗΣΟΙ",
  "ΠΟΥΝΕ",
  "ΓΡΙΦΕ",
  "ΧΑΒΡΑ",
  "ΓΕΙΣΟ",
  "ΦΟΡΑΩ",
  "ΚΕΣΙΟ",
  "ΞΕΧΝΑ",
  "ΠΡΙΜΟ",
  "ΨΥΧΩΝ",
  "ΣΑΤΤΩ",
  "ΝΙΨΕΙ",
  "ΟΧΤΟΥ",
  "ΠΥΡΕΞ",
  "ΑΓΙΟΥ",
  "ΙΑΤΡΕ",
  "ΖΕΥΕΙ",
  "ΘΕΡΙΑ",
  "ΠΑΓΚΕ",
  "ΠΑΣΕΣ",
  "ΑΜΑΘΗ",
  "ΠΑΙΝΩ",
  "ΑΤΙΜΟ",
  "ΧΩΡΟΙ",
  "ΙΓΝΥΑ",
  "ΠΟΡΝΗ",
  "ΔΗΛΟΣ",
  "ΦΡΟΥΤ",
  "ΝΟΝΟΥ",
  "ΣΟΔΕΣ",
  "ΚΟΛΟΝ",
  "ΦΑΚΕΣ",
  "ΣΚΕΠΑ",
  "ΑΤΟΝΗ",
  "ΠΟΔΙΑ",
  "ΟΠΟΙΑ",
  "ΓΟΡΓΟ",
  "ΘΥΝΝΟ",
  "ΤΗΡΑΝ",
  "ΤΣΙΠΑ",
  "ΚΩΜΟΥ",
  "ΤΥΡΟΣ",
  "ΒΛΕΠΩ",
  "ΧΝΩΤΑ",
  "ΔΟΣΑΣ",
  "ΠΗΧΤΕ",
  "ΠΕΖΟΥ",
  "ΚΑΨΟΥ",
  "ΤΑΡΣΟ",
  "ΧΛΙΔΗ",
  "ΠΙΤΕΣ",
  "ΚΟΥΚΙ",
  "ΙΩΔΙΟ",
  "ΕΤΕΡΗ",
  "ΗΧΗΡΗ",
  "ΤΣΟΧΑ",
  "ΤΥΠΤΩ",
  "ΔΗΘΕΝ",
  "ΑΒΑΦΗ",
  "ΞΟΡΚΙ",
  "ΦΡΑΣΗ",
  "ΑΠΛΑΣ",
  "ΧΟΡΙΑ",
  "ΦΘΟΝΕ",
  "ΧΑΣΤΕ",
  "ΑΕΝΑΗ",
  "ΕΡΙΖΑ",
  "ΑΡΓΗΣ",
  "ΑΧΑΡΕ",
  "ΓΙΟΥΣ",
  "ΘΑΛΛΟ",
  "ΚΛΙΣΕ",
  "ΩΙΔΙΟ",
  "ΟΡΙΟΥ",
  "ΛΟΞΩΝ",
  "ΣΤΥΨΕ",
  "ΗΒΙΚΕ",
  "ΕΜΑΘΕ",
  "ΕΒΑΝΕ",
  "ΚΕΝΕΣ",
  "ΖΗΤΑΩ",
  "ΠΙΝΕΣ",
  "ΘΕΙΚΕ",
  "ΝΟΥΛΑ",
  "ΔΥΝΗΣ",
  "ΕΝΑΓΩ",
  "ΙΔΩΘΩ",
  "ΑΙΤΙΑ",
  "ΑΡΩΓΕ",
  "ΛΑΒΗΣ",
  "ΝΟΣΕΙ",
  "ΧΑΛΚΑ",
  "ΜΑΛΕΣ",
  "ΦΡΑΞΩ",
  "ΚΟΜΨΑ",
  "ΞΥΛΟΝ",
  "ΧΑΡΗΣ",
  "ΗΡΕΜΩ",
  "ΠΑΘΟΣ",
  "ΨΗΦΟΣ",
  "ΣΚΑΕΙ",
  "ΑΜΠΡΙ",
  "ΑΨΗΛΕ",
  "ΓΑΤΟΥ",
  "ΑΒΑΦΟ",
  "ΣΟΡΟΥ",
  "ΑΦΑΝΗ",
  "ΨΩΜΑΣ",
  "ΦΙΚΟΣ",
  "ΑΝΟΥΝ",
  "ΛΕΤΣΕ",
  "ΕΡΑΝΑ",
  "ΒΟΥΤΩ",
  "ΗΘΙΚΟ",
  "ΩΘΗΘΩ",
  "ΑΨΟΓΗ",
  "ΚΑΒΕΣ",
  "ΜΑΓΜΑ",
  "ΠΛΑΤΗ",
  "ΚΥΝΟΣ",
  "ΔΡΕΨΩ",
  "ΤΗΞΙΣ",
  "ΟΔΗΓΩ",
  "ΟΛΙΚΟ",
  "ΖΕΡΒΙ",
  "ΑΠΟΝΕ",
  "ΚΥΡΤΟ",
  "ΤΟΝΟΥ",
  "ΜΑΓΟΣ",
  "ΒΟΣΚΕ",
  "ΛΟΧΜΗ",
  "ΟΛΚΗΣ",
  "ΟΜΒΡΕ",
  "ΕΡΗΜΑ",
  "ΓΗΙΝΕ",
  "ΑΣΚΕΙ",
  "ΔΙΟΠΕ",
  "ΤΡΙΤΟ",
  "ΡΙΣΚΟ",
  "ΕΨΥΧΕ",
  "ΦΕΡΝΕ",
  "ΙΩΔΩΝ",
];

export const GAME_WORDS: string[] = [
  "ΦΑΥΛΑ",
  "ΣΟΥΖΑ",
  "ΟΡΝΕΟ",
  "ΠΛΕΕΙ",
  "ΜΑΚΡΥ",
  "ΑΓΑΠΗ",
  "ΕΝΩΣΕ",
  "ΚΡΑΝΗ",
  "ΔΕΝΕΙ",
  "ΚΑΛΩΝ",
  "ΓΡΑΣΑ",
  "ΑΚΟΠΗ",
  "ΛΕΥΚΟ",
  "ΡΑΒΔΟ",
  "ΠΛΕΩΝ",
  "ΘΕΤΟΙ",
  "ΞΥΣΟΥ",
  "ΡΟΜΠΑ",
  "ΨΑΞΕΙ",
  "ΑΝΘΕΙ",
  "ΑΒΓΩΝ",
  "ΚΑΥΤΗ",
  "ΩΘΕΙΣ",
  "ΛΑΓΟΣ",
  "ΔΟΤΟΣ",
  "ΣΚΥΒΩ",
  "ΚΟΦΤΑ",
  "ΑΡΙΕΣ",
  "ΑΙΣΙΟ",
  "ΑΝΤΡΟ",
  "ΝΥΦΗΣ",
  "ΧΥΜΩΝ",
  "ΠΙΕΖΕ",
  "ΑΤΟΠΗ",
  "ΝΩΠΗΣ",
  "ΣΚΑΨΩ",
  "ΖΟΜΠΙ",
  "ΤΣΑΡΟ",
  "ΒΡΕΘΩ",
  "ΠΑΘΕΙ",
  "ΕΠΑΘΑ",
  "ΔΙΩΞΕ",
  "ΤΡΙΨΩ",
  "ΥΨΩΝΕ",
  "ΓΛΑΡΕ",
  "ΑΓΕΛΗ",
  "ΧΩΣΑΝ",
  "ΒΟΥΝΟ",
  "ΠΕΤΡΑ",
  "ΣΕΜΝΕ",
  "ΑΒΑΦΕ",
  "ΜΑΝΑΣ",
  "ΖΕΡΒΑ",
  "ΠΑΓΟΙ",
  "ΘΥΡΑΣ",
  "ΓΔΑΡΩ",
  "ΒΡΙΖΩ",
  "ΑΝΘΟΙ",
  "ΜΕΣΤΑ",
  "ΚΑΡΕΣ",
  "ΧΑΡΤΗ",
  "ΒΙΑΙΟ",
  "ΑΠΟΨΗ",
  "ΜΟΥΦΑ",
  "ΑΤΥΠΕ",
  "ΜΝΗΜΑ",
  "ΙΔΙΟΣ",
  "ΠΙΕΖΩ",
  "ΔΥΤΗΣ",
  "ΘΟΛΕΣ",
  "ΑΝΔΡΟ",
  "ΦΤΑΙΩ",
  "ΨΑΧΝΩ",
  "ΤΥΧΕΙ",
  "ΑΦΑΝΑ",
  "ΠΡΟΖΑ",
  "ΟΙΚΙΑ",
  "ΒΗΧΑΣ",
  "ΓΑΖΕΣ",
  "ΕΠΑΥΕ",
  "ΑΙΩΝΑ",
  "ΒΕΛΟΣ",
  "ΓΕΡΟΥ",
  "ΓΑΤΕΣ",
  "ΛΟΙΠΟ",
  "ΛΥΠΕΣ",
  "ΘΗΚΗΣ",
  "ΓΕΝΕΙ",
  "ΚΟΥΛΕ",
  "ΠΗΛΟΙ",
  "ΑΡΙΔΑ",
  "ΜΥΞΕΣ",
  "ΠΛΟΙΑ",
  "ΡΟΥΧΑ",
  "ΣΤΑΛΑ",
  "ΟΠΑΔΟ",
  "ΘΝΗΤΕ",
  "ΠΑΛΜΕ",
  "ΓΑΤΩΝ",
  "ΕΚΟΨΕ",
  "ΦΘΗΝΟ",
  "ΥΨΩΣΑ",
  "ΛΗΞΑΝ",
  "ΕΥΧΩΝ",
  "ΠΙΤΣΑ",
  "ΑΠΑΘΗ",
  "ΦΡΙΞΩ",
  "ΜΑΔΑΩ",
  "ΤΙΛΟΣ",
  "ΕΘΑΨΑ",
  "ΜΠΕΤΟ",
  "ΠΟΣΩΝ",
  "ΜΟΛΟΥ",
  "ΛΑΧΝΟ",
  "ΦΑΡΣΑ",
  "ΗΡΕΜΑ",
  "ΙΧΘΥΣ",
  "ΕΠΝΕΕ",
  "ΣΙΩΠΩ",
  "ΟΞΙΑΣ",
  "ΚΟΥΠΑ",
  "ΤΑΦΩΝ",
  "ΦΟΒΟΥ",
  "ΠΑΛΜΟ",
  "ΟΓΚΟΙ",
  "ΘΕΛΕΙ",
  "ΠΛΑΚΙ",
  "ΛΑΡΟΣ",
  "ΒΡΕΞΕ",
  "ΨΗΛΕΣ",
  "ΑΓΟΝΟ",
  "ΤΙΛΙΟ",
  "ΚΟΛΙΕ",
  "ΑΒΑΤΟ",
  "ΔΟΥΛΕ",
  "ΥΜΝΟΣ",
  "ΑΠΟΡΟ",
  "ΛΟΞΑΣ",
  "ΦΟΡΤΟ",
  "ΘΛΙΨΕ",
  "ΑΠΤΕΣ",
  "ΟΠΛΩΝ",
  "ΣΠΑΣΕ",
  "ΤΕΦΡΑ",
  "ΙΣΑΛΑ",
  "ΓΔΥΣΕ",
  "ΒΡΕΦΗ",
  "ΒΑΘΩΝ",
  "ΓΔΥΝΩ",
  "ΚΑΔΡΟ",
  "ΑΛΕΣΑ",
  "ΡΕΝΤΑ",
  "ΝΙΩΘΕ",
  "ΣΚΙΖΕ",
  "ΠΕΤΟΥ",
  "ΑΝΙΣΗ",
  "ΣΟΓΙΑ",
  "ΑΤΑΚΑ",
  "ΣΟΥΞΕ",
  "ΠΟΙΕΣ",
  "ΑΠΝΟΗ",
  "ΤΕΡΜΑ",
  "ΜΠΗΓΩ",
  "ΔΕΡΜΑ",
  "ΑΛΜΗΣ",
  "ΕΛΥΣΕ",
  "ΜΥΣΤΗ",
  "ΑΣΤΡΟ",
  "ΧΙΛΙΑ",
  "ΑΛΑΝΙ",
  "ΜΟΥΡΗ",
  "ΤΡΩΓΕ",
  "ΠΕΡΝΩ",
  "ΚΟΜΠΟ",
  "ΤΕΚΝΑ",
  "ΞΙΦΟΣ",
  "ΟΡΜΗΣ",
  "ΚΟΦΤΟ",
  "ΜΕΡΑΣ",
  "ΚΥΚΝΟ",
  "ΠΟΣΟΣ",
  "ΓΑΜΨΕ",
  "ΞΕΝΕΣ",
  "ΧΑΡΑΣ",
  "ΕΡΑΒΑ",
  "ΝΤΥΘΩ",
  "ΑΚΟΠΟ",
  "ΖΩΜΟΣ",
  "ΓΑΜΨΑ",
  "ΧΡΕΟΣ",
  "ΑΤΥΧΕ",
  "ΣΚΕΤΟ",
  "ΚΟΥΦΟ",
  "ΒΑΘΙΑ",
  "ΩΣΠΟΥ",
  "ΕΒΑΛΑ",
  "ΜΠΑΛΑ",
  "ΚΙΣΤΗ",
  "ΜΕΣΟΣ",
  "ΧΑΛΑΣ",
  "ΑΟΠΛΑ",
  "ΑΧΥΡΑ",
  "ΠΑΤΣΑ",
  "ΦΟΡΟΣ",
  "ΕΦΗΒΗ",
  "ΠΥΚΝΟ",
  "ΣΑΤΕΝ",
  "ΜΥΤΗΣ",
  "ΑΜΦΙΟ",
  "ΠΑΝΙΑ",
  "ΤΑΜΙΑ",
  "ΕΞΩΘΩ",
  "ΑΤΕΛΗ",
  "ΑΦΕΘΩ",
  "ΖΩΗΡΗ",
  "ΙΛΑΡΑ",
  "ΠΡΩΗΝ",
  "ΦΙΔΙΑ",
  "ΜΕΓΑΣ",
  "ΜΟΝΕΣ",
  "ΓΗΙΝΟ",
  "ΓΟΕΡΗ",
  "ΑΕΡΑΣ",
  "ΑΖΩΤΟ",
  "ΠΗΧΗΣ",
  "ΦΥΓΕΣ",
  "ΠΛΟΙΟ",
  "ΑΘΩΕΣ",
  "ΣΑΙΝΙ",
  "ΖΟΥΛΑ",
  "ΤΟΚΟΣ",
  "ΕΘΕΤΑ",
  "ΧΑΛΒΑ",
  "ΣΚΥΛΟ",
  "ΣΤΙΒΕ",
  "ΚΟΡΝΑ",
  "ΙΠΠΟΙ",
  "ΤΙΜΗΣ",
  "ΒΩΒΟΙ",
  "ΒΑΛΤΟ",
  "ΤΟΜΟΣ",
  "ΧΤΥΠΩ",
  "ΝΟΝΕΣ",
  "ΦΑΚΟΙ",
  "ΣΤΑΘΩ",
  "ΜΙΚΡΑ",
  "ΒΙΟΛΙ",
  "ΑΜΦΙΑ",
  "ΑΓΡΟΣ",
  "ΛΙΚΕΡ",
  "ΟΛΙΚΗ",
  "ΕΓΚΥΟ",
  "ΒΩΜΟΣ",
  "ΝΟΘΟΥ",
  "ΜΙΛΕΙ",
  "ΑΡΑΓΕ",
  "ΥΠΟΨΗ",
  "ΑΓΑΘΗ",
  "ΦΤΕΡΗ",
  "ΠΗΡΟΣ",
  "ΚΑΙΡΟ",
  "ΑΝΕΜΟ",
  "ΘΕΡΜΗ",
  "ΣΑΛΤΟ",
  "ΝΤΑΛΑ",
  "ΣΑΚΟΣ",
  "ΑΥΛΟΙ",
  "ΓΑΜΨΗ",
  "ΧΑΡΕΣ",
  "ΠΑΧΥΣ",
  "ΔΕΙΤΕ",
  "ΣΚΥΛΑ",
  "ΣΚΙΑΣ",
  "ΠΡΑΞΗ",
  "ΜΟΥΡΟ",
  "ΗΠΙΟΣ",
  "ΦΑΝΕΙ",
  "ΑΔΗΛΑ",
  "ΔΙΧΤΥ",
  "ΛΟΥΣΩ",
  "ΟΧΗΜΑ",
  "ΚΑΛΩΣ",
  "ΡΗΧΟΙ",
  "ΠΙΑΝΩ",
  "ΘΕΙΟΣ",
  "ΜΥΧΟΙ",
  "ΦΕΥΓΩ",
  "ΦΩΤΟΣ",
  "ΑΜΟΛΩ",
  "ΒΙΑΣΩ",
  "ΔΙΨΑΩ",
  "ΕΥΦΥΗ",
  "ΗΡΩΑΣ",
  "ΜΟΛΟΙ",
  "ΠΥΛΕΣ",
  "ΛΙΜΕΣ",
  "ΑΙΤΙΟ",
  "ΜΠΡΟΣ",
  "ΠΟΘΟΥ",
  "ΦΑΣΜΑ",
  "ΑΡΑΚΑ",
  "ΑΛΛΟΝ",
  "ΚΥΒΟΣ",
  "ΒΙΩΣΩ",
  "ΕΞΥΣΑ",
  "ΕΤΑΞΑ",
  "ΦΥΛΑΣ",
  "ΓΟΡΓΗ",
  "ΒΟΗΘΩ",
  "ΟΡΘΕΣ",
  "ΓΕΝΝΑ",
  "ΧΝΩΤΟ",
  "ΒΟΤΚΑ",
  "ΣΤΗΣΕ",
  "ΜΑΝΙΑ",
  "ΝΟΤΟΣ",
  "ΡΟΦΟΙ",
  "ΛΑΘΩΝ",
  "ΙΣΧΥΣ",
  "ΠΑΓΟΣ",
  "ΜΗΝΕΣ",
  "ΕΦΕΞΑ",
  "ΜΙΚΤΟ",
  "ΖΗΣΤΕ",
  "ΜΥΧΙΑ",
  "ΕΙΧΕΣ",
  "ΨΗΦΙΟ",
  "ΝΟΕΙΣ",
  "ΛΟΦΟΣ",
  "ΔΙΩΞΩ",
  "ΣΚΕΤΑ",
  "ΑΔΩΡΟ",
  "ΝΟΣΟΣ",
  "ΒΓΕΙΣ",
  "ΘΡΗΝΩ",
  "ΦΤΥΣΕ",
  "ΚΛΑΣΗ",
  "ΑΔΙΚΩ",
  "ΤΣΙΜΑ",
  "ΥΠΤΙΑ",
  "ΑΓΕΝΗ",
  "ΕΛΚΥΩ",
  "ΓΟΡΓΕ",
  "ΑΜΥΧΗ",
  "ΝΑΥΛΑ",
  "ΑΓΙΩΝ",
  "ΟΜΑΛΑ",
  "ΑΧΙΝΟ",
  "ΕΒΑΨΕ",
  "ΑΝΘΟΣ",
  "ΤΕΡΑΣ",
  "ΚΑΕΙΣ",
  "ΕΡΗΜΗ",
  "ΝΙΩΘΩ",
  "ΑΝΔΡΑ",
  "ΕΡΩΤΑ",
  "ΚΥΑΝΑ",
  "ΓΕΝΝΩ",
  "ΠΙΟΝΙ",
  "ΟΦΕΛΗ",
  "ΑΔΥΤΟ",
  "ΓΕΥΤΩ",
  "ΠΛΑΤΥ",
  "ΜΕΙΝΕ",
  "ΑΡΑΞΑ",
  "ΣΑΛΙΟ",
  "ΧΙΡΩΝ",
  "ΦΘΑΣΩ",
  "ΤΥΦΛΕ",
  "ΑΚΑΚΑ",
  "ΠΤΗΝΟ",
  "ΚΑΙΤΕ",
  "ΦΕΓΓΩ",
  "ΠΙΕΣΗ",
  "ΝΟΜΟΥ",
  "ΜΟΥΣΑ",
  "ΧΩΡΑΣ",
  "ΑΝΗΚΕ",
  "ΠΟΘΩΝ",
  "ΤΟΛΜΩ",
  "ΜΠΑΚΑ",
  "ΑΡΓΙΑ",
  "ΦΕΙΔΩ",
  "ΠΕΤΑΝ",
  "ΡΗΤΡΑ",
  "ΧΑΖΙΑ",
  "ΤΡΙΤΕ",
  "ΛΙΤΟΙ",
  "ΥΨΩΝΩ",
  "ΡΙΓΟΣ",
  "ΑΛΥΤΗ",
  "ΞΥΣΙΑ",
  "ΧΑΡΜΑ",
  "ΣΥΧΝΑ",
  "ΟΡΚΟΙ",
  "ΝΟΗΤΑ",
  "ΕΧΘΡΑ",
  "ΠΕΤΩΝ",
  "ΣΟΦΗΣ",
  "ΛΙΜΟΙ",
  "ΑΠΑΛΗ",
  "ΤΙΓΚΑ",
  "ΠΛΕΚΕ",
  "ΤΥΧΑΝ",
  "ΛΕΙΩΝ",
  "ΠΛΑΝΕ",
  "ΠΡΑΣΟ",
  "ΣΠΑΛΑ",
  "ΚΟΨΙΑ",
  "ΘΑΡΡΩ",
  "ΓΡΑΨΕ",
  "ΚΟΥΝΑ",
  "ΣΑΦΩΣ",
  "ΑΡΧΕΣ",
  "ΑΜΝΟΣ",
  "ΖΑΛΕΣ",
  "ΔΕΣΟΥ",
  "ΕΘΕΣΕ",
  "ΤΟΜΕΣ",
  "ΜΑΙΑΣ",
  "ΠΟΙΟΝ",
  "ΓΟΗΤΑ",
  "ΦΤΑΙΣ",
  "ΚΟΙΛΟ",
  "ΕΠΑΨΕ",
  "ΦΥΓΕΙ",
  "ΠΙΚΡΟ",
  "ΒΡΕΧΩ",
  "ΧΗΡΟΙ",
  "ΝΑΝΟΥ",
  "ΣΑΓΡΕ",
  "ΡΑΦΙΑ",
  "ΣΑΧΛΕ",
  "ΡΟΛΟΙ",
  "ΑΓΑΘΑ",
  "ΦΑΤΣΑ",
  "ΣΠΙΘΑ",
  "ΤΙΜΩΝ",
  "ΚΟΠΗΣ",
  "ΛΙΓΝΑ",
  "ΠΙΕΣΕ",
  "ΤΑΙΖΩ",
  "ΑΝΘΟΥ",
  "ΑΔΕΙΟ",
  "ΤΡΙΤΗ",
  "ΨΑΧΝΟ",
  "ΛΑΜΙΑ",
  "ΕΥΗΧΗ",
  "ΓΟΥΝΑ",
  "ΚΑΜΨΩ",
  "ΑΞΟΝΑ",
  "ΣΥΖΕΙ",
  "ΦΑΚΗΣ",
  "ΜΙΚΤΑ",
  "ΨΥΧΡΗ",
  "ΝΟΣΟΥ",
  "ΠΡΕΦΑ",
  "ΚΟΙΝΟ",
  "ΚΑΜΨΗ",
  "ΘΡΟΝΟ",
  "ΚΟΤΕΣ",
  "ΦΛΕΓΩ",
  "ΟΒΙΔΑ",
  "ΣΑΧΛΑ",
  "ΗΠΙΟΙ",
  "ΖΕΝΙΘ",
  "ΠΗΛΟΥ",
  "ΙΡΙΔΑ",
  "ΦΛΟΓΑ",
  "ΔΕΧΤΩ",
  "ΧΑΔΙΑ",
  "ΝΩΜΟΙ",
  "ΡΑΜΜΑ",
  "ΜΕΙΝΩ",
  "ΙΣΙΕΣ",
  "ΟΣΧΕΟ",
  "ΠΛΕΚΩ",
  "ΑΕΡΓΟ",
  "ΖΩΑΚΙ",
  "ΥΜΝΕΙ",
  "ΒΙΑΙΗ",
  "ΧΑΙΤΗ",
  "ΝΟΗΜΑ",
  "ΟΧΘΕΣ",
  "ΠΑΛΙΑ",
  "ΦΥΤΡΑ",
  "ΣΑΚΕΣ",
  "ΙΔΕΑΣ",
  "ΚΑΙΚΙ",
  "ΝΟΣΟΙ",
  "ΕΠΑΦΗ",
  "ΠΛΕΝΩ",
  "ΥΔΑΤΑ",
  "ΦΑΒΑΣ",
  "ΚΟΤΛΕ",
  "ΕΨΗΝΑ",
  "ΠΤΩΣΗ",
  "ΟΔΕΥΩ",
  "ΑΠΤΟΣ",
  "ΔΙΟΔΟ",
  "ΘΝΗΤΗ",
  "ΒΟΣΚΑ",
  "ΛΗΓΕΙ",
  "ΛΑΥΡΑ",
  "ΤΥΦΛΟ",
  "ΑΧΝΟΣ",
  "ΛΟΥΡΙ",
  "ΧΩΡΙΣ",
  "ΚΟΠΩΝ",
  "ΑΥΠΝΗ",
  "ΓΥΡΟΣ",
  "ΟΥΡΙΕ",
  "ΒΟΜΒΕ",
  "ΝΑΝΟΙ",
  "ΚΕΛΙΑ",
  "ΖΟΥΜΙ",
  "ΨΗΝΑΝ",
  "ΜΗΠΩΣ",
  "ΖΥΓΟΣ",
  "ΑΞΕΝΟ",
  "ΑΞΕΝΑ",
  "ΤΥΠΟΣ",
  "ΝΗΠΙΟ",
  "ΡΙΣΚΑ",
  "ΝΥΜΦΗ",
  "ΖΩΗΡΑ",
  "ΟΠΟΤΕ",
  "ΜΥΤΕΣ",
  "ΛΕΒΙΕ",
  "ΣΟΡΟΣ",
  "ΠΟΛΤΟ",
  "ΠΡΗΞΩ",
  "ΡΟΜΒΟ",
  "ΑΕΡΙΟ",
  "ΟΜΑΛΗ",
  "ΠΛΩΡΗ",
  "ΑΓΡΙΕ",
  "ΕΥΧΕΣ",
  "ΧΟΛΗΣ",
  "ΜΑΔΑΣ",
  "ΓΑΜΠΑ",
  "ΘΟΛΟΥ",
  "ΠΗΡΕΣ",
  "ΕΚΤΗΣ",
  "ΘΑΜΒΑ",
  "ΡΙΞΕΙ",
  "ΚΑΥΤΟ",
  "ΚΡΥΦΕ",
  "ΝΙΩΣΩ",
  "ΤΟΜΗΣ",
  "ΧΑΛΙΑ",
  "ΑΣΥΛΟ",
  "ΣΦΥΡΙ",
  "ΨΙΛΟΙ",
  "ΓΡΙΑΣ",
  "ΕΝΩΝΕ",
  "ΧΑΣΜΑ",
  "ΕΚΟΒΑ",
  "ΦΥΣΑΩ",
  "ΠΗΖΕΙ",
  "ΝΥΞΗΣ",
  "ΦΟΝΩΝ",
  "ΓΙΔΙΑ",
  "ΖΑΛΗΣ",
  "ΒΓΟΥΝ",
  "ΚΑΜΙΑ",
  "ΑΨΥΧΑ",
  "ΠΟΝΑΣ",
  "ΛΥΚΟΣ",
  "ΤΡΕΞΩ",
  "ΚΟΛΠΟ",
  "ΕΦΟΡΟ",
  "ΟΧΙΑΣ",
  "ΟΨΙΜΗ",
  "ΝΟΝΩΝ",
  "ΦΑΡΟΣ",
  "ΧΑΡΟΥ",
  "ΠΛΑΝΩ",
  "ΚΑΠΩΣ",
  "ΕΔΕΣΕ",
  "ΣΤΕΚΩ",
  "ΝΩΠΩΝ",
  "ΧΛΟΜΟ",
  "ΞΕΦΤΩ",
  "ΤΟΥΔΕ",
  "ΛΑΔΙΑ",
  "ΔΟΥΛΗ",
  "ΒΟΥΗΣ",
  "ΑΛΛΕΣ",
  "ΠΤΗΣΗ",
  "ΡΩΤΑΩ",
  "ΔΗΜΟΣ",
  "ΧΩΡΙΑ",
  "ΠΑΥΕΙ",
  "ΠΝΟΕΣ",
  "ΣΑΛΟΙ",
  "ΣΑΡΚΑ",
  "ΛΥΣΣΑ",
  "ΦΟΡΟΙ",
  "ΛΕΠΤΟ",
  "ΞΗΡΑΣ",
  "ΨΑΛΕΙ",
  "ΛΑΘΡΑ",
  "ΕΙΔΕΣ",
  "ΣΥΚΗΣ",
  "ΖΩΙΚΟ",
  "ΗΛΙΟΥ",
  "ΑΛΥΤΑ",
  "ΒΑΨΑΝ",
  "ΑΦΟΒΕ",
  "ΚΕΦΙΑ",
  "ΟΥΛΗΣ",
  "ΨΗΣΟΥ",
  "ΕΡΘΕΙ",
  "ΙΣΤΙΑ",
  "ΦΟΡΕΣ",
  "ΟΡΑΤΑ",
  "ΣΤΙΜΑ",
  "ΣΤΟΕΣ",
  "ΚΑΙΜΕ",
  "ΒΑΦΤΩ",
  "ΛΟΓΟΣ",
  "ΚΛΑΙΩ",
  "ΑΥΛΟΣ",
  "ΚΟΥΤΑ",
  "ΛΥΚΟΙ",
  "ΤΡΕΜΩ",
  "ΙΔΙΟΥ",
  "ΕΔΡΕΣ",
  "ΔΕΞΟΥ",
  "ΜΑΧΕΣ",
  "ΑΤΟΚΑ",
  "ΕΤΕΡΑ",
  "ΤΙΜΑΩ",
  "ΡΩΤΑΣ",
  "ΠΥΡΓΟ",
  "ΕΖΗΣΕ",
  "ΕΧΑΣΑ",
  "ΕΞΤΡΑ",
  "ΠΡΩΤΕ",
  "ΠΕΤΣΑ",
  "ΚΟΥΛΟ",
  "ΧΗΝΕΣ",
  "ΓΕΝΙΑ",
  "ΦΑΝΟΣ",
  "ΧΡΥΣΟ",
  "ΗΛΘΕΣ",
  "ΕΛΚΕΙ",
  "ΔΕΙΞΕ",
  "ΠΝΕΕΙ",
  "ΕΡΜΗΣ",
  "ΓΕΥΣΗ",
  "ΓΡΑΦΕ",
  "ΦΤΩΧΗ",
  "ΚΑΝΕΙ",
  "ΑΤΜΟΥ",
  "ΕΞΥΣΕ",
  "ΔΕΙΡΕ",
  "ΣΤΕΝΗ",
  "ΒΕΡΓΑ",
  "ΑΛΟΓΟ",
  "ΠΤΟΕΙ",
  "ΔΕΙΞΩ",
  "ΠΕΝΘΩ",
  "ΒΓΗΚΑ",
  "ΑΓΑΠΩ",
  "ΒΑΦΗΣ",
  "ΠΑΤΑΩ",
  "ΛΕΥΚΗ",
  "ΒΟΥΒΑ",
  "ΑΒΟΛΗ",
  "ΦΥΣΑΣ",
  "ΑΡΘΡΟ",
  "ΖΩΗΡΟ",
  "ΔΑΡΤΑ",
  "ΑΠΑΤΩ",
  "ΚΡΑΤΗ",
  "ΨΙΛΕΣ",
  "ΦΙΔΕΣ",
  "ΖΕΛΕΣ",
  "ΣΠΑΝΕ",
  "ΔΑΣΩΝ",
  "ΓΙΝΕΙ",
  "ΣΟΦΟΙ",
  "ΜΙΖΕΣ",
  "ΣΑΛΙΑ",
  "ΤΑΒΛΑ",
  "ΑΦΡΟΙ",
  "ΜΕΤΡΑ",
  "ΝΥΧΙΑ",
  "ΔΕΙΝΟ",
  "ΛΟΓΗΣ",
  "ΠΡΙΖΑ",
  "ΞΙΝΟΙ",
  "ΣΚΑΛΙ",
  "ΧΡΩΣΗ",
  "ΜΥΛΟΣ",
  "ΝΟΙΚΙ",
  "ΡΟΓΧΟ",
  "ΖΩΝΕΣ",
  "ΑΣΚΟΙ",
  "ΔΕΞΙΑ",
  "ΑΚΡΟΣ",
  "ΜΑΘΕΙ",
  "ΑΠΛΟΙ",
  "ΣΤΑΣΗ",
  "ΓΟΝΟΣ",
  "ΑΣΣΟΙ",
  "ΓΕΥΜΑ",
  "ΞΥΠΝΩ",
  "ΑΥΤΟΙ",
  "ΕΛΗΞΕ",
  "ΕΦΗΒΟ",
  "ΤΗΡΕΙ",
  "ΓΡΑΣΟ",
  "ΛΑΟΥΣ",
  "ΦΥΓΑΝ",
  "ΩΡΙΜΗ",
  "ΨΕΥΔΗ",
  "ΣΚΕΨΗ",
  "ΣΟΥΠΑ",
  "ΤΡΩΤΟ",
  "ΔΟΛΙΑ",
  "ΠΕΙΣΩ",
  "ΣΤΕΠΑ",
  "ΚΡΥΟΙ",
  "ΛΕΥΚΕ",
  "ΚΝΗΜΗ",
  "ΑΕΤΟΙ",
  "ΕΜΕΙΣ",
  "ΜΠΗΚΑ",
  "ΕΠΙΚΟ",
  "ΚΑΠΝΟ",
  "ΣΤΑΡΙ",
  "ΚΤΗΜΑ",
  "ΔΩΡΕΑ",
  "ΝΙΚΑΝ",
  "ΑΤΟΚΗ",
  "ΚΕΡΜΑ",
  "ΕΥΡΟΣ",
  "ΜΕΡΟΣ",
  "ΚΟΙΤΑ",
  "ΡΙΖΑΣ",
  "ΕΧΤΕΣ",
  "ΝΟΗΤΗ",
  "ΦΟΔΡΑ",
  "ΧΙΟΝΙ",
  "ΑΤΟΠΟ",
  "ΑΦΙΞΗ",
  "ΣΤΥΛΟ",
  "ΦΑΤΝΗ",
  "ΝΟΤΕΣ",
  "ΚΕΡΔΗ",
  "ΠΛΑΚΕ",
  "ΓΕΡΕΣ",
  "ΚΡΙΣΗ",
  "ΜΑΓΟΙ",
  "ΤΡΕΛΟ",
  "ΓΛΥΚΑ",
  "ΑΤΥΧΑ",
  "ΘΕΙΚΟ",
  "ΤΡΙΒΗ",
  "ΕΠΑΝΩ",
  "ΟΧΘΗΣ",
  "ΕΚΑΨΑ",
  "ΤΥΧΕΣ",
  "ΣΤΗΤΕ",
  "ΑΓΛΑΗ",
  "ΑΜΜΟΣ",
  "ΠΕΤΣΙ",
  "ΠΑΤΗΡ",
  "ΡΑΓΕΣ",
  "ΡΑΦΕΣ",
  "ΛΟΙΠΑ",
  "ΦΡΕΝΟ",
  "ΟΞΕΙΣ",
  "ΜΑΤΙΑ",
  "ΑΚΕΦΟ",
  "ΝΑΖΙΑ",
  "ΩΦΕΛΩ",
  "ΩΔΕΙΟ",
  "ΠΛΑΝΟ",
  "ΟΝΤΩΣ",
  "ΨΗΦΙΑ",
  "ΛΙΤΡΑ",
  "ΕΚΡΟΗ",
  "ΨΥΧΟΣ",
  "ΤΑΒΛΙ",
  "ΘΕΡΜΟ",
  "ΣΠΟΡΑ",
  "ΟΣΜΙΟ",
  "ΠΕΔΙΑ",
  "ΒΑΦΕΣ",
  "ΔΙΠΛΑ",
  "ΤΡΟΠΗ",
  "ΑΤΟΜΟ",
  "ΤΡΙΧΑ",
  "ΧΟΡΤΟ",
  "ΠΑΓΙΟ",
  "ΛΕΥΓΑ",
  "ΖΕΣΤΑ",
  "ΓΙΑΚΑ",
  "ΕΙΜΑΙ",
  "ΛΟΓΟΥ",
  "ΑΛΗΘΗ",
  "ΠΟΘΕΙ",
  "ΠΙΚΛΑ",
  "ΣΕΙΡΑ",
  "ΕΜΕΝΑ",
  "ΚΟΥΤΟ",
  "ΣΤΟΛΗ",
  "ΜΟΝΟΣ",
  "ΡΙΖΕΣ",
  "ΨΩΜΙΑ",
  "ΠΛΩΤΟ",
  "ΔΕΙΝΗ",
  "ΩΡΑΙΑ",
  "ΦΤΗΝΑ",
  "ΚΑΗΜΟ",
  "ΓΟΥΔΙ",
  "ΧΛΩΜΗ",
  "ΑΔΗΛΟ",
  "ΛΥΓΜΟ",
  "ΧΟΡΔΗ",
  "ΣΠΙΤΙ",
  "ΑΥΛΗΣ",
  "ΩΑΡΙΟ",
  "ΙΣΤΟΣ",
  "ΓΙΑΛΟ",
  "ΣΚΑΦΗ",
  "ΛΑΝΤΟ",
  "ΑΛΛΗΣ",
  "ΧΩΡΙΟ",
  "ΛΟΞΕΣ",
  "ΠΛΥΘΩ",
  "ΚΟΙΛΑ",
  "ΑΠΑΤΗ",
  "ΠΟΥΛΑ",
  "ΧΑΡΤΙ",
  "ΔΕΙΝΑ",
  "ΩΔΕΙΑ",
  "ΑΨΗΤΟ",
  "ΠΛΕΞΗ",
  "ΒΙΒΛΟ",
  "ΤΡΟΦΗ",
  "ΠΥΚΝΑ",
  "ΑΚΟΥΩ",
  "ΞΥΣΜΑ",
  "ΟΧΥΡΑ",
  "ΓΟΦΟΙ",
  "ΓΥΨΟΙ",
  "ΞΕΣΜΑ",
  "ΒΟΕΙΟ",
  "ΣΠΑΘΙ",
  "ΕΝΑΤΟ",
  "ΕΔΑΦΗ",
  "ΕΣΤΙΑ",
  "ΣΚΑΒΩ",
  "ΠΛΑΣΗ",
  "ΚΟΛΠΑ",
  "ΠΟΥΛΩ",
  "ΓΥΨΟΣ",
  "ΧΛΩΡΟ",
  "ΕΛΗΞΑ",
  "ΠΕΙΡΑ",
  "ΣΟΒΑΣ",
  "ΑΣΗΜΙ",
  "ΔΑΚΡΥ",
  "ΑΡΑΙΗ",
  "ΑΡΜΟΣ",
  "ΝΕΦΩΝ",
  "ΝΟΜΑΣ",
  "ΛΑΣΚΟ",
  "ΕΠΙΝΕ",
  "ΑΡΑΖΩ",
  "ΛΩΤΟΣ",
  "ΚΩΝΟΙ",
  "ΣΤΥΒΩ",
  "ΛΟΞΟΙ",
  "ΠΕΛΜΑ",
  "ΘΑΜΠΗ",
  "ΣΒΗΣΕ",
  "ΓΟΡΓΑ",
  "ΚΟΛΛΑ",
  "ΑΘΩΟΙ",
  "ΤΑΚΤΑ",
  "ΑΓΙΟΣ",
  "ΣΤΕΚΙ",
  "ΓΥΑΛΑ",
  "ΓΟΠΕΣ",
  "ΟΞΙΝΑ",
  "ΑΠΑΧΗ",
  "ΑΓΩΓΗ",
  "ΓΑΝΤΙ",
  "ΧΩΡΟΣ",
  "ΤΖΑΜΙ",
  "ΜΑΝΕΣ",
  "ΔΕΘΕΙ",
  "ΠΩΛΟΙ",
  "ΑΘΛΙΑ",
  "ΦΥΛΛΑ",
  "ΚΡΕΠΑ",
];
