import { WORD_LENGTH } from "../constants/variables";
import Tile from "./Tile";

type Props = { currentGuess: string };

const CurrentRow = ({ currentGuess = "" }: Props) => {
  let tiles = currentGuess.split("");
  let emptyTiles = Array.from(Array(WORD_LENGTH - currentGuess.length));

  return (
    <div className="flex justify-center">
      {tiles.map((letter, i) => (
        <div key={i} className={i + 1 === tiles.length ? "pop-animation" : ""}>
          <Tile letter={letter} />
        </div>
      ))}

      {emptyTiles.map((l, i) => (
        <Tile key={`empty-${i}`} />
      ))}
    </div>
  );
};

export default CurrentRow;
