import {
  TEXT_ABSENT_LETTER,
  TEXT_CORRECT_LETTER,
  TEXT_INFO,
  TEXT_PRESENT_LETTER,
  TITLE_INFO,
} from "../constants/strings";
import { GAME_VERSION } from "../constants/variables";
import CloseIcon from "./CloseIcon";
import InfoRow from "./InfoRow";
import Modal from "./Modal";

type Props = {
  isShown: boolean;
  toggle: () => void;
};

const InfoModal = ({ isShown, toggle }: Props) => {
  return (
    <div
      className={`opacity-0 ${isShown && "opacity-100"} transition ease-in-out`}
    >
      {isShown && (
        <Modal>
          <div className="relative">
            <h2 className="text-center text-xl">{TITLE_INFO}</h2>
            <button onClick={toggle} className="absolute right-0 top-0">
              <CloseIcon />
            </button>
            <hr className="my-2" />
          </div>
          <div className="text-center">
            <p>{TEXT_INFO}</p>
            <div className="my-4">
              <InfoRow solution="ΑΣΣΣΣ" guess="ΑΓΑΠΗ" />
              <p>{TEXT_CORRECT_LETTER}</p>
            </div>
            <div className="my-4">
              <InfoRow solution="ΣΕΣΣΣ" guess="ΦΡΕΝΟ" />
              <p>{TEXT_PRESENT_LETTER}</p>
            </div>
            <div className="my-4">
              <InfoRow solution="ΑΒΕΟΤ" guess="ΛΑΘΟΣ" />
              <p>{TEXT_ABSENT_LETTER}</p>
            </div>
          </div>
          <div className="text-right text-xs">
            <span>v{GAME_VERSION}</span>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default InfoModal;
