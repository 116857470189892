type Props = { toggle: () => void };

const StatsButton = ({ toggle }: Props) => {
  return (
    <button onClick={toggle}>
      <svg
        className="w-6 dark:fill-slate-300"
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 92 92"
      >
        <path
          id="XMLID_78_"
          d="M73,89c-3.3,0-6-2.7-6-6V9c0-3.3,2.7-6,6-6s6,2.7,6,6v74C79,86.3,76.3,89,73,89z M52,83V33.4 c0-3.3-2.7-6-6-6s-6,2.7-6,6V83c0,3.3,2.7,6,6,6S52,86.3,52,83z M25,83V57.8c0-3.3-2.7-6-6-6s-6,2.7-6,6V83c0,3.3,2.7,6,6,6S25,86.3,25,83z"
        />
      </svg>
    </button>
  );
};

export default StatsButton;
