import { MAX_TRIES } from "../constants/variables";
import CurrentRow from "./CurrentRow";
import EmptyRow from "./EmptyRow";
import GuessRow from "./GuessRow";

type Props = { guesses: string[]; currentGuess: string };

const Grid = ({ guesses, currentGuess }: Props) => {
  const emptyRows =
    guesses.length < MAX_TRIES
      ? Array.from(Array(MAX_TRIES - guesses.length - 1))
      : [];

  return (
    <div className="flex flex-1 flex-col justify-center">
      {guesses.map((guess, i) => (
        <GuessRow key={i} guess={guess} />
      ))}
      {guesses.length < MAX_TRIES && <CurrentRow currentGuess={currentGuess} />}

      {emptyRows.map((r, i) => (
        <EmptyRow key={i} />
      ))}
    </div>
  );
};

export default Grid;
