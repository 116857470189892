import { useEffect, useState } from "react";
import "./App.css";
import { Game } from "./components/Game";
import { TEXT_COOKIES, TEXT_NO_CONSENT, TEXT_OK } from "./constants/strings";
import { GAME_DOMAIN } from "./constants/variables";

declare global {
  interface Window {
    dataLayer: any[];
  }
}

const isDev = !window.location.href.includes(GAME_DOMAIN);

const injectGa = () => {
  let script = document.createElement("script");
  script.setAttribute("async", "true");
  script.setAttribute(
    "src",
    "https://www.googletagmanager.com/gtag/js?id=G-BJHKFD5HQH"
  );
  document.head.append(script);

  window.dataLayer = window.dataLayer || [];
  function gtag(a: any, b: any) {
    window.dataLayer.push(arguments);
  }
  gtag("js", new Date());

  gtag("config", "G-BJHKFD5HQH");
};

const removeCookies = () => {
  if (!!document.cookie) {
    let cookies = document.cookie.split(";");

    cookies.forEach((cookie) => {
      let [cookieName] = cookie.split("=");
      document.cookie = `${cookieName}= ; expires = Thu, 01 Jan 1970 00:00:00 GMT`;
    });
  }
};

function App() {
  const [analytics, setAnalytics] = useState(false);
  const [showCookiesModal, setShowCookiesModal] = useState(false);

  useEffect(() => {
    if (!isDev) {
      if (analytics) {
        injectGa();
      }

      let allowAnalytics = JSON.parse(localStorage.allowAnalytics ?? false);
      if (!allowAnalytics) {
        setShowCookiesModal(true);
      } else {
        if (allowAnalytics !== analytics) {
          setAnalytics(allowAnalytics);
        }
      }
    }
  }, [analytics]);

  const noConsent = () => {
    localStorage.allowAnalytics = false;
    setAnalytics(false);
    setShowCookiesModal(false);
    removeCookies();
  };

  const consent = () => {
    localStorage.allowAnalytics = true;
    setAnalytics(true);
    setShowCookiesModal(false);
  };

  return (
    <div className="game-container h-full w-screen select-none bg-gray-200 dark:bg-gray-800">
      <Game />
      {showCookiesModal && (
        <div className="fixed bottom-0 z-20 w-full bg-slate-300 p-3 dark:bg-slate-700 dark:text-slate-300">
          <p className="mb-3">{TEXT_COOKIES}</p>
          <button
            onClick={noConsent}
            className="mr-3 rounded border-2 border-slate-800 px-3 py-1 dark:border-slate-300"
          >
            {TEXT_NO_CONSENT}
          </button>
          <button
            onClick={consent}
            className="mr-3 rounded border-2 border-slate-800 px-3 py-1 dark:border-slate-300"
          >
            {TEXT_OK}
          </button>
        </div>
      )}
    </div>
  );
}

export default App;
