import React, { useEffect, useState } from "react";

type Props = {
  children: React.ReactNode;
  status: "success" | "info" | "danger";
  isShown: boolean;
};

const Alert = (props: Props) => {
  const [shown, setShown] = useState(false);

  useEffect(() => {
    if (props.isShown !== shown) {
      setShown(props.isShown);
    }
  }, [props.isShown, shown]);

  let statusClass = (() => {
    switch (props.status) {
      case "success":
        return "bg-emerald-600 text-slate-300";
      case "info":
        return "dark:bg-slate-700 bg-slate-300 text-slate-800 dark:text-slate-300";
      case "danger":
        return "bg-rose-700 text-slate-300";

      default:
        return "";
    }
  })();

  return (
    <div
      className={`opacity-0 ${
        shown && "opacity-100"
      } absolute top-11 self-center rounded  transition duration-200 ease-in-out`}
    >
      {shown && (
        <div className={`self-center rounded p-3  ${statusClass}`}>
          {props.children}
        </div>
      )}
    </div>
  );
};

export default Alert;
