import { ICON_DELETE, ICON_ENTER } from "../constants/icons";
import Key from "./Key";

type Props = {
  onKey: (key: string) => void;
  guesses: string[];
  solution: string;
};

const Keyboard = ({ onKey, guesses, solution }: Props) => {
  let keyRows = [
    ["Ε", "Ρ", "Τ", "Υ", "Θ", "Ι", "Ο", "Π"],
    ["Α", "Σ", "Δ", "Φ", "Γ", "Η", "Ξ", "Κ", "Λ"],
    ["Ζ", "Χ", "Ψ", "Ω", "Β", "Ν", "Μ"],
  ];

  const getKeyStatus = (key: string) => {
    let status = "";
    guesses.forEach((guess) => {
      if (guess.includes(key)) {
        if (solution.includes(key)) {
          let instances = solution.split(key).length - 1;
          if (instances > 1) {
            let solutionIndexes = solution
              .split("")
              .reduce(
                (acc, curr, i) => (curr === key ? [...acc, i] : acc),
                [] as number[]
              );
            let guessIndexes = guess
              .split("")
              .reduce(
                (acc, curr, i) => (curr === key ? [...acc, i] : acc),
                [] as number[]
              );

            if (solutionIndexes.every((i) => guessIndexes.includes(i))) {
              status = "correct";
            } else {
              if (
                solutionIndexes.some((i) => guessIndexes.includes(i)) &&
                guessIndexes.length > 1
              ) {
                status = status === "correct" ? status : "almost";
              } else if (
                guessIndexes.some((i) => solutionIndexes.includes(i))
              ) {
                status = status === "correct" ? status : "correct-present";
              } else if (guessIndexes.length === solutionIndexes.length) {
                status = status === "correct" ? status : "present";
              } else {
                status = status === "correct" ? status : "almost-present";
              }
            }
          } else {
            if (solution.indexOf(key) === guess.indexOf(key)) {
              status = "correct";
            } else {
              status = status === "correct" ? status : "present";
            }
          }
        } else {
          status = status || "absent";
        }
      }
    });
    return status;
  };

  return (
    <div>
      {keyRows.map((keyRow, i) => (
        <div key={i} className="mt-auto flex justify-center">
          {i === 2 && <Key icon={ICON_DELETE} value="DELETE" onKey={onKey} />}
          {keyRow.map((key) => (
            <Key
              key={key}
              letter={key}
              value={key}
              onKey={onKey}
              status={getKeyStatus(key)}
            />
          ))}
          {i === 2 && <Key icon={ICON_ENTER} value="ENTER" onKey={onKey} />}
        </div>
      ))}
    </div>
  );
};

export default Keyboard;
