type Props = {
  children: React.ReactNode;
};

const Modal = (props: Props) => {
  return (
    <div className="fixed left-0 top-0 z-10 flex h-screen w-screen items-start justify-center overflow-y-auto bg-slate-600 bg-opacity-75 pt-11">
      <div className="max-w-md rounded bg-slate-300 bg-white p-3 text-slate-800 dark:bg-slate-800 dark:text-slate-300">
        {props.children}
      </div>
    </div>
  );
};

export default Modal;
