import { WORD_LENGTH } from "../constants/variables";
import Tile from "./Tile";

const EmptyRow = () => {
  const cols = Array.from(Array(WORD_LENGTH));

  return (
    <div className="flex justify-center">
      {cols.map((e, i) => (
        <Tile key={i} />
      ))}
    </div>
  );
};

export default EmptyRow;
