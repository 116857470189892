import Tile from "./Tile";

type Props = { guess: string };

export const getLetterStatuses = (guess: string) => {
  const solution = JSON.parse(localStorage.gameState).solution.split("");
  const guessedLetters = solution.map(() => false);
  const splitGuess = guess.split("");
  const statuses = Array.from(Array(guess.length));

  splitGuess.forEach((letter, i) => {
    if (solution[i] === letter) {
      statuses[i] = "correct";
      guessedLetters[i] = true;
    }
  });

  splitGuess.forEach((letter, i) => {
    if (statuses[i]) return;

    if (!solution.includes(letter)) {
      statuses[i] = "absent";
      return;
    }

    const charIndex = solution.findIndex(
      (l: string, i: number) => l === letter && !guessedLetters[i]
    );

    if (charIndex > -1) {
      statuses[i] = "present";
      guessedLetters[charIndex] = true;
      return;
    } else {
      statuses[i] = "absent";
    }
  });

  return statuses;
};

const GuessRow = ({ guess }: Props) => {
  let letterStatuses = getLetterStatuses(guess);
  let isWon = letterStatuses.every((s) => s === "correct");
  return (
    <div className={`flex justify-center ${isWon ? "game-won" : ""}`}>
      {guess.split("").map((letter, i) => (
        <Tile key={i} letter={letter} status={letterStatuses[i]} />
      ))}
    </div>
  );
};

export default GuessRow;
