import { GAME_NAME } from "../constants/strings";
import DarkModeButton from "./DarkModeButton";
import InfoButton from "./InfoButton";
import StatsButton from "./StatsButton";

type Props = {
  toggleInfoModal: () => void;
  toggleStatsModal: () => void;
};

const TitleBar = ({ toggleInfoModal, toggleStatsModal }: Props) => {
  return (
    <div className="mb-1 flex items-center border-b-2 border-slate-600 dark:border-slate-500">
      <h1 className="text-2xl font-bold text-slate-700 dark:text-slate-200">
        {GAME_NAME}
      </h1>
      <div className="ml-auto flex items-center">
        <DarkModeButton />
        <InfoButton toggle={toggleInfoModal} />
        <StatsButton toggle={toggleStatsModal} />
      </div>
    </div>
  );
};

export default TitleBar;
